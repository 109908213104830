"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.B2bStockHistoryScreen = void 0;
var random_id_1 = require("@codebuild/glaze/libs/libs/random-id");
var repository_1 = require("@codebuild/glaze/libs/repository/repository");
var router_provider_1 = require("@codebuild/glaze/libs/router/router.provider");
var field_1 = require("@codebuild/sprinkles/libs/form/field");
var form_builder_1 = require("@codebuild/sprinkles/libs/form/form-builder");
var form_control_1 = require("@codebuild/sprinkles/libs/form/form-control/form-control");
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var trans_1 = require("@codebuild/sprinkles/libs/translation/trans");
var button_1 = require("@codebuild/sprinkles/modules/codebuild/button/react/button");
var select_input_form_control_type_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/form-control-types/select-input.form-control-type");
var select_input_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/select-input");
var table_1 = require("@codebuild/sprinkles/modules/codebuild/table/table");
var table_body_cell_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-body-cell");
var table_body_row_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-body-row");
var table_header_cell_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-header-cell");
var table_header_row_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-header-row");
var lodash_1 = require("lodash");
var moment = require("moment/moment");
var React = require("react");
var operators_1 = require("rxjs/operators");
var list_date_range_filter_component_1 = require("../../common/components/list-search/list-date-range-filter.component");
var pagination_list_control_1 = require("../../common/components/repository-list/controls/pagination-list-control");
var pagination_control_1 = require("../../common/components/repository-list/controls/pagination.control");
var list_empty_1 = require("../../common/components/repository-list/empty/list-empty");
var list_loader_1 = require("../../common/components/repository-list/loader/list-loader");
var repository_list_1 = require("../../common/components/repository-list/repository-list");
var tree_select_1 = require("../../common/components/tree-select/tree-select");
var form_1 = require("../../components/form/form");
var load_creator_1 = require("../../components/load-creator/load-creator");
require("./b2b-stock-history.screen.scss");
var history_storage_tree_select_form_control_type_1 = require("./history-storage-tree-select.form-control-type");
var B2bStockHistoryScreen = (function (_super) {
    __extends(B2bStockHistoryScreen, _super);
    function B2bStockHistoryScreen() {
        var _this = this;
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3;
        _this = _super.apply(this, __spreadArray([], __read(arguments), false)) || this;
        _this.repository = repository_1.Repository.use('default');
        _this.control = new pagination_list_control_1.PaginationListControl({
            defaultData: {
                limit: 20,
                page: (_c = (_b = (_a = _this.props.route) === null || _a === void 0 ? void 0 : _a.query) === null || _b === void 0 ? void 0 : _b._page) !== null && _c !== void 0 ? _c : 0,
                query: (_f = (_e = (_d = _this.props.route) === null || _d === void 0 ? void 0 : _d.query) === null || _e === void 0 ? void 0 : _e.query) !== null && _f !== void 0 ? _f : '',
                sort: (_h = (_g = _this.props.route) === null || _g === void 0 ? void 0 : _g.sort) !== null && _h !== void 0 ? _h : { createdAt: -1 }
            },
        });
        _this.filterForm = new form_builder_1.FormBuilder({
            fields: {
                products: new field_1.Field({
                    name: 'products',
                    label: (0, trans_1.trans)('b2b-stock-history.filter.form.label.product'),
                    value: ((_k = (_j = _this.control) === null || _j === void 0 ? void 0 : _j.getQuery()) === null || _k === void 0 ? void 0 : _k.products) || null,
                    optionsEndpoint: '/b2b/stock-history/product',
                    optionsMap: function (response) { return response === null || response === void 0 ? void 0 : response.map(function (item) { return ({
                        name: "".concat(item.sku, " - ").concat(item.manufacturer, " ").concat(item.title, " ").concat(item.vintage, " - ").concat(item.capacity),
                        value: item._id,
                        key: item._id,
                    }); }); },
                    loadOptionsAfterMount: true,
                }),
                lots: new field_1.Field({
                    name: 'lots',
                    label: (0, trans_1.trans)('b2b-stock-history.filter.form.label.lot'),
                    value: ((_m = (_l = _this.control) === null || _l === void 0 ? void 0 : _l.getQuery()) === null || _m === void 0 ? void 0 : _m.lots) || null,
                    optionsEndpoint: '/b2b/stock-history/lot',
                    optionsMap: function (response) { return response === null || response === void 0 ? void 0 : response.map(function (item) { return ({
                        name: item,
                        value: item,
                        key: item,
                    }); }); },
                    loadOptionsAfterMount: true,
                }),
                storages: new field_1.Field({
                    name: 'storages',
                    label: (0, trans_1.trans)('b2b-stock-history.filter.form.label.storage'),
                    value: ((_p = (_o = _this.control) === null || _o === void 0 ? void 0 : _o.getQuery()) === null || _p === void 0 ? void 0 : _p.storages) || null,
                }),
                actions: new field_1.Field({
                    name: 'actions',
                    label: (0, trans_1.trans)('b2b-stock-history.filter.form.label.action'),
                    value: ((_r = (_q = _this.control) === null || _q === void 0 ? void 0 : _q.getQuery()) === null || _r === void 0 ? void 0 : _r.actions) || null,
                    optionsEndpoint: '/b2b/stock-history/action',
                    optionsMap: function (response) { return response === null || response === void 0 ? void 0 : response.map(function (item) {
                        console.log({ item: item, 'this.getAction(item)': _this.getAction(item) });
                        return {
                            name: _this.getAction(item),
                            value: item,
                            key: item,
                        };
                    }); },
                    loadOptionsAfterMount: true,
                }),
                vtsz: new field_1.Field({
                    name: 'vtsz',
                    label: (0, trans_1.trans)('b2b-stock-history.filter.form.label.vtsz'),
                    value: ((_t = (_s = _this.control) === null || _s === void 0 ? void 0 : _s.getQuery()) === null || _t === void 0 ? void 0 : _t.vtsz) || null,
                    optionsEndpoint: '/b2b/stock-history/vtsz',
                    optionsMap: function (response) { return response === null || response === void 0 ? void 0 : response.map(function (item) { return ({
                        name: item.knCode,
                        value: item._id,
                        key: item._id,
                    }); }); },
                    loadOptionsAfterMount: true,
                }),
                jovedekiFajta: new field_1.Field({
                    name: 'jovedekiFajta',
                    label: (0, trans_1.trans)('b2b-stock-history.filter.form.label.jovedekiFajta'),
                    value: ((_v = (_u = _this.control) === null || _u === void 0 ? void 0 : _u.getQuery()) === null || _v === void 0 ? void 0 : _v.jovedekiFajta) || null,
                    optionsEndpoint: '/b2b/stock-history/jovedekiFajta',
                    optionsMap: function (response) { return response === null || response === void 0 ? void 0 : response.map(function (item) { return ({
                        name: "".concat(item.code, " - ").concat(item.description),
                        value: item.code,
                        key: item.code,
                    }); }); },
                    loadOptionsAfterMount: true,
                }),
                warehouse: new field_1.Field({
                    name: 'warehouse',
                    label: (0, trans_1.trans)('b2b-stock-history.filter.form.label.warehouse'),
                    value: ((_x = (_w = _this.control) === null || _w === void 0 ? void 0 : _w.getQuery()) === null || _x === void 0 ? void 0 : _x.warehouse) || null,
                    optionsEndpoint: '/b2b/warehouse',
                    optionsMap: function (response) {
                        var _a;
                        return (_a = response === null || response === void 0 ? void 0 : response.items) === null || _a === void 0 ? void 0 : _a.map(function (item) { return ({
                            name: item.title,
                            value: item._id,
                            key: item._id,
                        }); });
                    },
                    loadOptionsAfterMount: true,
                }),
                country: new field_1.Field({
                    name: 'country',
                    label: (0, trans_1.trans)('b2b-stock-history.filter.form.label.country'),
                    value: ((_z = (_y = _this.control) === null || _y === void 0 ? void 0 : _y.getQuery()) === null || _z === void 0 ? void 0 : _z.country) || null,
                    optionsEndpoint: '/b2b/stock-history/country',
                    optionsMap: function (response) { return response === null || response === void 0 ? void 0 : response.map(function (item) { return ({
                        name: item,
                        value: item,
                        key: item,
                    }); }); },
                    loadOptionsAfterMount: true,
                }),
                customerPartner: new field_1.Field({
                    name: 'customerPartner',
                    label: (0, trans_1.trans)('b2b-stock-history.filter.form.label.customerPartner'),
                    value: ((_1 = (_0 = _this.control) === null || _0 === void 0 ? void 0 : _0.getQuery()) === null || _1 === void 0 ? void 0 : _1.country) || null,
                    optionsEndpoint: '/b2b/customer-partner',
                    optionsMap: function (response) {
                        var _a;
                        return (_a = response === null || response === void 0 ? void 0 : response.items) === null || _a === void 0 ? void 0 : _a.map(function (item) { return ({
                            name: "".concat(item.name, " (").concat(item.brandName, ")"),
                            value: item._id,
                            key: item._id,
                        }); });
                    },
                    loadOptionsAfterMount: true,
                }),
                supplierPartner: new field_1.Field({
                    name: 'supplierPartner',
                    label: (0, trans_1.trans)('b2b-stock-history.filter.form.label.supplierPartner'),
                    value: ((_3 = (_2 = _this.control) === null || _2 === void 0 ? void 0 : _2.getQuery()) === null || _3 === void 0 ? void 0 : _3.country) || null,
                    optionsEndpoint: '/b2b/supplier-partner',
                    optionsMap: function (response) {
                        var _a;
                        return (_a = response === null || response === void 0 ? void 0 : response.items) === null || _a === void 0 ? void 0 : _a.map(function (item) { return ({
                            name: "".concat(item.name, " (").concat(item.brandName, ")"),
                            value: item._id,
                            key: item._id,
                        }); });
                    },
                    loadOptionsAfterMount: true,
                }),
            }
        });
        _this.state = {
            isFilterOpen: true,
            isDownloading: false
        };
        return _this;
    }
    B2bStockHistoryScreen.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                this.filterForm.fields.products.$value.pipe((0, operators_1.debounceTime)(450)).subscribe(function (v) { return _this.handleChange('products', v); });
                this.filterForm.fields.lots.$value.pipe((0, operators_1.debounceTime)(450)).subscribe(function (v) { return _this.handleChange('lots', v); });
                this.filterForm.fields.storages.$value.pipe((0, operators_1.debounceTime)(450)).subscribe(function (v) { return _this.handleChange('storages', v); });
                this.filterForm.fields.actions.$value.pipe((0, operators_1.debounceTime)(450)).subscribe(function (v) { return _this.handleChange('actions', v); });
                this.filterForm.fields.warehouse.$value.pipe((0, operators_1.debounceTime)(450)).subscribe(function (v) { return _this.handleChange('warehouses', v); });
                this.filterForm.fields.country.$value.pipe((0, operators_1.debounceTime)(450)).subscribe(function (v) { return _this.handleChange('country', v); });
                this.filterForm.fields.vtsz.$value.pipe((0, operators_1.debounceTime)(450)).subscribe(function (v) { return _this.handleChange('vtsz', v); });
                this.filterForm.fields.jovedekiFajta.$value.pipe((0, operators_1.debounceTime)(450)).subscribe(function (v) { return _this.handleChange('jovedekiFajta', v); });
                this.filterForm.fields.customerPartner.$value.pipe((0, operators_1.debounceTime)(450)).subscribe(function (v) { return _this.handleChange('customerPartner', v); });
                this.filterForm.fields.supplierPartner.$value.pipe((0, operators_1.debounceTime)(450)).subscribe(function (v) { return _this.handleChange('supplierPartner', v); });
                return [2];
            });
        });
    };
    B2bStockHistoryScreen.prototype.handleChange = function (property, value) {
        var currentValue = this.control.getQuery()[property];
        if (!!(0, lodash_1.isEqual)(currentValue, value)) {
            return;
        }
        this.control.set(property, value);
        this.control.page(0);
    };
    B2bStockHistoryScreen.prototype.render = function () {
        var _this = this;
        return React.createElement("div", { className: 'B2bStockHistoryScreen' },
            React.createElement("div", { className: 'row' },
                React.createElement("div", { className: "col " },
                    React.createElement("div", { className: 'display-flex align-items-bottom mb-8' },
                        React.createElement("h2", null, (0, trans_1.trans)('stock-history.main.title')))),
                React.createElement("div", null,
                    React.createElement("a", { id: 'downloadAnchorElem', className: 'display-none' }),
                    React.createElement(button_1.Button, { disabled: this.state.isDownloading, title: 'Letöltés', iconRight: 'fas fa-download', className: 'button-primary-normal button-size-normal', onClick: function () { return _this.downloadCSV(); } }))),
            React.createElement("div", { className: 'row mt-4 mb-7' },
                React.createElement("div", { className: 'col' },
                    React.createElement("div", { className: 'filter-wrapper' },
                        React.createElement("div", { className: 'row justify-content-between align-items-center px-2' },
                            React.createElement("p", { className: 'typo-small fw-600' }, (0, trans_1.trans)('b2b-stock-history.filters.title')),
                            React.createElement("div", { onClick: function () { return _this.onFilterToggle(); } },
                                React.createElement("i", { className: "far ".concat(this.state.isFilterOpen ? 'fa-chevron-up' : 'fa-chevron-down') }))),
                        this.state.isFilterOpen && React.createElement(form_1.Form, null,
                            React.createElement("div", { className: 'row mx-0' },
                                React.createElement(form_control_1.FormControl, { field: this.filterForm.fields.products, type: select_input_form_control_type_1.SelectInputFormControlType },
                                    React.createElement(select_input_1.SelectInput, { searchable: true, unclearable: false, multiple: true, tabIndex: 1, className: 'select-input-basic col-8 pl-0' })),
                                React.createElement(form_control_1.FormControl, { field: this.filterForm.fields.lots, type: select_input_form_control_type_1.SelectInputFormControlType },
                                    React.createElement(select_input_1.SelectInput, { searchable: true, unclearable: false, multiple: true, tabIndex: 1, className: 'select-input-basic col-8 pl-0' })),
                                React.createElement(form_control_1.FormControl, { field: this.filterForm.fields.actions, type: select_input_form_control_type_1.SelectInputFormControlType },
                                    React.createElement(select_input_1.SelectInput, { unclearable: false, multiple: true, tabIndex: 3, className: 'select-input-basic col-8 pr-0' }))),
                            React.createElement("div", { className: 'row mx-0' },
                                React.createElement(form_control_1.FormControl, { field: this.filterForm.fields.vtsz, type: select_input_form_control_type_1.SelectInputFormControlType },
                                    React.createElement(select_input_1.SelectInput, { searchable: true, unclearable: false, multiple: true, tabIndex: 1, className: 'select-input-basic col-8 pl-0' })),
                                React.createElement(form_control_1.FormControl, { field: this.filterForm.fields.jovedekiFajta, type: select_input_form_control_type_1.SelectInputFormControlType },
                                    React.createElement(select_input_1.SelectInput, { searchable: true, unclearable: false, multiple: true, tabIndex: 1, className: 'select-input-basic col-8 pl-0' })),
                                React.createElement(form_control_1.FormControl, { field: this.filterForm.fields.warehouse, type: select_input_form_control_type_1.SelectInputFormControlType },
                                    React.createElement(select_input_1.SelectInput, { searchable: true, unclearable: false, multiple: true, tabIndex: 1, className: 'select-input-basic col-8 pl-0' }))),
                            React.createElement("div", { className: "row" },
                                React.createElement(form_control_1.FormControl, { field: this.filterForm.fields.country, type: select_input_form_control_type_1.SelectInputFormControlType },
                                    React.createElement(select_input_1.SelectInput, { searchable: true, unclearable: false, multiple: true, tabIndex: 1, className: 'select-input-basic col-8 pl-0' })),
                                React.createElement(form_control_1.FormControl, { field: this.filterForm.fields.customerPartner, type: select_input_form_control_type_1.SelectInputFormControlType },
                                    React.createElement(select_input_1.SelectInput, { searchable: true, unclearable: false, clearAll: true, multiple: true, tabIndex: 1, className: 'select-input-basic col-8 pl-0' })),
                                React.createElement(form_control_1.FormControl, { field: this.filterForm.fields.supplierPartner, type: select_input_form_control_type_1.SelectInputFormControlType },
                                    React.createElement(select_input_1.SelectInput, { searchable: true, unclearable: false, clearAll: true, multiple: true, tabIndex: 1, className: 'select-input-basic col-8 pl-0' }))),
                            React.createElement("div", { className: 'row mx-0' },
                                React.createElement("div", { className: "flex-fill" },
                                    React.createElement(form_control_1.FormControl, { field: this.filterForm.fields.storages, type: history_storage_tree_select_form_control_type_1.HistoryStorageTreeSelectFormControlType },
                                        React.createElement(tree_select_1.TreeSelect, { multi: true, clearable: true, enabledTypes: ['storage'], modalTitle: 'Tárhely választása', searchable: true, className: 'select-input-basic' }))),
                                React.createElement("div", { className: "col-8" },
                                    React.createElement(list_date_range_filter_component_1.ListDateRangeFilterComponent, { startDate: moment().add(-1, 'month').toDate(), "control$": this.control }))))))),
            React.createElement(repository_list_1.RepositoryList, { control: this.control, hideItemsWhileLoading: true, showLoading: function () { return true; }, showError: function () { return true; }, showItem: function () { return true; }, onChange: function (ctx) { return _this.handleListChange(ctx); }, repository: repository_1.Repository.use('default'), endpoint: '/b2b/stock-history', renderItemsWrapper: function (ctx) { return _this.renderTable(ctx); }, renderItem: function (ctx, item) { return _this.renderItem(ctx, item); }, renderEmpty: function () { return (React.createElement(list_empty_1.ListEmpty, null)); }, renderLoading: function (ctx) { return (React.createElement(list_loader_1.ListLoader, null)); } }),
            React.createElement(pagination_control_1.PaginationControl, { "control$": this.control, limitOptions: [
                    { name: 20, value: 20 },
                    { name: 50, value: 50 },
                    { name: 100, value: 100 },
                    { name: 500, value: 500 },
                ] }));
    };
    B2bStockHistoryScreen.prototype.renderTable = function (ctx) {
        var _this = this;
        return React.createElement(table_1.Table, { className: 'table-basic', renderHeader: function () { return _this.renderTableHeader(); }, renderBody: function () { return ctx.renderItems(ctx); } });
    };
    B2bStockHistoryScreen.prototype.renderItem = function (ctx, item) {
        var _this = this;
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11;
        var diff = item.afterValue - item.beforeValue;
        var diffED = parseFloat((item.afterExciseDutyValue - item.beforeExciseDutyValue || 0).toFixed(6));
        var ean = (item === null || item === void 0 ? void 0 : item.ean) || '-';
        var sku = ((_b = (_a = item === null || item === void 0 ? void 0 : item.__meta) === null || _a === void 0 ? void 0 : _a.product) === null || _b === void 0 ? void 0 : _b.sku) || '-';
        var manufacturer = ((_d = (_c = item === null || item === void 0 ? void 0 : item.__meta) === null || _c === void 0 ? void 0 : _c.product) === null || _d === void 0 ? void 0 : _d.manufacturer) || '-';
        var title = ((_f = (_e = item === null || item === void 0 ? void 0 : item.__meta) === null || _e === void 0 ? void 0 : _e.product) === null || _f === void 0 ? void 0 : _f.title) || '-';
        var vintage = ((_h = (_g = item === null || item === void 0 ? void 0 : item.__meta) === null || _g === void 0 ? void 0 : _g.product) === null || _h === void 0 ? void 0 : _h.vintage) || '-';
        var capacity = ((_k = (_j = item === null || item === void 0 ? void 0 : item.__meta) === null || _j === void 0 ? void 0 : _j.product) === null || _k === void 0 ? void 0 : _k.capacity) || '-';
        var vtsz = ((_m = (_l = item === null || item === void 0 ? void 0 : item.__meta) === null || _l === void 0 ? void 0 : _l.product) === null || _m === void 0 ? void 0 : _m.vtsz) || '-';
        var jovedekiFajta = ((_q = (_p = (_o = item === null || item === void 0 ? void 0 : item.__meta) === null || _o === void 0 ? void 0 : _o.product) === null || _p === void 0 ? void 0 : _p.jovedekiFajta) === null || _q === void 0 ? void 0 : _q.code) || ((_s = (_r = item === null || item === void 0 ? void 0 : item.__meta) === null || _r === void 0 ? void 0 : _r.product) === null || _s === void 0 ? void 0 : _s.jovedekiFajta) || '-';
        var path = (_w = (_v = (_u = (_t = item === null || item === void 0 ? void 0 : item.__meta) === null || _t === void 0 ? void 0 : _t.storage) === null || _u === void 0 ? void 0 : _u.path) === null || _v === void 0 ? void 0 : _v.map(function (p) { return p.title; })) === null || _w === void 0 ? void 0 : _w.join(' / ');
        var action = this.getAction((_x = item.group) === null || _x === void 0 ? void 0 : _x.action);
        var partner = ((_y = item.group) === null || _y === void 0 ? void 0 : _y.action) === 'execute-stock-issuing'
            ? "".concat(((_0 = (_z = item === null || item === void 0 ? void 0 : item.__meta) === null || _z === void 0 ? void 0 : _z.customerPartner) === null || _0 === void 0 ? void 0 : _0.name) || ((_2 = (_1 = item === null || item === void 0 ? void 0 : item.__meta) === null || _1 === void 0 ? void 0 : _1.supplierPartner) === null || _2 === void 0 ? void 0 : _2.name), " (").concat(((_4 = (_3 = item === null || item === void 0 ? void 0 : item.__meta) === null || _3 === void 0 ? void 0 : _3.customerPartner) === null || _4 === void 0 ? void 0 : _4.brandName) || ((_6 = (_5 = item === null || item === void 0 ? void 0 : item.__meta) === null || _5 === void 0 ? void 0 : _5.supplierPartner) === null || _6 === void 0 ? void 0 : _6.brandName) || '-', ")")
            : '';
        var exciseUnit = item.exciseUnit || ((_9 = (_8 = (_7 = item === null || item === void 0 ? void 0 : item.__meta) === null || _7 === void 0 ? void 0 : _7.product) === null || _8 === void 0 ? void 0 : _8.jovedekiFajta) === null || _9 === void 0 ? void 0 : _9.unit);
        return React.createElement(table_body_row_1.TableBodyRow, { key: (0, random_id_1.randomId)() },
            React.createElement(table_body_cell_1.TableBodyCell, { width: 20 },
                React.createElement("div", null,
                    React.createElement("p", null,
                        React.createElement("small", { className: 'color--neutral-8 fw-700 mr-2' }, "EAN"),
                        React.createElement("span", null, ean)),
                    React.createElement("p", null,
                        React.createElement("small", { className: 'color--neutral-8 fw-700 mr-2' }, "SKU"),
                        React.createElement("span", null, sku)),
                    React.createElement("p", null,
                        manufacturer,
                        " ",
                        title,
                        " ",
                        vintage,
                        " (",
                        capacity,
                        ")"),
                    React.createElement("p", { className: 'typo-small' },
                        "kn/vtsz: ",
                        vtsz),
                    React.createElement("p", { className: 'typo-small' },
                        "J\u00F6ved\u00E9ki fajta k\u00F3d: ",
                        jovedekiFajta))),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 10 },
                React.createElement("p", null, item.lot)),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 15 },
                React.createElement("p", null, path)),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 10 },
                React.createElement("p", null, action),
                React.createElement(button_1.Button, { disabled: !this.hasLink(item), className: 'button-size-small button-primary-link', iconLeft: 'fa-solid fa-arrow-up-right-from-square', onClick: function () { return _this.goToTransaction(item); } })),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 10 },
                React.createElement("p", null, partner)),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 8 },
                React.createElement("p", null,
                    item.beforeValue,
                    " db")),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 8 },
                React.createElement("p", { className: "".concat(diff < 0 ? 'color--error-6' : 'color--success-6') },
                    diff < 0 ? '-' : '+',
                    " ",
                    Math.abs(diff),
                    " db")),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 8 },
                React.createElement("p", { className: "".concat(diff < 0 ? 'color--error-6' : 'color--success-6') },
                    diff < 0 ? '-' : '+',
                    " ",
                    Math.abs(diffED),
                    " ",
                    exciseUnit)),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 8 },
                React.createElement("p", null,
                    item.afterValue,
                    " db")),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 12 },
                React.createElement(load_creator_1.LoadCreator, { userId: (_10 = item.group) === null || _10 === void 0 ? void 0 : _10.creator, display: 'name' })),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 10 },
                React.createElement("p", null, moment((_11 = item.group) === null || _11 === void 0 ? void 0 : _11.createdAt).format('YYYY.MM.DD HH:mm'))));
    };
    B2bStockHistoryScreen.prototype.goToTransaction = function (item) {
        var _a, _b;
        if (!((_b = (_a = item.group) === null || _a === void 0 ? void 0 : _a.transaction) === null || _b === void 0 ? void 0 : _b.length)) {
            return;
        }
        var path = item.group.action.replace('execute-', '');
        router_provider_1.RouterProvider.goTo("/".concat(path, "/").concat(item.group.transaction));
    };
    B2bStockHistoryScreen.prototype.hasLink = function (item) {
        var _a, _b;
        return !!((_b = (_a = item.group) === null || _a === void 0 ? void 0 : _a.transaction) === null || _b === void 0 ? void 0 : _b.length);
    };
    B2bStockHistoryScreen.prototype.renderTableHeader = function () {
        return React.createElement(table_header_row_1.TableHeaderRow, { sticky: true },
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 20 }, this.setHeaderCell('ean'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 10 }, this.setHeaderCell('lot'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 15 }, this.setHeaderCell('storage'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 10 }, this.setHeaderCell('action'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 10 }, this.setHeaderCell('issuePartner'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 8 }, this.setHeaderCell('start'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 8 }, this.setHeaderCell('difference'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 8 }, this.setHeaderCell('differenceHlt'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 8 }, this.setHeaderCell('end'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 12 }, this.setHeaderCell('creator'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 10 }, this.setHeaderCell('createdAt'))));
    };
    B2bStockHistoryScreen.prototype.setHeaderCell = function (p, enableSort) {
        var _this = this;
        if (enableSort === void 0) { enableSort = true; }
        return {
            sortValue: this.getSortValue(p),
            onSort: function (property, value) { return _this.handleSort(property, value); },
            enableSort: enableSort,
            title: (0, trans_1.trans)("b2b-stock-history.table.header.".concat(p)),
            property: p
        };
    };
    B2bStockHistoryScreen.prototype.handleSort = function (property, value) {
        this.control.sort(property, value);
    };
    B2bStockHistoryScreen.prototype.getSortValue = function (property) {
        var _a;
        return ((_a = this.control.data.sort) === null || _a === void 0 ? void 0 : _a[property]) || 0;
    };
    B2bStockHistoryScreen.prototype.handleListChange = function (ctx) {
        var _a, _b;
        var total = (_a = ctx.state) === null || _a === void 0 ? void 0 : _a.total;
        this.setState({ total: (_b = ctx.state) === null || _b === void 0 ? void 0 : _b.total, openedRows: [] });
        if (this.props.totalChange) {
            this.props.totalChange(total);
        }
    };
    B2bStockHistoryScreen.prototype.getAction = function (action) {
        var hey = [
            'execute-stock-disposal',
            'execute-stock-income',
            'execute-stock-inventory',
            'execute-stock-issuing',
            'execute-stock-transaction'
        ];
        switch (action) {
            case ('execute-stock-income'):
                return (0, trans_1.trans)('b2b-stock-history.action.income');
            case ('execute-stock-outcome'):
                return (0, trans_1.trans)('b2b-stock-history.action.outcome');
            case ('execute-stock-disposal'):
                return (0, trans_1.trans)('b2b-stock-history.action.dispose');
            case ('execute-stock-transaction'):
                return (0, trans_1.trans)('b2b-stock-history.action.transaction');
            case ('execute-stock-issuing'):
                return (0, trans_1.trans)('b2b-stock-history.action.issuing');
            case ('execute-stock-inventory'):
                return (0, trans_1.trans)('b2b-stock-history.action.inventory');
            default:
                return (0, trans_1.trans)('b2b-stock-history.action.default');
        }
    };
    B2bStockHistoryScreen.prototype.onFilterToggle = function () {
        this.setState({ isFilterOpen: !this.state.isFilterOpen });
    };
    B2bStockHistoryScreen.prototype.downloadCSV = function () {
        return __awaiter(this, void 0, void 0, function () {
            var data, titleRow, rows, csvContent, encodedUri, dlAnchorElem, e_1;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, 3, 4]);
                        this.setState({ isDownloading: true });
                        return [4, this.repository.get('/b2b/stock-history/download', { params: this.control.getQuery() })];
                    case 1:
                        data = _a.sent();
                        titleRow = [
                            'Cikkszám',
                            'Pince neve',
                            'Termék neve',
                            'Évjárat',
                            'Űrtartalom',
                            'Alkohol',
                            'KN kód',
                            'Jövedéki fajtakód',
                            'Termék EAN',
                            'LOT',
                            'Tárhely útvonal',
                            'Esemény',
                            'Esemény azonosítója',
                            'Bizonylatszám',
                            'Jogcímkód',
                            'Beszállító',
                            'Beszállító adószám',
                            'Beszállító telephely megnevezése',
                            'Beszállító ország',
                            'Beszállító irányítószám',
                            'Beszállító város',
                            'Beszállító cím',
                            'Beszállító engedélyszám',
                            'Átadó neve',
                            'Átadó adószáma',
                            'Átadó irányítószám',
                            'Átadó város',
                            'Átadó cím',
                            'Átadó engedélyszám',
                            'Vevő',
                            'Vevő adószám',
                            'Vevő ország',
                            'Vevő irányítószám',
                            'Vevő város',
                            'Vevő cím',
                            'Vevő engedélyszám',
                            'Nyitás db',
                            'Mozgás db',
                            'Záró db',
                            'Nyitás jövedéki',
                            'Mozgás jövedéki',
                            'Záró jövedéki',
                            'Jövedéki mértékegység',
                            'Létrehozó',
                            'Létrehozás',
                        ];
                        rows = __spreadArray([
                            titleRow
                        ], __read((data.map(function (d) {
                            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14, _15, _16, _17, _18, _19, _20, _21, _22, _23, _24, _25, _26, _27, _28, _29, _30, _31, _32, _33, _34, _35, _36, _37, _38, _39, _40, _41, _42, _43, _44, _45, _46, _47, _48, _49, _50, _51, _52, _53, _54, _55, _56, _57, _58, _59, _60, _61, _62, _63, _64, _65, _66, _67, _68, _69, _70, _71, _72, _73, _74, _75, _76, _77, _78, _79, _80, _81, _82;
                            var diff = d.afterValue - d.beforeValue || 0;
                            var diffED = d.afterExciseDutyValue - d.beforeExciseDutyValue || 0;
                            var path = (_d = (_c = (_b = (_a = d === null || d === void 0 ? void 0 : d.__meta) === null || _a === void 0 ? void 0 : _a.storage) === null || _b === void 0 ? void 0 : _b.path) === null || _c === void 0 ? void 0 : _c.map(function (p) { return p.title; })) === null || _d === void 0 ? void 0 : _d.join(' / ');
                            var creator = d.group.creatorArtifact ? "".concat((_f = (_e = d.group) === null || _e === void 0 ? void 0 : _e.creatorArtifact) === null || _f === void 0 ? void 0 : _f.lastName, " ").concat((_h = (_g = d.group) === null || _g === void 0 ? void 0 : _g.creatorArtifact) === null || _h === void 0 ? void 0 : _h.firstName) : (_j = d.group) === null || _j === void 0 ? void 0 : _j.creator;
                            return [
                                ((_l = (_k = d.__meta) === null || _k === void 0 ? void 0 : _k.product) === null || _l === void 0 ? void 0 : _l.sku) || '',
                                ((_o = (_m = d.__meta) === null || _m === void 0 ? void 0 : _m.product) === null || _o === void 0 ? void 0 : _o.manufacturer) || '',
                                ((_q = (_p = d.__meta) === null || _p === void 0 ? void 0 : _p.product) === null || _q === void 0 ? void 0 : _q.title) || '',
                                ((_s = (_r = d.__meta) === null || _r === void 0 ? void 0 : _r.product) === null || _s === void 0 ? void 0 : _s.vintage) || '',
                                ((_u = (_t = d.__meta) === null || _t === void 0 ? void 0 : _t.product) === null || _u === void 0 ? void 0 : _u.capacity) || '',
                                ((_w = (_v = d.__meta) === null || _v === void 0 ? void 0 : _v.product) === null || _w === void 0 ? void 0 : _w.alcohol) || '',
                                ((_y = (_x = d.__meta) === null || _x === void 0 ? void 0 : _x.product) === null || _y === void 0 ? void 0 : _y.knCode) || '',
                                ((_1 = (_0 = (_z = d.__meta) === null || _z === void 0 ? void 0 : _z.product) === null || _0 === void 0 ? void 0 : _0.exciseDutyType) === null || _1 === void 0 ? void 0 : _1.code) || ((_3 = (_2 = d.__meta) === null || _2 === void 0 ? void 0 : _2.product) === null || _3 === void 0 ? void 0 : _3.exciseDutyType) || '',
                                d.ean || '',
                                d.lot || '',
                                path,
                                _this.getAction((_4 = d.group) === null || _4 === void 0 ? void 0 : _4.action),
                                ((_6 = (_5 = d.__meta) === null || _5 === void 0 ? void 0 : _5.document) === null || _6 === void 0 ? void 0 : _6.identifier) || '',
                                ((_8 = (_7 = d.__meta) === null || _7 === void 0 ? void 0 : _7.document) === null || _8 === void 0 ? void 0 : _8.bko) || '',
                                ((_10 = (_9 = d.__meta) === null || _9 === void 0 ? void 0 : _9.document) === null || _10 === void 0 ? void 0 : _10.legalCode) || '',
                                ((_12 = (_11 = d === null || d === void 0 ? void 0 : d.__meta) === null || _11 === void 0 ? void 0 : _11.supplier) === null || _12 === void 0 ? void 0 : _12.name) ? "".concat((_14 = (_13 = d === null || d === void 0 ? void 0 : d.__meta) === null || _13 === void 0 ? void 0 : _13.supplier) === null || _14 === void 0 ? void 0 : _14.name, " (").concat(((_16 = (_15 = d === null || d === void 0 ? void 0 : d.__meta) === null || _15 === void 0 ? void 0 : _15.supplier) === null || _16 === void 0 ? void 0 : _16.brandName) || '-', ")") : '',
                                ((_18 = (_17 = d === null || d === void 0 ? void 0 : d.__meta) === null || _17 === void 0 ? void 0 : _17.supplier) === null || _18 === void 0 ? void 0 : _18.taxNumber) || '',
                                ((_21 = (_20 = (_19 = d.__meta) === null || _19 === void 0 ? void 0 : _19.document) === null || _20 === void 0 ? void 0 : _20.supplierShippingData) === null || _21 === void 0 ? void 0 : _21.name) || '',
                                ((_24 = (_23 = (_22 = d.__meta) === null || _22 === void 0 ? void 0 : _22.document) === null || _23 === void 0 ? void 0 : _23.supplierShippingData) === null || _24 === void 0 ? void 0 : _24.country) || '',
                                ((_27 = (_26 = (_25 = d.__meta) === null || _25 === void 0 ? void 0 : _25.document) === null || _26 === void 0 ? void 0 : _26.supplierShippingData) === null || _27 === void 0 ? void 0 : _27.zipCode) || '',
                                ((_30 = (_29 = (_28 = d.__meta) === null || _28 === void 0 ? void 0 : _28.document) === null || _29 === void 0 ? void 0 : _29.supplierShippingData) === null || _30 === void 0 ? void 0 : _30.city) || '',
                                ((_33 = (_32 = (_31 = d.__meta) === null || _31 === void 0 ? void 0 : _31.document) === null || _32 === void 0 ? void 0 : _32.supplierShippingData) === null || _33 === void 0 ? void 0 : _33.address) || '',
                                ((_36 = (_35 = (_34 = d.__meta) === null || _34 === void 0 ? void 0 : _34.document) === null || _35 === void 0 ? void 0 : _35.supplierShippingData) === null || _36 === void 0 ? void 0 : _36.operatingPermitNumber) || '',
                                ((_38 = (_37 = d.__meta) === null || _37 === void 0 ? void 0 : _37.depot) === null || _38 === void 0 ? void 0 : _38.companyName) || '',
                                ((_40 = (_39 = d.__meta) === null || _39 === void 0 ? void 0 : _39.depot) === null || _40 === void 0 ? void 0 : _40.taxNumber) || '',
                                ((_43 = (_42 = (_41 = d.__meta) === null || _41 === void 0 ? void 0 : _41.depot) === null || _42 === void 0 ? void 0 : _42.address) === null || _43 === void 0 ? void 0 : _43.zipCode) || '',
                                ((_46 = (_45 = (_44 = d.__meta) === null || _44 === void 0 ? void 0 : _44.depot) === null || _45 === void 0 ? void 0 : _45.address) === null || _46 === void 0 ? void 0 : _46.city) || '',
                                "".concat(((_49 = (_48 = (_47 = d.__meta) === null || _47 === void 0 ? void 0 : _47.depot) === null || _48 === void 0 ? void 0 : _48.address) === null || _49 === void 0 ? void 0 : _49.address) || '', " ").concat(((_52 = (_51 = (_50 = d.__meta) === null || _50 === void 0 ? void 0 : _50.depot) === null || _51 === void 0 ? void 0 : _51.address) === null || _52 === void 0 ? void 0 : _52.number) || '') || '',
                                ((_54 = (_53 = d.__meta) === null || _53 === void 0 ? void 0 : _53.depot) === null || _54 === void 0 ? void 0 : _54.licenceNumber) || '',
                                !!((_56 = (_55 = d === null || d === void 0 ? void 0 : d.__meta) === null || _55 === void 0 ? void 0 : _55.customer) === null || _56 === void 0 ? void 0 : _56.name) ? "".concat((_58 = (_57 = d === null || d === void 0 ? void 0 : d.__meta) === null || _57 === void 0 ? void 0 : _57.customer) === null || _58 === void 0 ? void 0 : _58.name, " (").concat(((_60 = (_59 = d === null || d === void 0 ? void 0 : d.__meta) === null || _59 === void 0 ? void 0 : _59.customer) === null || _60 === void 0 ? void 0 : _60.brandName) || '-', ")") : '',
                                ((_62 = (_61 = d === null || d === void 0 ? void 0 : d.__meta) === null || _61 === void 0 ? void 0 : _61.customer) === null || _62 === void 0 ? void 0 : _62.taxNumber) || '',
                                ((_65 = (_64 = (_63 = d === null || d === void 0 ? void 0 : d.__meta) === null || _63 === void 0 ? void 0 : _63.customer) === null || _64 === void 0 ? void 0 : _64.customerShippingData) === null || _65 === void 0 ? void 0 : _65.country) || '',
                                ((_68 = (_67 = (_66 = d === null || d === void 0 ? void 0 : d.__meta) === null || _66 === void 0 ? void 0 : _66.customer) === null || _67 === void 0 ? void 0 : _67.customerShippingData) === null || _68 === void 0 ? void 0 : _68.zipCode) || '',
                                ((_71 = (_70 = (_69 = d === null || d === void 0 ? void 0 : d.__meta) === null || _69 === void 0 ? void 0 : _69.customer) === null || _70 === void 0 ? void 0 : _70.customerShippingData) === null || _71 === void 0 ? void 0 : _71.city) || '',
                                ((_74 = (_73 = (_72 = d === null || d === void 0 ? void 0 : d.__meta) === null || _72 === void 0 ? void 0 : _72.customer) === null || _73 === void 0 ? void 0 : _73.customerShippingData) === null || _74 === void 0 ? void 0 : _74.address) || '',
                                ((_77 = (_76 = (_75 = d === null || d === void 0 ? void 0 : d.__meta) === null || _75 === void 0 ? void 0 : _75.customer) === null || _76 === void 0 ? void 0 : _76.customerShippingData) === null || _77 === void 0 ? void 0 : _77.operatingPermitNumber) || '',
                                d.beforeValue,
                                "".concat(diff < 0 ? '-' : '+', " ").concat(Math.abs(diff)),
                                d.afterValue,
                                d.beforeExciseDutyValue,
                                "".concat(diffED < 0 ? '-' : '+', " ").concat(Math.abs(diffED)),
                                d.afterExciseDutyValue,
                                ((_80 = (_79 = (_78 = d.__meta) === null || _78 === void 0 ? void 0 : _78.product) === null || _79 === void 0 ? void 0 : _79.exciseDutyType) === null || _80 === void 0 ? void 0 : _80.unit) || '',
                                creator,
                                moment((_82 = (_81 = d.__meta) === null || _81 === void 0 ? void 0 : _81.document) === null || _82 === void 0 ? void 0 : _82.executed).format('YYYY-MM-DD HH:mm:ss')
                            ];
                        }))), false);
                        csvContent = "data:text/csv;charset=utf-8, ".concat(rows.map(function (e) { return e.join(';'); }).join('\n'));
                        encodedUri = encodeURI(csvContent).replace(/#/gi, '%23');
                        dlAnchorElem = document.getElementById('downloadAnchorElem');
                        dlAnchorElem.setAttribute('href', encodedUri);
                        dlAnchorElem.setAttribute('download', "stock-history-".concat(moment().format('YYYY.MM.DD'), ".csv"));
                        dlAnchorElem.click();
                        return [3, 4];
                    case 2:
                        e_1 = _a.sent();
                        console.log({ downloadCSV: e_1 });
                        return [3, 4];
                    case 3:
                        this.setState({ isDownloading: false });
                        return [7];
                    case 4: return [2];
                }
            });
        });
    };
    return B2bStockHistoryScreen;
}(abstract_component_1.AbstractComponent));
exports.B2bStockHistoryScreen = B2bStockHistoryScreen;
