"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.HasNotOrderedForAWhile = exports.periodRange = void 0;
var repository_1 = require("@codebuild/glaze/libs/repository/repository");
var select_input_form_control_type_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/form-control-types/select-input.form-control-type");
var select_input_1 = require("@codebuild/glaze/modules/codebuild/input-fields/react/select-input");
var field_1 = require("@codebuild/sprinkles/libs/form/field");
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var trans_1 = require("@codebuild/sprinkles/libs/translation/trans");
var React = require("react");
var box_loader_1 = require("../../../common/components/repository-list/loader/box-loader");
var form_builder_1 = require("@codebuild/sprinkles/libs/form/form-builder");
var form_control_1 = require("@codebuild/sprinkles/libs/form/form-control/form-control");
var handle_date_1 = require("../../../libs/handle-date");
var get_date_range_by_year_1 = require("./libs/get-date-range-by-year");
exports.periodRange = [
    { value: '30', name: '30', key: '30' },
    { value: '90', name: '90', key: '90' },
    { value: '180', name: '180', key: '180' },
];
var HasNotOrderedForAWhile = (function (_super) {
    __extends(HasNotOrderedForAWhile, _super);
    function HasNotOrderedForAWhile() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.repository = repository_1.Repository.use('default');
        _this.state = {
            loading: false,
            data: []
        };
        _this.baseTransText = 'sales.dashboard';
        _this.form = new form_builder_1.FormBuilder({
            fields: {
                period: new field_1.Field({
                    placeholder: '',
                    value: exports.periodRange[0].value,
                    name: 'periodRange',
                    options: exports.periodRange,
                    label: (0, trans_1.trans)("".concat(_this.baseTransText, ".periodRange.label")),
                    validators: []
                }),
            }
        });
        return _this;
    }
    HasNotOrderedForAWhile.prototype.componentDidMount = function () {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var year;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!((_a = this.props.user) === null || _a === void 0 ? void 0 : _a._id)) return [3, 2];
                        year = this.props.year || new Date().getFullYear();
                        return [4, this.fetch(year)];
                    case 1:
                        _b.sent();
                        _b.label = 2;
                    case 2:
                        this.$subscriptions.push(this.form.fields.period.$value.subscribe(function (period) { return _this.fetch(_this.props.year, period); }));
                        return [2];
                }
            });
        });
    };
    HasNotOrderedForAWhile.prototype.componentDidUpdate = function (prevProps, prevState, snapshot) {
        return __awaiter(this, void 0, void 0, function () {
            var year;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(prevProps.user !== this.props.user)) return [3, 2];
                        year = this.props.year || new Date().getFullYear();
                        return [4, this.fetch(year)];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2:
                        if (!(prevProps.year !== this.props.year)) return [3, 4];
                        return [4, this.fetch(this.props.year)];
                    case 3:
                        _a.sent();
                        _a.label = 4;
                    case 4: return [2];
                }
            });
        });
    };
    HasNotOrderedForAWhile.prototype.render = function () {
        if (this.state.loading) {
            return React.createElement("div", { style: { minHeight: '282px' }, className: 'row py-9 ' },
                React.createElement(box_loader_1.BoxLoader, null));
        }
        return React.createElement("div", { className: "w-100 display-flex align-items-center flex-column" },
            React.createElement("div", { className: "w-100" },
                React.createElement("h4", { className: 'mb-3' }, "A legr\u00E9gebben rendel\u0151 vev\u0151k:"),
                React.createElement(form_control_1.FormControl, { field: this.form.fields.period, type: select_input_form_control_type_1.SelectInputFormControlType },
                    React.createElement(select_input_1.SelectInput, { unclearable: true, className: 'select-input-basic' }))),
            this.renderTable());
    };
    HasNotOrderedForAWhile.prototype.renderTable = function () {
        return React.createElement("div", { style: { minHeight: '330px' }, className: 'sales-dashboard-box w-100 scrollable-div' }, this.renderTableRow());
    };
    HasNotOrderedForAWhile.prototype.renderTableRow = function () {
        var _a;
        if (!((_a = this.state.data) === null || _a === void 0 ? void 0 : _a.length)) {
            return React.createElement("span", null, "Empty list");
        }
        var renderItem = function (data) { return React.createElement("div", { key: data.lastOrder, className: 'row mb-4' },
            React.createElement("div", { className: 'col-16 display-flex justify-content-start' },
                React.createElement("span", { className: 'fs-11' }, data.title)),
            React.createElement("div", { className: 'col-8 display-flex justify-content-start' },
                React.createElement("span", { className: 'fs-11' }, (0, handle_date_1.handleDate)(data.lastOrder)))); };
        return this.state.data.map(renderItem);
    };
    HasNotOrderedForAWhile.prototype.fetch = function (year, period) {
        var _a;
        if (period === void 0) { period = exports.periodRange[0].value; }
        return __awaiter(this, void 0, void 0, function () {
            var _b, from, to, response, e_1;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 2, 3, 4]);
                        this.setState({ loading: true });
                        _b = (0, get_date_range_by_year_1.getDateRangeByYear)(year), from = _b.from, to = _b.to;
                        return [4, this.repository.get("/b2b/sales-dashboard/has-not-ordered-for-a-while/".concat((_a = this.props.user) === null || _a === void 0 ? void 0 : _a._id, "?period=").concat(period, "&from=").concat(from, "&to=").concat(to))];
                    case 1:
                        response = _c.sent();
                        this.setState({
                            data: response,
                        });
                        return [3, 4];
                    case 2:
                        e_1 = _c.sent();
                        console.log({ HasNotOrderedForAWhile: e_1 });
                        return [3, 4];
                    case 3:
                        this.setState({ loading: false });
                        return [7];
                    case 4: return [2];
                }
            });
        });
    };
    return HasNotOrderedForAWhile;
}(abstract_component_1.AbstractComponent));
exports.HasNotOrderedForAWhile = HasNotOrderedForAWhile;
