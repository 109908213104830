"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.PasswordStrength = void 0;
var React = require("react");
var PasswordStrength = (function (_super) {
    __extends(PasswordStrength, _super);
    function PasswordStrength() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            passwordStrength: 0
        };
        return _this;
    }
    PasswordStrength.prototype.componentDidMount = function () {
        console.log('got here');
        this.testPasswordStrength(this.props.value);
    };
    PasswordStrength.prototype.componentDidUpdate = function (prevProps, prevState, snapshot) {
        if (prevProps.value !== this.props.value) {
            this.testPasswordStrength(this.props.value);
        }
    };
    PasswordStrength.prototype.testPasswordStrength = function (password) {
        var numericValue = /\d+/g;
        var specialCharacter = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        var lowerCase = /(?=.*[a-z])/;
        var upperCase = /(?=.*[A-Z])/;
        var strength = 0;
        if (password.length > 1) {
            if (password.length > 8) {
                strength++;
            }
            if (numericValue.test(password)) {
                strength++;
            }
            if (specialCharacter.test(password)) {
                strength++;
            }
            if (lowerCase.test(password)) {
                strength++;
            }
            if (upperCase.test(password)) {
                strength++;
            }
        }
        this.setState({ passwordStrength: strength });
    };
    PasswordStrength.prototype.render = function () {
        return React.createElement("div", { className: "w-100 password-strength background-color--black-3 ".concat(this.props.className) },
            React.createElement("div", { className: "password-strength--line strength-w--".concat(this.state.passwordStrength) }));
    };
    return PasswordStrength;
}(React.Component));
exports.PasswordStrength = PasswordStrength;
