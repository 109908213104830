"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CategoryTotals = void 0;
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var React = require("react");
require("./customer-partner-report.scss");
var currency_parser_1 = require("../../common/libs/currency-parser");
var CategoryTotals = (function (_super) {
    __extends(CategoryTotals, _super);
    function CategoryTotals() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CategoryTotals.prototype.render = function () {
        try {
            return React.createElement("div", { className: 'category-total-box row px-3 row align-items-center justify-content-between' }, Object.entries(this.props.categories).map(function (_a) {
                var _b = __read(_a, 2), category = _b[0], value = _b[1];
                return React.createElement("div", { key: category, className: 'row col-24' },
                    React.createElement("div", { className: 'col-12 display-flex justify-content-start' },
                        React.createElement("span", { className: 'fs-10' }, category)),
                    React.createElement("div", { className: 'col-12 display-flex justify-content-end' },
                        React.createElement("span", { className: 'fs-10' }, (0, currency_parser_1.hufFormat)(value))));
            }));
        }
        catch (e) {
            return null;
        }
    };
    return CategoryTotals;
}(abstract_component_1.AbstractComponent));
exports.CategoryTotals = CategoryTotals;
