"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.B2bStockInventoryCreateScreen = void 0;
var repository_1 = require("@codebuild/glaze/libs/repository/repository");
var router_provider_1 = require("@codebuild/glaze/libs/router/router.provider");
var field_1 = require("@codebuild/sprinkles/libs/form/field");
var form_builder_1 = require("@codebuild/sprinkles/libs/form/form-builder");
var form_control_1 = require("@codebuild/sprinkles/libs/form/form-control/form-control");
var required_validator_1 = require("@codebuild/sprinkles/libs/form/validators/required.validator");
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var trans_1 = require("@codebuild/sprinkles/libs/translation/trans");
var button_1 = require("@codebuild/sprinkles/modules/codebuild/button/react/button");
var select_input_form_control_type_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/form-control-types/select-input.form-control-type");
var select_input_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/select-input");
var table_1 = require("@codebuild/sprinkles/modules/codebuild/table/table");
var table_body_cell_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-body-cell");
var table_body_row_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-body-row");
var table_header_cell_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-header-cell");
var table_header_row_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-header-row");
var React = require("react");
var pagination_list_control_1 = require("../../common/components/repository-list/controls/pagination-list-control");
var sticky_container_1 = require("../../common/components/sticky-container");
var issuing_legal_codes_1 = require("../../common/libs/issuing-legal-codes");
var handle_toasts_1 = require("../../components/libs/handle.toasts");
var message_box_1 = require("../../components/libs/message-box/message-box");
require("./b2b-stock-inventory.screen.scss");
var automatic_save_manager_1 = require("./components/automatic-save-manager");
var stock_inventory_table_stock_row_1 = require("./components/stock-inventory-table-stock-row");
var B2bStockInventoryCreateScreen = (function (_super) {
    __extends(B2bStockInventoryCreateScreen, _super);
    function B2bStockInventoryCreateScreen() {
        var _this = this;
        var _a, _b, _c, _d, _e, _f, _g, _h;
        _this = _super.apply(this, __spreadArray([], __read(arguments), false)) || this;
        _this.stockRefs = {};
        _this.repository = repository_1.Repository.use('default');
        _this.control = new pagination_list_control_1.PaginationListControl({
            defaultData: {
                limit: 20,
                page: (_c = (_b = (_a = _this.props.route) === null || _a === void 0 ? void 0 : _a.query) === null || _b === void 0 ? void 0 : _b._page) !== null && _c !== void 0 ? _c : 0,
                query: (_f = (_e = (_d = _this.props.route) === null || _d === void 0 ? void 0 : _d.query) === null || _e === void 0 ? void 0 : _e.query) !== null && _f !== void 0 ? _f : '',
                sort: (_h = (_g = _this.props.route) === null || _g === void 0 ? void 0 : _g.sort) !== null && _h !== void 0 ? _h : { createdAt: -1 }
            },
        });
        _this.state = {
            loading: false,
            storages: [],
            plan: [],
            error: '',
        };
        _this.form = new form_builder_1.FormBuilder({
            fields: {
                warehouse: new field_1.Field({
                    name: 'warehouse',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('stock-inventory.form.label.warehouse'),
                        " ",
                        React.createElement("i", { className: 'color--error-6' }, "*")),
                    value: '',
                    optionsEndpoint: '/b2b/warehouse',
                    optionsMap: function (response) {
                        var _a;
                        return (_a = response === null || response === void 0 ? void 0 : response.items) === null || _a === void 0 ? void 0 : _a.map(function (item) { return ({
                            name: item.title,
                            value: item._id,
                            key: item._id,
                        }); });
                    },
                    loadOptionsAfterMount: true,
                    validators: [
                        new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))
                    ]
                }),
                legalCodeIssue: new field_1.Field({
                    placeholder: '',
                    value: '',
                    name: 'legalCode',
                    label: React.createElement("div", null, (0, trans_1.trans)('stock-issuing.create.cp.form.issue.legalCode.label')),
                    options: issuing_legal_codes_1.ISSUING_LEGAL_CODES.map(function (c) { return ({ key: c.code, value: c.code, name: "".concat(c.code, " - ").concat(c.title) }); }),
                    loadOptionsAfterMount: true,
                    validators: []
                }),
                legalCodeIncome: new field_1.Field({
                    placeholder: '',
                    value: '',
                    name: 'legalCode',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('stock-issuing.create.cp.form.income.legalCode.label'),
                        " ",
                        React.createElement("i", { className: 'color--error-6' }, "*")),
                    options: issuing_legal_codes_1.INCOME_LEGAL_CODES.map(function (c) { return ({ key: c.code, value: c.code, name: "".concat(c.code, " - ").concat(c.title) }); }),
                    loadOptionsAfterMount: true,
                    validators: []
                }),
            }
        });
        return _this;
    }
    B2bStockInventoryCreateScreen.prototype.componentDidMount = function () {
        var _this = this;
        this.$subscriptions.push(this.form.fields.warehouse.$value.subscribe(function (v) { return _this.handleWarehouseChange(v); }));
    };
    B2bStockInventoryCreateScreen.prototype.handleWarehouseChange = function (value) {
        return __awaiter(this, void 0, void 0, function () {
            var data, result, e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!value) {
                            return [2, this.setState({ storages: [] })];
                        }
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        data = {
                            warehouse: value,
                        };
                        return [4, this.repository.post('/b2b/stock-inventory/plan', { data: data })];
                    case 2:
                        result = _a.sent();
                        this.setState({ storages: result.items, plan: result.plan });
                        return [3, 4];
                    case 3:
                        e_1 = _a.sent();
                        this.setState({ error: e_1.message });
                        return [3, 4];
                    case 4: return [2];
                }
            });
        });
    };
    B2bStockInventoryCreateScreen.prototype.render = function () {
        return React.createElement("div", { className: 'StockInventoryScreen CreateScreen' },
            React.createElement("div", { className: 'row' },
                React.createElement("div", { className: 'col-18 row' },
                    React.createElement("div", { className: 'mb-2 col-24' },
                        React.createElement(button_1.Button, { className: 'button-neutral-link button-size-normal mb-1', title: 'Vissza', iconLeft: 'fas fa-arrow-left', onClick: function () { return router_provider_1.RouterProvider.goTo('/stock-product-inventory'); } })),
                    React.createElement("div", { className: 'mb-4 col-24' },
                        React.createElement("h2", null, (0, trans_1.trans)('stock-inventory.create.title'))),
                    React.createElement("div", { className: 'row col-24 ' },
                        React.createElement(form_control_1.FormControl, { field: this.form.fields.warehouse, type: select_input_form_control_type_1.SelectInputFormControlType },
                            React.createElement(select_input_1.SelectInput, { unclearable: false, multiple: false, searchable: true, className: 'select-input-basic col-8' })),
                        React.createElement(form_control_1.FormControl, { field: this.form.fields.legalCodeIssue, type: select_input_form_control_type_1.SelectInputFormControlType },
                            React.createElement(select_input_1.SelectInput, { unclearable: false, multiple: false, searchable: true, className: 'select-input-basic col-8' })),
                        React.createElement(form_control_1.FormControl, { field: this.form.fields.legalCodeIncome, type: select_input_form_control_type_1.SelectInputFormControlType },
                            React.createElement(select_input_1.SelectInput, { unclearable: false, multiple: false, searchable: true, className: 'select-input-basic col-8' })))),
                React.createElement("div", { className: 'row col-6' },
                    React.createElement("div", { className: 'col-24 save-box-wrapper' }, this.renderSaveBox()),
                    React.createElement("div", { className: 'col-24 save-box-wrapper' },
                        React.createElement(automatic_save_manager_1.AutomaticSaveManager, { form: this.form, stockRefs: this.stockRefs, storages: this.state.storages })))),
            React.createElement("div", { className: 'row' },
                React.createElement("div", { className: 'col' }, this.renderTable())));
    };
    B2bStockInventoryCreateScreen.prototype.renderTable = function () {
        var _this = this;
        return React.createElement(table_1.Table, { className: 'table-basic B2bOrderTable mb-6', renderHeader: function () { return _this.renderTableHeader(); }, renderBody: function () { return _this.renderItems(_this.state.storages); } });
    };
    B2bStockInventoryCreateScreen.prototype.renderItems = function (items) {
        var _this = this;
        if (!items.length) {
            return React.createElement("div", { className: 'display-flex justify-content-center py-7' },
                React.createElement("p", { className: 'fw-700' }, "Nem tal\u00E1lhat\u00F3 term\u00E9k"));
        }
        return items === null || items === void 0 ? void 0 : items.map(function (item) {
            var _a, _b;
            return React.createElement(table_body_row_1.TableBodyRow, { key: item === null || item === void 0 ? void 0 : item._id, className: 'flex-column wrapper main-wrapper' },
                React.createElement(table_body_cell_1.TableBodyCell, { width: 100 },
                    React.createElement(table_body_row_1.TableBodyRow, null,
                        React.createElement(table_body_cell_1.TableBodyCell, null, (_a = item === null || item === void 0 ? void 0 : item.path) === null || _a === void 0 ? void 0 : _a.map(function (p) { return p.title; }).join(' / ')))), (_b = item === null || item === void 0 ? void 0 : item.stockProducts) === null || _b === void 0 ? void 0 :
                _b.map(function (stock) {
                    var _a;
                    return React.createElement(stock_inventory_table_stock_row_1.StockInventoryTableStockRow, { key: stock._id, ref: function (ref) { return _this.stockRefs[stock._id] = ref; }, stock: stock, plan: (_a = _this.state.plan) === null || _a === void 0 ? void 0 : _a.find(function (p) { return p.stockProduct === stock._id; }) });
                }));
        });
    };
    B2bStockInventoryCreateScreen.prototype.renderTableHeader = function () {
        return React.createElement(table_header_row_1.TableHeaderRow, { sticky: true },
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 100 }, this.setHeaderCell('storage'))));
    };
    B2bStockInventoryCreateScreen.prototype.setHeaderCell = function (p, enableSort) {
        var _this = this;
        if (enableSort === void 0) { enableSort = true; }
        return {
            sortValue: this.getSortValue(p),
            onSort: function (property, value) { return _this.handleSort(property, value); },
            enableSort: enableSort,
            title: (0, trans_1.trans)("inventory.table.header.".concat(p)),
            property: p
        };
    };
    B2bStockInventoryCreateScreen.prototype.handleSort = function (property, value) {
        this.control.sort(property, value);
    };
    B2bStockInventoryCreateScreen.prototype.getSortValue = function (property) {
        var _a;
        return ((_a = this.control.data.sort) === null || _a === void 0 ? void 0 : _a[property]) || 0;
    };
    B2bStockInventoryCreateScreen.prototype.renderSaveBox = function () {
        var _this = this;
        return React.createElement(sticky_container_1.StickyContainer, { topSpacing: 32, className: 'w-100' },
            React.createElement("div", { className: 'save-box mb-6' },
                React.createElement("h5", { className: '' }, "L\u00C9TREHOZ\u00C1S"),
                React.createElement("div", { className: 'mt-5' },
                    React.createElement(button_1.Button, { title: this.state.loading ? React.createElement("i", { className: 'far fa-spinner-third fa-spin' }) : 'Mentés', iconLeft: this.state.loading ? '' : 'fal fa-save', className: 'button-success-normal button-size-normal', disabled: false, onClick: function () { return _this.handleSubmit(); } }))),
            this.state.error && React.createElement(message_box_1.MessageBox, { type: 'error', message: React.createElement("div", { className: 'display-flex flex-column align-items-start' }, this.state.error) }));
    };
    B2bStockInventoryCreateScreen.prototype.handleSubmit = function () {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var valid, plan, stockProducts, stockProducts_1, stockProducts_1_1, stock, item, e_2_1, formData, data, e_3;
            var e_2, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 11, 12, 13]);
                        this.setState({ loading: true });
                        return [4, this.form.validate()];
                    case 1:
                        valid = _c.sent();
                        plan = [];
                        stockProducts = (_a = this.state.storages) === null || _a === void 0 ? void 0 : _a.reduce(function (acc, storage) { return __spreadArray(__spreadArray([], __read(acc), false), __read(storage.stockProducts), false); }, []);
                        _c.label = 2;
                    case 2:
                        _c.trys.push([2, 7, 8, 9]);
                        stockProducts_1 = __values(stockProducts), stockProducts_1_1 = stockProducts_1.next();
                        _c.label = 3;
                    case 3:
                        if (!!stockProducts_1_1.done) return [3, 6];
                        stock = stockProducts_1_1.value;
                        return [4, this.stockRefs[stock._id].getData(false)];
                    case 4:
                        item = _c.sent();
                        if (!(item === null || item === void 0 ? void 0 : item.amount) && (item === null || item === void 0 ? void 0 : item.amount) !== 0) {
                            return [3, 5];
                        }
                        plan.push(item);
                        _c.label = 5;
                    case 5:
                        stockProducts_1_1 = stockProducts_1.next();
                        return [3, 3];
                    case 6: return [3, 9];
                    case 7:
                        e_2_1 = _c.sent();
                        e_2 = { error: e_2_1 };
                        return [3, 9];
                    case 8:
                        try {
                            if (stockProducts_1_1 && !stockProducts_1_1.done && (_b = stockProducts_1.return)) _b.call(stockProducts_1);
                        }
                        finally { if (e_2) throw e_2.error; }
                        return [7];
                    case 9:
                        if (!valid) {
                            throw new Error((0, trans_1.trans)('stock-inventory.form.missing.required'));
                        }
                        formData = this.form.toJSON();
                        if (!(formData === null || formData === void 0 ? void 0 : formData.warehouse)) {
                            throw new Error('warehouse is required');
                        }
                        if (!(formData === null || formData === void 0 ? void 0 : formData.legalCodeIssue)) {
                            throw new Error('Kitárolás jógcímkód kötelező mező');
                        }
                        if (!(formData === null || formData === void 0 ? void 0 : formData.legalCodeIncome)) {
                            throw new Error('Betárolás jógcímkód kötelező mező');
                        }
                        data = {
                            warehouse: formData === null || formData === void 0 ? void 0 : formData.warehouse,
                            legalCodeIssue: formData === null || formData === void 0 ? void 0 : formData.legalCodeIssue,
                            legalCodeIncome: formData === null || formData === void 0 ? void 0 : formData.legalCodeIncome,
                            plan: plan === null || plan === void 0 ? void 0 : plan.map(function (p) { return ({
                                stockProduct: p.product,
                                amount: parseInt(p.amount, 10),
                            }); }),
                        };
                        return [4, this.repository.post('/b2b/stock-inventory', { data: data })];
                    case 10:
                        _c.sent();
                        handle_toasts_1.HandleToasts.success((0, trans_1.trans)('stock-inventory.create.success.toast'));
                        return [2, router_provider_1.RouterProvider.goTo('/stock-product-inventory')];
                    case 11:
                        e_3 = _c.sent();
                        this.setState({ loading: false, error: e_3.message });
                        return [3, 13];
                    case 12:
                        this.setState({ loading: false });
                        return [7];
                    case 13: return [2];
                }
            });
        });
    };
    return B2bStockInventoryCreateScreen;
}(abstract_component_1.AbstractComponent));
exports.B2bStockInventoryCreateScreen = B2bStockInventoryCreateScreen;
