"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Orders = void 0;
var repository_1 = require("@codebuild/glaze/libs/repository/repository");
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var table_1 = require("@codebuild/sprinkles/modules/codebuild/table/table");
var table_body_cell_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-body-cell");
var table_body_row_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-body-row");
var table_header_cell_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-header-cell");
var table_header_row_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-header-row");
var React = require("react");
var currency_parser_1 = require("../../../common/libs/currency-parser");
var Orders = (function (_super) {
    __extends(Orders, _super);
    function Orders() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.repository = repository_1.Repository.use('default');
        _this.state = {
            loading: false,
            orders: [],
            activeOrder: null,
        };
        return _this;
    }
    Orders.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.fetchOrders()];
                    case 1:
                        _a.sent();
                        return [2];
                }
            });
        });
    };
    Orders.prototype.render = function () {
        var _this = this;
        var _a, _b;
        if (!((_b = (_a = this.state) === null || _a === void 0 ? void 0 : _a.orders) === null || _b === void 0 ? void 0 : _b.length)) {
            return this.renderLoader();
        }
        return (React.createElement("div", { className: 'row' },
            React.createElement("div", { className: 'row col-5 scrollable-div-small' }, this.state.orders.map(function (order) {
                var activeClass = _this.state.activeOrder._id === order._id ? 'bgc-light-grey' : '';
                return React.createElement("div", { className: "col-24 py-3 hover-opacity-strong click-transition-light max-height-48 ".concat(activeClass), key: order.identifier, onClick: function () { return _this.selectActive(order._id); } },
                    React.createElement("span", { className: 'fw-700' }, order.identifier));
            })),
            React.createElement("div", { className: 'col-19' }, this.renderOrderContent())));
    };
    Orders.prototype.selectActive = function (orderId) {
        this.setState({ activeOrder: this.state.orders.find(function (order) { return order._id === orderId; }) });
    };
    Orders.prototype.renderOrderContent = function () {
        var activeOrder = this.state.activeOrder;
        return React.createElement("div", { className: 'row' },
            React.createElement("div", { className: 'col-24' }, this.renderTable(activeOrder)));
    };
    Orders.prototype.renderTable = function (items) {
        var _this = this;
        return React.createElement(table_1.Table, { className: 'table-basic', renderHeader: function () { return _this.renderTableHeader(); }, renderBody: function () { return _this.renderItems(items); } });
    };
    Orders.prototype.renderTableHeader = function () {
        return React.createElement(table_header_row_1.TableHeaderRow, { sticky: false },
            React.createElement(table_header_cell_1.TableHeaderCell, { width: 30, enableSort: false, property: 'name', title: 'Termék neve' }),
            React.createElement(table_header_cell_1.TableHeaderCell, { width: 30, enableSort: false, property: 'unit', title: 'Egység ár' }),
            React.createElement(table_header_cell_1.TableHeaderCell, { width: 30, enableSort: false, property: 'amount', title: 'Rendelt' }),
            React.createElement(table_header_cell_1.TableHeaderCell, { width: 30, enableSort: false, property: 'total', title: 'Termék totál' }));
    };
    Orders.prototype.renderItems = function (activeOrder) {
        var _this = this;
        var currency = (activeOrder === null || activeOrder === void 0 ? void 0 : activeOrder.currency) || 'HUF';
        return React.createElement(table_body_row_1.TableBodyRow, { className: 'wrapper' },
            React.createElement(table_body_cell_1.TableBodyCell, { width: 100, className: ' flex-column' }, activeOrder.plan.map(function (plan) { return _this.renderItem(plan, currency); })));
    };
    Orders.prototype.renderItem = function (productItem, currency) {
        var _a, _b, _c, _d, _e;
        var sku = ((_a = productItem === null || productItem === void 0 ? void 0 : productItem.product) === null || _a === void 0 ? void 0 : _a.sku) || '-';
        var manufacturer = ((_c = (_b = productItem === null || productItem === void 0 ? void 0 : productItem.product) === null || _b === void 0 ? void 0 : _b.manufacturerName) === null || _c === void 0 ? void 0 : _c.hu) || '-';
        var title = ((_e = (_d = productItem === null || productItem === void 0 ? void 0 : productItem.product) === null || _d === void 0 ? void 0 : _d.title) === null || _e === void 0 ? void 0 : _e.hu) || '-';
        var price = productItem.calculatedPrice;
        var amount = productItem.amount;
        var total = amount * price;
        return React.createElement(table_body_row_1.TableBodyRow, { className: 'wrapper' },
            React.createElement(table_body_cell_1.TableBodyCell, { width: 30 },
                React.createElement("div", { className: 'row' },
                    React.createElement("div", { className: 'col-24' }, sku),
                    React.createElement("div", { className: 'col-24' }, manufacturer),
                    React.createElement("div", { className: 'col-24' }, title))),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 30 }, (0, currency_parser_1.moneyFormat)(price, currency)),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 30 }, amount),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 30 }, (0, currency_parser_1.moneyFormat)(total, currency)));
    };
    Orders.prototype.renderLoader = function () {
        return React.createElement("div", { className: 'display-flex align-items-center w-100 my-7 pl-7' },
            React.createElement("div", { className: 'pl-7' },
                React.createElement("i", { className: "fas fa-spinner-third fa-spin fs-20" })));
    };
    Orders.prototype.fetchOrders = function () {
        return __awaiter(this, void 0, void 0, function () {
            var response, orders, firstOrder, e_1;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, 3, 4]);
                        this.setState({ loading: true });
                        return [4, Promise.all(this.props.orderIds.map(function (orderId) { return _this.fetchOrder(orderId); }))];
                    case 1:
                        response = _a.sent();
                        orders = (response === null || response === void 0 ? void 0 : response.filter(function (i) { return i; })) || [];
                        firstOrder = orders[0];
                        this.setState({ orders: orders, activeOrder: firstOrder });
                        return [3, 4];
                    case 2:
                        e_1 = _a.sent();
                        console.log({ fetchOrders: e_1 });
                        return [3, 4];
                    case 3:
                        this.setState({ loading: false });
                        return [7];
                    case 4: return [2];
                }
            });
        });
    };
    Orders.prototype.fetchOrder = function (orderId) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                try {
                    this.setState({ loading: true });
                    return [2, this.repository.get("/b2b/order/".concat(orderId))];
                }
                catch (e) {
                    console.log({ fetchOrders: e });
                }
                finally {
                    this.setState({ loading: false });
                }
                return [2];
            });
        });
    };
    return Orders;
}(abstract_component_1.AbstractComponent));
exports.Orders = Orders;
