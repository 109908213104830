"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SalesDashboardScreen = void 0;
var repository_1 = require("@codebuild/glaze/libs/repository/repository");
var local_store_1 = require("@codebuild/glaze/libs/store/local.store");
var trans_1 = require("@codebuild/glaze/libs/translation/trans");
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var React = require("react");
var year_selector_1 = require("../../common/components/year-selector");
var user_permission_1 = require("../../common/libs/user-permission");
var dashboard_total_boxes_1 = require("./components/dashboard-total-boxes");
var expired_credits_1 = require("./components/expired-credits");
var has_not_ordered_for_a_while_1 = require("./components/has-not-ordered-for-a-while");
var plan_data_1 = require("./components/plan-data");
var sales_managers_selector_1 = require("./components/sales-managers.selector");
var yearly_sales_1 = require("./components/yearly-sales");
var yearly_sales_by_partner_1 = require("./components/yearly-sales-by-partner");
require("./components/sales-dashboard.scss");
var SalesDashboardScreen = (function (_super) {
    __extends(SalesDashboardScreen, _super);
    function SalesDashboardScreen() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.repository = repository_1.Repository.use('default');
        _this.state = {
            loading: false,
            isAdmin: false,
            year: new Date().getFullYear(),
            customerPartners: [],
            user: null
        };
        _this.baseTransText = 'sales.dashboard';
        return _this;
    }
    SalesDashboardScreen.prototype.componentDidMount = function () {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var user;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0: return [4, local_store_1.LocalStore.getItem('User')];
                    case 1:
                        user = _c.sent();
                        return [4, this.activateCache()];
                    case 2:
                        _c.sent();
                        if (((_a = user === null || user === void 0 ? void 0 : user.publicMeta) === null || _a === void 0 ? void 0 : _a.role) === user_permission_1.Role.admin || ((_b = user === null || user === void 0 ? void 0 : user.publicMeta) === null || _b === void 0 ? void 0 : _b.role) === user_permission_1.Role.superadmin) {
                            return [2, this.setState({ isAdmin: true })];
                        }
                        this.setState({ user: user });
                        return [4, this.fetchOptions()];
                    case 3:
                        _c.sent();
                        return [2];
                }
            });
        });
    };
    SalesDashboardScreen.prototype.render = function () {
        var _this = this;
        return React.createElement("div", { className: 'row' },
            React.createElement("div", { className: 'col-24 row' },
                React.createElement("div", { className: 'col-12 display-flex justify-content-start' },
                    React.createElement("h2", { className: 'mr-4' }, (0, trans_1.trans)("".concat(this.baseTransText, ".title")))),
                React.createElement("div", { className: 'col-12 display-flex justify-content-end' }, this.renderSalesSelector())),
            React.createElement("div", { className: 'col-24 row' },
                React.createElement(year_selector_1.YearSelector, { onChange: function (year) { return _this.setState({ year: year }); }, className: 'col-md-8 col-12' })),
            React.createElement("div", { className: 'col-24 display-flex align-items-start my-5' },
                React.createElement(dashboard_total_boxes_1.DashboardTotalBoxes, { user: this.state.user, year: this.state.year })),
            React.createElement("div", { className: 'col-24 display-flex align-items-start mb-5' },
                React.createElement(plan_data_1.PlanData, { user: this.state.user, year: this.state.year })),
            React.createElement("div", { className: 'col-24 row display-flex align-items-start my-5' },
                React.createElement("div", { className: 'col-16' },
                    React.createElement(yearly_sales_1.YearlySales, { user: this.state.user, year: this.state.year })),
                React.createElement("div", { className: 'col-8' },
                    React.createElement(has_not_ordered_for_a_while_1.HasNotOrderedForAWhile, { user: this.state.user, year: this.state.year }))),
            React.createElement("div", { className: 'col-24 row display-flex align-items-start my-5' },
                React.createElement("div", { className: 'col-16' },
                    React.createElement(yearly_sales_by_partner_1.YearlySalesByPartner, { customerPartners: this.state.customerPartners, year: this.state.year })),
                React.createElement("div", { className: 'col-8' },
                    React.createElement(expired_credits_1.ExpiredCredits, { user: this.state.user, year: this.state.year }))));
    };
    SalesDashboardScreen.prototype.renderSalesSelector = function () {
        var _this = this;
        if (!this.state.isAdmin) {
            return;
        }
        return React.createElement(sales_managers_selector_1.SalesManagersSelector, { onUserChange: function (_a) {
                var customerPartners = _a.customerPartners, user = _a.user;
                return _this.setState({ customerPartners: customerPartners, user: user });
            } });
    };
    SalesDashboardScreen.prototype.fetchOptions = function () {
        return __awaiter(this, void 0, void 0, function () {
            var response, e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, 3, 4]);
                        this.setState({ loading: true });
                        return [4, this.repository.get('/b2b/sales-dashboard/customer-partners-by-sales-manager')];
                    case 1:
                        response = _a.sent();
                        this.setState({ customerPartners: response });
                        return [3, 4];
                    case 2:
                        e_1 = _a.sent();
                        console.log({ DashboardTotalBoxes: e_1 });
                        return [3, 4];
                    case 3:
                        this.setState({ loading: false });
                        return [7];
                    case 4: return [2];
                }
            });
        });
    };
    SalesDashboardScreen.prototype.activateCache = function () {
        return __awaiter(this, void 0, void 0, function () {
            var e_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4, this.repository.get('/b2b/sales-dashboard/sales-managers')];
                    case 1:
                        _a.sent();
                        return [3, 3];
                    case 2:
                        e_2 = _a.sent();
                        console.log({ activateCache: e_2 });
                        return [3, 3];
                    case 3: return [2];
                }
            });
        });
    };
    return SalesDashboardScreen;
}(abstract_component_1.AbstractComponent));
exports.SalesDashboardScreen = SalesDashboardScreen;
