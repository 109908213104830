"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.StockInventoryTableStockRow = void 0;
var field_1 = require("@codebuild/sprinkles/libs/form/field");
var form_builder_1 = require("@codebuild/sprinkles/libs/form/form-builder");
var form_control_1 = require("@codebuild/sprinkles/libs/form/form-control/form-control");
var required_validator_1 = require("@codebuild/sprinkles/libs/form/validators/required.validator");
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var trans_1 = require("@codebuild/sprinkles/libs/translation/trans");
var text_input_form_control_type_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/form-control-types/text-input.form-control-type");
var text_input_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/text-input");
var table_body_cell_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-body-cell");
var table_body_row_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-body-row");
var lodash_1 = require("lodash");
var React = require("react");
var StockInventoryTableStockRow = (function (_super) {
    __extends(StockInventoryTableStockRow, _super);
    function StockInventoryTableStockRow() {
        var _this = this;
        var _a, _b, _c;
        _this = _super.apply(this, __spreadArray([], __read(arguments), false)) || this;
        _this.state = {
            stock: _this.props.stock || {},
            plan: _this.props.plan || {},
        };
        _this.form = new form_builder_1.FormBuilder({
            fields: {
                product: new field_1.Field({
                    name: 'product',
                    value: ((_a = _this.props.plan) === null || _a === void 0 ? void 0 : _a.stockProduct) || ((_b = _this.props.stock) === null || _b === void 0 ? void 0 : _b._id)
                }),
                amount: new field_1.Field({
                    name: 'amount',
                    value: ((_c = _this.props.plan) === null || _c === void 0 ? void 0 : _c.amount) || '',
                    validators: [
                        new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required')),
                    ]
                })
            }
        });
        return _this;
    }
    StockInventoryTableStockRow.prototype.componentDidMount = function () {
        var _a;
        if (!isNaN(+((_a = this.props.stock) === null || _a === void 0 ? void 0 : _a.amount)) && !this.form.fields.amount.getValue()) {
            this.form.fields.amount.setValue(this.props.stock.amount);
        }
    };
    StockInventoryTableStockRow.prototype.componentDidUpdate = function (prevProps, prevState, snapshot) {
        var _a;
        if (!(0, lodash_1.isEqual)(prevProps.stock, this.props.stock) && !isNaN(+((_a = this.props.stock) === null || _a === void 0 ? void 0 : _a.amount))) {
            this.form.fields.amount.setValue(this.props.stock.amount);
        }
    };
    StockInventoryTableStockRow.prototype.render = function () {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u;
        var ean = (_a = this.state.stock) === null || _a === void 0 ? void 0 : _a.ean;
        var lot = (_b = this.state.stock) === null || _b === void 0 ? void 0 : _b.lot;
        var sku = (_d = (_c = this.state.stock) === null || _c === void 0 ? void 0 : _c.product) === null || _d === void 0 ? void 0 : _d.sku;
        var manufacturer = (_g = (_f = (_e = this.state.stock) === null || _e === void 0 ? void 0 : _e.product) === null || _f === void 0 ? void 0 : _f.manufacturerName) === null || _g === void 0 ? void 0 : _g.hu;
        var title = (_k = (_j = (_h = this.state.stock) === null || _h === void 0 ? void 0 : _h.product) === null || _j === void 0 ? void 0 : _j.title) === null || _k === void 0 ? void 0 : _k.hu;
        var vintage = (_m = (_l = this.state.stock) === null || _l === void 0 ? void 0 : _l.product) === null || _m === void 0 ? void 0 : _m.vintage;
        var capacity = (_r = (_q = (_p = (_o = this.state.stock) === null || _o === void 0 ? void 0 : _o.product) === null || _p === void 0 ? void 0 : _p.b2cAttributeSearchValues) === null || _q === void 0 ? void 0 : _q.capacity) === null || _r === void 0 ? void 0 : _r[1];
        return React.createElement(table_body_cell_1.TableBodyCell, { key: (_s = this.state.stock) === null || _s === void 0 ? void 0 : _s._id, width: 100 },
            React.createElement(table_body_row_1.TableBodyRow, null,
                React.createElement(table_body_cell_1.TableBodyCell, { width: 100 },
                    React.createElement(table_body_row_1.TableBodyRow, { className: 'border-0' },
                        React.createElement("div", { style: { width: 32 } }),
                        React.createElement(table_body_cell_1.TableBodyCell, { width: 70 },
                            React.createElement("div", null,
                                React.createElement("p", null,
                                    React.createElement("small", { className: 'color--neutral-8 fw-700 mr-2' }, "EAN"),
                                    React.createElement("span", null, ean)),
                                React.createElement("p", null,
                                    React.createElement("small", { className: 'color--neutral-8 fw-700 mr-2' }, "SKU"),
                                    React.createElement("span", null, sku)),
                                React.createElement("p", null,
                                    React.createElement("small", { className: 'color--neutral-8 fw-700 mr-2' }, "LOT"),
                                    React.createElement("span", null, lot)),
                                React.createElement("p", null,
                                    manufacturer,
                                    " ",
                                    title,
                                    " ",
                                    vintage,
                                    " (",
                                    capacity,
                                    ")"))),
                        React.createElement(table_body_cell_1.TableBodyCell, { width: 15 }, (_t = this.state.stock) === null || _t === void 0 ? void 0 :
                            _t.amount,
                            " db"),
                        React.createElement(table_body_cell_1.TableBodyCell, { width: 15 },
                            React.createElement(form_control_1.FormControl, { field: this.form.fields.amount, type: text_input_form_control_type_1.TextInputFormControlType },
                                React.createElement(text_input_1.TextInput, { readOnly: this.props.readOnly, disabled: this.props.readOnly, className: 'text-input-basic w-50', type: 'number', min: 0, max: (_u = this.state.stock) === null || _u === void 0 ? void 0 : _u.amount })))))));
    };
    StockInventoryTableStockRow.prototype.getData = function (validate) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var errors, hasError_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!validate) return [3, 3];
                        return [4, this.form.validate()];
                    case 1:
                        _b.sent();
                        return [4, ((_a = this.form) === null || _a === void 0 ? void 0 : _a.getErrors())];
                    case 2:
                        errors = _b.sent();
                        hasError_1 = false;
                        (0, lodash_1.map)(errors, function (e) {
                            hasError_1 = hasError_1 || !!e.length;
                        });
                        if (hasError_1) {
                            return [2, { error: true }];
                        }
                        _b.label = 3;
                    case 3: return [2, __assign({}, this.form.toJSON())];
                }
            });
        });
    };
    return StockInventoryTableStockRow;
}(abstract_component_1.AbstractComponent));
exports.StockInventoryTableStockRow = StockInventoryTableStockRow;
