"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getDateRangeByYear = void 0;
var moment = require("moment");
var getDateRangeByYear = function (year) {
    if (year === void 0) { year = new Date().getFullYear(); }
    var today = new Date();
    var month = today.getMonth() + 1;
    var day = today.getDate();
    var date = moment("".concat(year, "-").concat(month, "-").concat(day), 'YYYY-MM-DD');
    var from = date.startOf('year').toString();
    var to = date.endOf('year').toString();
    return {
        from: from,
        to: to
    };
};
exports.getDateRangeByYear = getDateRangeByYear;
