"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.StockItem = void 0;
var repository_1 = require("@codebuild/glaze/libs/repository/repository");
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var trans_1 = require("@codebuild/sprinkles/libs/translation/trans");
var table_body_cell_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-body-cell");
var table_body_row_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-body-row");
var lodash_1 = require("lodash");
var React = require("react");
var currency_parser_1 = require("../../../common/libs/currency-parser");
var StockItem = (function (_super) {
    __extends(StockItem, _super);
    function StockItem() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.repository = repository_1.Repository.use('default');
        _this.state = {
            loading: false,
            price: 0
        };
        return _this;
    }
    StockItem.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.fetchProcurementPrice()];
                    case 1:
                        _a.sent();
                        return [2];
                }
            });
        });
    };
    StockItem.prototype.render = function () {
        var _this = this;
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
        var data = (0, lodash_1.clone)(this.props.item);
        var sum = 0;
        data.items = this.filterStorages(data.items);
        data.items = (_a = data.items) === null || _a === void 0 ? void 0 : _a.reduce(function (result, i) {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
            var lotIndex = result.indexOf(result.find(function (l) { return l.lot === i.productLot; }));
            var lotPosition = lotIndex < 0 ? result.length : lotIndex;
            var lot = result[lotPosition] = result[lotPosition] || { lot: i.productLot, lotItems: [], sum: 0 };
            var storageIndex = (_a = lot.lotItems) === null || _a === void 0 ? void 0 : _a.indexOf((_b = lot.lotItems) === null || _b === void 0 ? void 0 : _b.find(function (s) { return s.storage === i.storageId; }));
            var storagePosition = storageIndex < 0 ? (_c = lot.lotItems) === null || _c === void 0 ? void 0 : _c.length : storageIndex;
            var storage = lot.lotItems[storagePosition] = lot.lotItems[storagePosition] || { storage: i.storageId, storageItems: [], sum: 0, title: (_e = (_d = i.__meta) === null || _d === void 0 ? void 0 : _d.storage) === null || _e === void 0 ? void 0 : _e.title, path: (_g = (_f = i.__meta) === null || _f === void 0 ? void 0 : _f.storage) === null || _g === void 0 ? void 0 : _g.path };
            var typeIndex = (_h = storage.storageItems) === null || _h === void 0 ? void 0 : _h.indexOf((_j = storage.storageItems) === null || _j === void 0 ? void 0 : _j.find(function (t) { return t.type === i.type; }));
            var typePosition = typeIndex < 0 ? (_k = storage.storageItems) === null || _k === void 0 ? void 0 : _k.length : typeIndex;
            var type = storage.storageItems[typePosition] = storage.storageItems[typePosition] || { type: i.type, typeItems: [], sum: 0 };
            (_l = type.typeItems) === null || _l === void 0 ? void 0 : _l.push(i);
            var procurement = +(i === null || i === void 0 ? void 0 : i.procurement);
            type.sum += i.amount;
            type.procurement = (type.procurement || 0) + isNaN(procurement) ? 0 : procurement;
            storage.sum += i.amount;
            lot.sum += i.amount;
            sum += i.amount;
            return result;
        }, []);
        var drs = ((_b = data === null || data === void 0 ? void 0 : data.product) === null || _b === void 0 ? void 0 : _b.drs) ? '(DRS)' : ((_c = data === null || data === void 0 ? void 0 : data.product) === null || _c === void 0 ? void 0 : _c.drs2) ? '(VISSZAVÁLTHATÓ ÜVEG)' : '';
        var procurementPrice = this.state.price;
        var procurementPriceTotal = procurementPrice * sum;
        return React.createElement(table_body_row_1.TableBodyRow, { className: 'ean flex-column wrapper' },
            React.createElement(table_body_cell_1.TableBodyCell, { width: 100 },
                React.createElement(table_body_row_1.TableBodyRow, { className: 'cursor-pointer align-items-center content', onClick: function () { var _a; return _this.props.handleRowClick((_a = data.product) === null || _a === void 0 ? void 0 : _a.sku); } },
                    React.createElement("div", { className: 'chevron-container' },
                        React.createElement("i", { className: "fal ".concat(this.props.openedRows.includes("".concat((_d = data.product) === null || _d === void 0 ? void 0 : _d.sku)) ? 'fa-chevron-up' : 'fa-chevron-down') })),
                    React.createElement(table_body_cell_1.TableBodyCell, { width: 15 },
                        React.createElement("p", null, (_e = data.product) === null || _e === void 0 ? void 0 : _e.sku)),
                    React.createElement(table_body_cell_1.TableBodyCell, { width: 15 },
                        React.createElement("p", null, (_f = data.product) === null || _f === void 0 ? void 0 : _f.ean)),
                    React.createElement(table_body_cell_1.TableBodyCell, { width: 40 },
                        React.createElement("p", null, (_g = data.product) === null || _g === void 0 ? void 0 :
                            _g.manufacturer,
                            " ", (_h = data.product) === null || _h === void 0 ? void 0 :
                            _h.title,
                            " ", (_j = data.product) === null || _j === void 0 ? void 0 :
                            _j.vintage,
                            " (", (_k = data.product) === null || _k === void 0 ? void 0 :
                            _k.capacity,
                            ") ",
                            drs)),
                    React.createElement(table_body_cell_1.TableBodyCell, { width: 10, className: 'justify-content-end' },
                        React.createElement("p", { className: 'color--neutral-4' },
                            "\u2211 ",
                            sum,
                            " db")),
                    React.createElement(table_body_cell_1.TableBodyCell, { width: 10, className: 'justify-content-end' },
                        React.createElement("p", { className: 'color--neutral-4' }, this.renderProcurementPrice(procurementPrice))),
                    React.createElement(table_body_cell_1.TableBodyCell, { width: 10, className: 'justify-content-end' },
                        React.createElement("p", { className: 'color--neutral-4' }, this.renderProcurementPrice(procurementPriceTotal))))), (_l = data.items) === null || _l === void 0 ? void 0 :
            _l.map(function (lot) {
                var _a;
                return React.createElement(table_body_cell_1.TableBodyCell, { key: "".concat(lot.lot, "-").concat(lot.amount), width: 100, className: "".concat(_this.props.openedRows.includes((_a = data.product) === null || _a === void 0 ? void 0 : _a.sku) ? 'opened' : 'closed') }, _this.renderLotRow(data, lot, _this.props.item));
            }));
    };
    StockItem.prototype.renderProcurementPrice = function (price) {
        if (this.state.loading) {
            return React.createElement("i", { className: 'far fa-spinner-third fa-spin' });
        }
        if (!price) {
            return 'Nincs ár';
        }
        return (0, currency_parser_1.hufFormat)(price);
    };
    StockItem.prototype.filterStorages = function (items) {
        var _a, _b;
        var warehouse = (_a = this.props.control.getQuery()) === null || _a === void 0 ? void 0 : _a.warehouse;
        if (!(warehouse === null || warehouse === void 0 ? void 0 : warehouse.length) || !(items === null || items === void 0 ? void 0 : items.length)) {
            return items;
        }
        var selectedWarehouse = (_b = this.props.warehouse.getOptions()) === null || _b === void 0 ? void 0 : _b.filter(function (option) { return warehouse === null || warehouse === void 0 ? void 0 : warehouse.includes(option === null || option === void 0 ? void 0 : option.value); }).map(function (i) { return i === null || i === void 0 ? void 0 : i.key; });
        if (!(selectedWarehouse === null || selectedWarehouse === void 0 ? void 0 : selectedWarehouse.length)) {
            return items;
        }
        return items.filter(function (i) { return i.storageId.path.find(function (p) { return selectedWarehouse.includes(p.identifier); }); });
    };
    StockItem.prototype.renderLotRow = function (data, lot, item) {
        var _this = this;
        var _a;
        var lotItems = lot.lotItems;
        return React.createElement(table_body_row_1.TableBodyRow, { className: 'lot flex-column wrapper' },
            React.createElement(table_body_cell_1.TableBodyCell, { width: 100 },
                React.createElement("div", { style: { width: 32 } }),
                React.createElement(table_body_row_1.TableBodyRow, { key: item._id, className: 'cursor-pointer align-items-center content', onClick: function () { var _a; return _this.props.handleRowClick("".concat((_a = data.product) === null || _a === void 0 ? void 0 : _a.sku, "-").concat(lot.lot)); } },
                    React.createElement("div", { className: 'chevron-container' },
                        React.createElement("i", { className: "fal ".concat(this.props.openedRows.includes("".concat((_a = data.product) === null || _a === void 0 ? void 0 : _a.sku, "-").concat(lot.lot)) ? 'fa-chevron-up' : 'fa-chevron-down') })),
                    React.createElement(table_body_cell_1.TableBodyCell, { width: 90 },
                        React.createElement("p", null, lot.lot)),
                    React.createElement(table_body_cell_1.TableBodyCell, { width: 10, className: 'justify-content-end' },
                        React.createElement("p", { className: 'color--neutral-4' },
                            "\u2211 ",
                            lot.sum,
                            " db")))), lotItems === null || lotItems === void 0 ? void 0 :
            lotItems.map(function (storage) {
                var _a;
                return React.createElement(table_body_cell_1.TableBodyCell, { width: 100, key: storage.identifier, className: "".concat(_this.props.openedRows.includes("".concat((_a = data.product) === null || _a === void 0 ? void 0 : _a.sku, "-").concat(lot.lot)) ? 'opened' : 'closed') }, _this.renderStorageRow(data, lot, storage, item));
            }));
    };
    StockItem.prototype.renderStorageRow = function (data, lot, storage, item) {
        var _this = this;
        var _a, _b, _c, _d;
        return React.createElement(table_body_row_1.TableBodyRow, { className: 'storage flex-column wrapper' },
            React.createElement(table_body_cell_1.TableBodyCell, { width: 100 },
                React.createElement("div", { style: { width: 64 } }),
                React.createElement(table_body_row_1.TableBodyRow, { key: item._id, className: 'cursor-pointer align-items-center content', onClick: function () { var _a; return _this.props.handleRowClick("".concat((_a = data.product) === null || _a === void 0 ? void 0 : _a.sku, "-").concat(lot.lot, "-").concat(storage.storage)); } },
                    React.createElement("div", { className: 'chevron-container' },
                        React.createElement("i", { className: "fal ".concat(this.props.openedRows.includes("".concat((_a = data.product) === null || _a === void 0 ? void 0 : _a.sku, "-").concat(lot.lot, "-").concat(storage.storage)) ? 'fa-chevron-up' : 'fa-chevron-down') })),
                    React.createElement(table_body_cell_1.TableBodyCell, { width: 90 },
                        React.createElement("p", null, (_c = (_b = storage.path) === null || _b === void 0 ? void 0 : _b.map(function (p) { return p.title; })) === null || _c === void 0 ? void 0 : _c.join('/'))),
                    React.createElement(table_body_cell_1.TableBodyCell, { width: 10, className: 'justify-content-end' },
                        React.createElement("p", { className: 'color--neutral-4' },
                            "\u2211 ",
                            storage.sum,
                            " db")))), (_d = (0, lodash_1.orderBy)(storage.storageItems, 'type', 'desc')) === null || _d === void 0 ? void 0 :
            _d.map(function (type) {
                var _a;
                return React.createElement(table_body_cell_1.TableBodyCell, { width: 100, key: type.type, className: "".concat(_this.props.openedRows.includes("".concat((_a = data.product) === null || _a === void 0 ? void 0 : _a.sku, "-").concat(lot.lot, "-").concat(storage.storage)) ? 'opened' : 'closed') }, _this.renderTypeRow(data, lot, storage, type, item));
            }));
    };
    StockItem.prototype.renderTypeRow = function (data, lot, storage, type, item) {
        return React.createElement(table_body_row_1.TableBodyRow, { className: 'type flex-column wrapper' },
            React.createElement(table_body_row_1.TableBodyRow, { className: 'type flex-column wrapper' },
                React.createElement(table_body_cell_1.TableBodyCell, { width: 100 },
                    React.createElement("div", { style: { width: 128 } }),
                    type.type === 'available'
                        ? React.createElement(table_body_row_1.TableBodyRow, { key: item._id, className: "align-items-center content type ".concat(type.type) },
                            React.createElement("div", { className: 'chevron-container' }),
                            React.createElement(table_body_cell_1.TableBodyCell, { width: 90 },
                                React.createElement("p", null, (0, trans_1.trans)('b2b-stock.management.type.available'))),
                            React.createElement(table_body_cell_1.TableBodyCell, { width: 10, className: 'justify-content-end' },
                                React.createElement("p", { className: 'fw-700' },
                                    type.sum,
                                    " db")))
                        : this.renderDataRow(data, lot, storage, type, item))),
            React.createElement(table_body_row_1.TableBodyRow, { className: 'type flex-column wrapper' },
                React.createElement(table_body_cell_1.TableBodyCell, { width: 100 },
                    React.createElement("div", { style: { width: 128 } }),
                    React.createElement(table_body_row_1.TableBodyRow, { key: item._id, style: { backgroundColor: 'rgba(102,202,245,0.36)' }, className: 'align-items-center content type-procurement' },
                        React.createElement("div", { className: 'chevron-container' }),
                        React.createElement(table_body_cell_1.TableBodyCell, { width: 90 },
                            React.createElement("p", null, (0, trans_1.trans)('b2b-stock.management.type.procurement'))),
                        React.createElement(table_body_cell_1.TableBodyCell, { width: 10, className: 'justify-content-end' },
                            React.createElement("p", { className: 'fw-700' },
                                type.procurement,
                                " db"))))));
    };
    StockItem.prototype.renderDataRow = function (data, lot, storage, type, item) {
        var _this = this;
        var _a, _b;
        return React.createElement(table_body_row_1.TableBodyRow, { key: item._id, className: 'cursor-pointer flex-column wrapper' },
            React.createElement(table_body_cell_1.TableBodyCell, { width: 100 },
                React.createElement(table_body_row_1.TableBodyRow, { key: item._id, className: "align-items-center content type ".concat(type.type), onClick: function () { var _a; return _this.props.handleRowClick("".concat((_a = data.product) === null || _a === void 0 ? void 0 : _a.sku, "-").concat(lot.lot, "-").concat(storage.storage, "-").concat(type.type)); } },
                    React.createElement("div", { className: 'chevron-container' },
                        React.createElement("i", { className: "fal ".concat(this.props.openedRows.includes("".concat((_a = data.product) === null || _a === void 0 ? void 0 : _a.sku, "-").concat(lot.lot, "-").concat(storage.storage, "-").concat(type.type)) ? 'fa-chevron-up' : 'fa-chevron-down') })),
                    React.createElement(table_body_cell_1.TableBodyCell, { width: 90 },
                        React.createElement("p", null, (0, trans_1.trans)("b2b-stock.management.type.".concat(type.type)))),
                    React.createElement(table_body_cell_1.TableBodyCell, { width: 10, className: 'justify-content-end' },
                        React.createElement("p", { className: 'color--neutral-4' },
                            "\u2211 ",
                            type.sum,
                            " db")))),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 100, className: "mb-4 flex-column ".concat(this.props.openedRows.includes("".concat((_b = data.product) === null || _b === void 0 ? void 0 : _b.sku, "-").concat(lot.lot, "-").concat(storage.storage, "-").concat(type.type)) ? 'opened' : 'closed') }, this.renderStock(type.typeItems, item)));
    };
    StockItem.prototype.renderStock = function (typeItems, item) {
        return typeItems === null || typeItems === void 0 ? void 0 : typeItems.map(function (stock, index) {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
            var name = stock.type === 'issue-lock' ? (_c = (_b = (_a = stock.issue) === null || _a === void 0 ? void 0 : _a.customerPartner) === null || _b === void 0 ? void 0 : _b.name) !== null && _c !== void 0 ? _c : '' : (_e = (_d = stock.lockCustomerPartner) === null || _d === void 0 ? void 0 : _d.name) !== null && _e !== void 0 ? _e : '';
            var identifier = stock.type === 'issue-lock' ? (_g = (_f = stock === null || stock === void 0 ? void 0 : stock.issue) === null || _f === void 0 ? void 0 : _f.identifier) !== null && _g !== void 0 ? _g : '' : (_h = stock === null || stock === void 0 ? void 0 : stock.lockOrder) === null || _h === void 0 ? void 0 : _h.identifier;
            return React.createElement("div", { key: item.ean, className: 'w-100 display-flex' },
                React.createElement("div", { style: { width: 32 } }),
                !!stock.amount && React.createElement(table_body_row_1.TableBodyRow, { className: 'align-items-center content reservation-item' },
                    stock.type === 'cp-reservation' && React.createElement(table_body_cell_1.TableBodyCell, { width: 90 },
                        React.createElement("p", null, (_k = (_j = stock.__meta) === null || _j === void 0 ? void 0 : _j.owners) === null || _k === void 0 ? void 0 : _k.map(function (cp) { return cp.name; }).join(', '))),
                    stock.type === 'ch-reservation' && React.createElement(table_body_cell_1.TableBodyCell, { width: 90 },
                        React.createElement("p", null, stock.channels.join(', '))),
                    React.createElement(table_body_cell_1.TableBodyCell, { width: 100, className: '' },
                        React.createElement("div", { className: 'row' },
                            React.createElement("div", { className: 'row w-100 col-24' },
                                React.createElement("div", { className: 'col-8' },
                                    React.createElement("p", { className: '' }, "Mennyis\u00E9g")),
                                React.createElement("div", { className: 'col-8' },
                                    React.createElement("p", { className: '' }, "Rendel\u00E9s azonos\u00EDt\u00F3")),
                                React.createElement("div", { className: 'col-8' },
                                    React.createElement("p", { className: '' }, "Rendel\u0151 neve"))),
                            React.createElement("div", { className: 'row w-100 col-24' },
                                React.createElement("div", { className: 'col-8' },
                                    React.createElement("p", { className: 'fw-700' },
                                        stock.amount,
                                        " db")),
                                React.createElement("div", { className: 'col-8' },
                                    React.createElement("p", { className: 'fw-700' }, identifier)),
                                React.createElement("div", { className: 'col-8' },
                                    React.createElement("p", { className: 'fw-700' }, name)))))));
        });
    };
    StockItem.prototype.fetchProcurementPrice = function () {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var id, price, e_1;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 2, , 3]);
                        id = (_b = (_a = this.props.item) === null || _a === void 0 ? void 0 : _a.product) === null || _b === void 0 ? void 0 : _b.productId;
                        if (!id) {
                            return [2];
                        }
                        return [4, this.repository.get("/b2b/supplier-pricing/price-for-product/".concat(id))];
                    case 1:
                        price = _c.sent();
                        this.setState({ price: price });
                        return [3, 3];
                    case 2:
                        e_1 = _c.sent();
                        console.log(e_1);
                        return [3, 3];
                    case 3: return [2];
                }
            });
        });
    };
    return StockItem;
}(abstract_component_1.AbstractComponent));
exports.StockItem = StockItem;
