"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
module.exports = {
    'stock-transaction.list.main.title': 'Belső mozgatás',
    'stock-transaction.list.create.button': 'Új mozgatás',
    'stock-transaction.add-product.modal.title': 'Termék választása',
    'stock-transaction.create.title': 'Belső mozgatás',
    'stock-transaction.create.form.from.label': 'Honnan',
    'stock-transaction.create.form.to.label': 'Hova',
    'stock-transaction.create.form.comment.label': 'Megjegyzés',
    'stock-transaction.create.product-list.title': 'Termékek',
    'stock-transaction.create.product-list.add.button': 'Új termék hozzáadása',
    'stock-transaction.create.table.header.product': 'Termék',
    'stock-transaction.create.table.header.fromStorage': 'Honnan',
    'stock-transaction.create.table.header.toStorage': 'Hova',
    'stock-transaction.create.table.header.delete': React.createElement("i", { className: 'fas fa-trash-can' }),
    'b2b-stock-transaction.table.header.identifier': 'azonosító',
    'b2b-stock-transaction.table.header.amount': 'mennyiség',
    'b2b-stock-transaction.table.header.creator': 'létrehozó',
    'b2b-stock-transaction.table.header.createdAt': 'létrehozva',
    'stock-transaction.view.data.comment': 'Megjegyzés',
    'stock-transaction.view.data.products': 'Termékek',
    'stock-transaction.view.control.status.title': 'STÁTUSZ',
    'stock-transaction.status.badge.title.done': 'MOZGATVA',
    'stock-transaction.view.table.header.product': 'termék',
    'stock-transaction.view.table.header.lot': 'lot/nébih',
    'stock-transaction.view.table.header.fromStorage': 'honnan',
    'stock-transaction.view.table.header.toStorage': 'hova',
    'stock-transaction.view.table.header.amount': 'mennyiség',
    'stock-transaction.form.missing.plan': 'Hiányzó mennyiség(ek)',
    'stock-transaction.form.missing.required': 'Hiányzó kötelező adatok',
    'stock-transaction.form.missing.products': 'Hiányzó termék(ek)',
    'stock-transaction.form.NoStockAvailable': 'nincs elérhető készlet termékből',
    'stock-transaction.form.ForbiddenSameStorageOnPlanItem': 'Mozgatás csak eltérő tárhelyre lehetséges',
    'stock-transaction.form.ForbiddenSameStorage': 'Mozgatás csak eltérő tárhelyre lehetséges',
};
