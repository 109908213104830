"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustomerPartnerListScreen = void 0;
var random_id_1 = require("@codebuild/glaze/libs/libs/random-id");
var repository_1 = require("@codebuild/glaze/libs/repository/repository");
var router_provider_1 = require("@codebuild/glaze/libs/router/router.provider");
var field_1 = require("@codebuild/sprinkles/libs/form/field");
var form_builder_1 = require("@codebuild/sprinkles/libs/form/form-builder");
var form_control_1 = require("@codebuild/sprinkles/libs/form/form-control/form-control");
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var trans_1 = require("@codebuild/sprinkles/libs/translation/trans");
var button_1 = require("@codebuild/sprinkles/modules/codebuild/button/react/button");
var select_input_form_control_type_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/form-control-types/select-input.form-control-type");
var select_input_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/select-input");
var table_1 = require("@codebuild/sprinkles/modules/codebuild/table/table");
var table_body_cell_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-body-cell");
var table_header_cell_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-header-cell");
var table_header_row_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-header-row");
var moment = require("moment");
var React = require("react");
var list_search_component_1 = require("../../common/components/list-search/list-search.component");
var pagination_list_control_1 = require("../../common/components/repository-list/controls/pagination-list-control");
var pagination_control_1 = require("../../common/components/repository-list/controls/pagination.control");
var list_empty_1 = require("../../common/components/repository-list/empty/list-empty");
var list_loader_1 = require("../../common/components/repository-list/loader/list-loader");
var repository_list_1 = require("../../common/components/repository-list/repository-list");
var year_selector_1 = require("../../common/components/year-selector");
var currency_parser_1 = require("../../common/libs/currency-parser");
var permissions_1 = require("../../common/libs/permissions/permissions");
var clickable_row_1 = require("../../components/clickable-row");
var form_1 = require("../../components/form/form");
var badge_1 = require("../../components/libs/badge/badge");
var permission_wrapper_1 = require("../../components/permission-wrapper");
var CustomerPartnerListScreen = (function (_super) {
    __extends(CustomerPartnerListScreen, _super);
    function CustomerPartnerListScreen() {
        var _this = this;
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
        _this = _super.apply(this, __spreadArray([], __read(arguments), false)) || this;
        _this.repository = repository_1.Repository.use('default');
        _this.control = new pagination_list_control_1.PaginationListControl({
            defaultData: {
                limit: 20,
                page: (_c = (_b = (_a = _this.props.route) === null || _a === void 0 ? void 0 : _a.query) === null || _b === void 0 ? void 0 : _b._page) !== null && _c !== void 0 ? _c : 0,
                query: (_f = (_e = (_d = _this.props.route) === null || _d === void 0 ? void 0 : _d.query) === null || _e === void 0 ? void 0 : _e.query) !== null && _f !== void 0 ? _f : '',
                sort: (_h = (_g = _this.props.route) === null || _g === void 0 ? void 0 : _g.sort) !== null && _h !== void 0 ? _h : { createdAt: -1 }
            },
        });
        _this.filterForm = new form_builder_1.FormBuilder({
            fields: {
                marketingManager: new field_1.Field({
                    placeholder: '',
                    value: ((_k = (_j = _this.control) === null || _j === void 0 ? void 0 : _j.getQuery()) === null || _k === void 0 ? void 0 : _k.marketingManager) || '',
                    name: 'marketingManager',
                    label: (0, trans_1.trans)('b2b-supplier-pricing.filter.form.label.marketing.manager'),
                    optionsEndpoint: '/b2b/customer-partner/marketing-manager-options',
                    loadOptionsAfterMount: true,
                    validators: []
                }),
            }
        });
        _this.state = {
            total: 0
        };
        return _this;
    }
    CustomerPartnerListScreen.prototype.componentDidMount = function () {
        var _this = this;
        this.$subscriptions.push(this.filterForm.fields.marketingManager.$value.subscribe(function (v) { return _this.handleChange('marketingManager', v); }));
    };
    CustomerPartnerListScreen.prototype.handleChange = function (property, value) {
        this.control.set(property, value);
        this.control.page(0);
    };
    CustomerPartnerListScreen.prototype.render = function () {
        var _this = this;
        return React.createElement("div", null,
            React.createElement("div", { className: "row" },
                React.createElement("div", { className: "col-15" },
                    React.createElement("div", { className: 'display-flex align-items-center mb-8' },
                        React.createElement("h2", { className: 'mr-4' }, (0, trans_1.trans)('customer-partner.list.title')),
                        React.createElement("span", null,
                            this.state.total,
                            " db"),
                        React.createElement("div", { className: 'ml-6' },
                            React.createElement(permission_wrapper_1.PermissionWrapper, { permissions: permissions_1.PERMISSIONS.CRM.customerPartners.create },
                                React.createElement(button_1.Button, { onClick: function () { return router_provider_1.RouterProvider.goTo('/customer-partner/create'); }, title: (0, trans_1.trans)('customer-partner.list.button-create.title'), iconLeft: 'fal fa-plus', className: 'button-primary-link button-size-normal' }))))),
                React.createElement("div", { className: "col-9" },
                    React.createElement(list_search_component_1.ListSearchComponent, { "control$": this.control }))),
            React.createElement("div", { className: 'row mt-2' },
                React.createElement("div", { className: 'col' },
                    React.createElement("div", { className: 'filter-wrapper' },
                        React.createElement(form_1.Form, null,
                            React.createElement("div", { className: 'row mx-0' },
                                React.createElement(form_control_1.FormControl, { field: this.filterForm.fields.marketingManager, type: select_input_form_control_type_1.SelectInputFormControlType },
                                    React.createElement(select_input_1.SelectInput, { searchable: false, unclearable: false, tabIndex: 3, className: 'select-input-basic col-24 col-lg-8 pl-0' })),
                                React.createElement(year_selector_1.YearSelector, { className: 'col-24 col-lg-8 pl-0', onChange: function (value) { return _this.handleChange('year', value); } })))))),
            React.createElement(repository_list_1.RepositoryList, { control: this.control, hideItemsWhileLoading: true, showLoading: function () { return true; }, showError: function () { return true; }, showItem: function () { return true; }, defaultSort: { createdAt: 1 }, onChange: function (ctx) { return _this.handleListChange(ctx); }, repository: repository_1.Repository.use('default'), endpoint: '/b2b/customer-partner', renderItemsWrapper: function (ctx) { return _this.renderTable(ctx); }, renderItem: function (ctx, item, index) { return _this.renderItem(ctx, item, index); }, renderEmpty: function () { return (React.createElement(list_empty_1.ListEmpty, null)); }, renderLoading: function (ctx) { return (React.createElement(list_loader_1.ListLoader, null)); } }),
            React.createElement(pagination_control_1.PaginationControl, { "control$": this.control, limitOptions: [
                    { name: 10, value: 10 },
                    { name: 20, value: 20 },
                    { name: 50, value: 50 },
                    { name: 100, value: 100 },
                ] }));
    };
    CustomerPartnerListScreen.prototype.renderTable = function (ctx) {
        var _this = this;
        return React.createElement(table_1.Table, { className: 'table-basic', renderHeader: function () { return _this.renderTableHeader(); }, renderBody: function () { return ctx.renderItems(ctx); } });
    };
    CustomerPartnerListScreen.prototype.renderTableHeader = function () {
        return React.createElement(table_header_row_1.TableHeaderRow, { sticky: false },
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 30 }, this.setHeaderCell('name'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 30 }, this.setHeaderCell('lastOrder'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 30 }, this.setHeaderCell('orderSummary'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 30 }, this.setHeaderCell('commitment'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 30 }, this.setHeaderCell('status'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 20 }, this.setHeaderCell('createdAt'))));
    };
    CustomerPartnerListScreen.prototype.setHeaderCell = function (p) {
        var _this = this;
        return {
            sortValue: this.getSortValue(p),
            onSort: function (property, value) { return _this.handleSort(property, value); },
            enableSort: true,
            title: (0, trans_1.trans)("customer-partner.table.header.".concat(p)),
            property: p
        };
    };
    CustomerPartnerListScreen.prototype.handleSort = function (property, value) {
        this.control.sort(property, value);
    };
    CustomerPartnerListScreen.prototype.getSortValue = function (property) {
        var _a;
        return ((_a = this.control.data.sort) === null || _a === void 0 ? void 0 : _a[property]) || 0;
    };
    CustomerPartnerListScreen.prototype.renderItem = function (ctx, item, index) {
        var _a, _b, _c, _d;
        return React.createElement(clickable_row_1.ClickableRow, { key: (0, random_id_1.randomId)(), href: "/customer-partner/update/".concat(item === null || item === void 0 ? void 0 : item._id) },
            React.createElement(table_body_cell_1.TableBodyCell, { width: 30, className: 'cursor-pointer', onClick: function () { return router_provider_1.RouterProvider.goTo("/customer-partner/update/".concat(item === null || item === void 0 ? void 0 : item._id)); } },
                React.createElement("span", null, item === null || item === void 0 ? void 0 : item.name)),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 30 },
                React.createElement("span", null, (0, currency_parser_1.moneyFormat)((_b = (_a = item.lastOrderSummary) === null || _a === void 0 ? void 0 : _a.currentTotalValue) !== null && _b !== void 0 ? _b : 0))),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 30 },
                React.createElement("span", null, (0, currency_parser_1.moneyFormat)((_d = (_c = item.yearlyOrderSummary) === null || _c === void 0 ? void 0 : _c.amount) !== null && _d !== void 0 ? _d : 0))),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 30 },
                React.createElement("span", null, (0, currency_parser_1.hufFormat)(item === null || item === void 0 ? void 0 : item.commitment))),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 30 },
                React.createElement(badge_1.Badge, { title: (item === null || item === void 0 ? void 0 : item.isActive) ? (0, trans_1.trans)('customer-partner.status.active') : (0, trans_1.trans)('customer-partner.status.inactive'), type: (item === null || item === void 0 ? void 0 : item.isActive) ? 'success' : 'error' })),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 20 },
                React.createElement("span", null, moment(item === null || item === void 0 ? void 0 : item.createdAt).format('YYYY.MM.DD.'))));
    };
    CustomerPartnerListScreen.prototype.handleListChange = function (ctx) {
        var _a, _b;
        var total = (_a = ctx.state) === null || _a === void 0 ? void 0 : _a.total;
        this.setState({ total: (_b = ctx.state) === null || _b === void 0 ? void 0 : _b.total });
        if (this.props.totalChange) {
            this.props.totalChange(total);
        }
    };
    return CustomerPartnerListScreen;
}(abstract_component_1.AbstractComponent));
exports.CustomerPartnerListScreen = CustomerPartnerListScreen;
