module.exports = {
    'dashboard.charts.time-selector-label': 'Intervallum',
    'dashboard.charts.no-data': 'Nincsenek megjeleníthető adatok',
    'dashboard.charts.by-category.amount.title': 'Darab eladás kategóriánként',
    'dashboard.charts.by-category.price.title': 'Eladási érték kategóriánként',
    'dashboard.charts.bar-chart.label.amount': 'Eladott darab',
    'dashboard.charts.bar-chart.label.price': 'Eladott érték',
    'dashboard.charts.top-seller.title': 'Top 5 eladott termék',
    'dashboard.charts.top-seller.header-cell-name.product.title.hu': 'Termék',
    'dashboard.charts.top-seller.header-cell-name.soldCount': 'Darab',
    'dashboard.charts.top-seller.header-cell-name.sumPriceHUF': 'Bruttó HUF értek',
    'dashboard.charts.top-seller.header-cell-name.sumPriceEUR': 'Bruttó EUR érték',
    'dashboard.charts.top-city.title': 'Eladások városonként',
    'dashboard.charts.top-city.header-cell-name.city': 'Város',
    'dashboard.charts.top-city.header-cell-name.orderCount': 'Rendelések száma',
    'dashboard.charts.top-city.header-cell-name.priceTotalHUF': 'Bruttó HUF értek',
    'dashboard.charts.top-city.header-cell-name.priceTotalEUR': 'Bruttó EUR érték',
    'dashboard.charts.total-sold.title': 'Összeladás',
    'sales.dashboard.periodRange.label': 'Periódus választó',
    'sales.dashboard.commitment.label': 'Forgalmi vállalás',
    'sales.dashboard.sales.users': 'Értékesítő választó',
    'sales.dashboard.fact.label': 'Tény forgalom',
    'sales.dashboard.title': 'Értékesítő dashboard',
    'sales.dashboard.total.procurement': 'ÖSSZES BESZERZÉS',
    'sales.dashboard.total.profit': 'ÖSSZES NYERESÉG',
    'sales.dashboard.total.orders': 'ÖSSZES RENDELÉS',
    'sales.dashboard.monthly.plan': 'Havi tervszám',
    'sales.dashboard.base.target.value': 'Bázis tervszám',
    'sales.dashboard.base.target': 'Bázisérték',
    'sales.dashboard.monthly.actual': 'Havi tényszám',
    'sales.dashboard.this.period.customer.orders': 'Ebben az időszakban ennyi vevőm rendelt',
    'sales.dashboard.this.period.customer.have.not.ordered': 'Ennyi partnerem nem rendelt ebben az időszakban',
    'sales.dashboard.period.orders': 'Ebben az időszakban ennyi rendelésem van',
    'sales.dashboard.period.new.partners': 'Ennyi új partnerem lett ebben az időszakban',
    'sales.dashboard.ordered.a.while.back': 'A legrégebben rendelő 20 vevő',
    'sales.dashboard.ordered.new.partners': 'Ennyi új partnerem lett ebben az időszakban',
    'sales.dashboard.expired.credits': 'járt tartozások',
    'sales.dashboard.yearly.plan.and.data': 'Éves terv és tény adataim',
};
