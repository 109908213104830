module.exports = {
    'login.screen.title': 'Bejelentkezés',
    'login.button.title': 'Bejelentkezés',
    'login.field.password': 'Jelszó',
    'login.field.email': 'Email',
    'login.forgot-password.button.title': 'Elfelejtettem a jelszavam',
    'login.back-to-login.button.title': 'Vissza a bejelentkezésre',
    'recover.password': 'Új jelszó',
    'recover.password.again': 'Új jelszó megerősítése',
    'recover.password.again.error': 'The passwords do not match',
};
