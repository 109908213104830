"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwitchableDateRangeFilter = void 0;
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var React = require("react");
var custom_prop_list_date_range_filter_component_1 = require("../custom-prop-list-date-range-filter.component");
require("./switchable-date-range-filter.scss");
var SwitchableDateRangeFilter = (function (_super) {
    __extends(SwitchableDateRangeFilter, _super);
    function SwitchableDateRangeFilter() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            options: [],
            active: null,
        };
        return _this;
    }
    SwitchableDateRangeFilter.prototype.componentDidMount = function () {
        var _this = this;
        var options = this.props.options.map(function (option) {
            return {
                title: option.title,
                prefix: option.propertyPrefix,
                element: React.createElement(custom_prop_list_date_range_filter_component_1.CustomPropListDateRangeFilterComponent, { key: option.title, from: "".concat(option.propertyPrefix, "from"), to: "".concat(option.propertyPrefix, "to"), startDate: option.startDate, "control$": _this.props.control })
            };
        });
        var firstOption = options[0];
        this.setState({ options: options, active: firstOption });
    };
    SwitchableDateRangeFilter.prototype.render = function () {
        var _this = this;
        if (!this.state.active) {
            return React.createElement("i", { className: 'far fa-spinner-third fa-spin' });
        }
        return React.createElement(React.Fragment, null,
            React.createElement("div", { className: 'switch-controller pb-2' }, this.state.options.map(function (option) {
                var isActiveClass = option.title === _this.state.active.title ? 'switch-item-active' : '';
                return React.createElement("div", { className: "switch-item hover-opacity ".concat(isActiveClass), key: option.title, onClick: function () { return _this.select(option); } },
                    React.createElement("span", null, option.title));
            })),
            this.renderActiveFilter());
    };
    SwitchableDateRangeFilter.prototype.select = function (nextOption) {
        this.resetQuery();
        this.setState({ active: nextOption });
    };
    SwitchableDateRangeFilter.prototype.resetQuery = function () {
        var e_1, _a;
        try {
            for (var _b = __values(this.props.options), _c = _b.next(); !_c.done; _c = _b.next()) {
                var option = _c.value;
                var from = "".concat(option.propertyPrefix, "from");
                var to = "".concat(option.propertyPrefix, "to");
                this.props.control.deleteKeys([from, to]);
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
    };
    SwitchableDateRangeFilter.prototype.renderActiveFilter = function () {
        var active = this.state.active;
        if (!active) {
            return null;
        }
        return active.element;
    };
    return SwitchableDateRangeFilter;
}(abstract_component_1.AbstractComponent));
exports.SwitchableDateRangeFilter = SwitchableDateRangeFilter;
