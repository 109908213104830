"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.SalesCommitmentCharts = void 0;
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var trans_1 = require("@codebuild/sprinkles/libs/translation/trans");
var moment = require("moment/moment");
var React = require("react");
var react_circular_progressbar_1 = require("react-circular-progressbar");
var currency_parser_1 = require("../../../../common/libs/currency-parser");
var commitment_chart_1 = require("../../../../components/charts/commitment-chart");
var SalesCommitmentCharts = (function (_super) {
    __extends(SalesCommitmentCharts, _super);
    function SalesCommitmentCharts() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    SalesCommitmentCharts.prototype.render = function () {
        return React.createElement("div", { className: 'border-2 p-4' },
            React.createElement("div", { className: 'row' },
                React.createElement("div", { className: "col-12" },
                    React.createElement("h5", null,
                        "Forgalmi v\u00E1llal\u00E1s ",
                        this.props.year || moment().year())),
                React.createElement("div", { className: 'col-12' },
                    React.createElement("p", null,
                        (0, trans_1.trans)('sales.dashboard.commitment.label'),
                        ": ",
                        React.createElement("span", { className: 'fw-700' }, (0, currency_parser_1.hufFormat)(this.props.monthlyCommitment))),
                    React.createElement("p", null,
                        (0, trans_1.trans)('sales.dashboard.fact.label'),
                        ": ",
                        React.createElement("span", { className: 'fw-700' }, (0, currency_parser_1.hufFormat)(this.props.total))))),
            React.createElement("div", { className: 'row' },
                React.createElement("div", { className: 'col-19' },
                    React.createElement(commitment_chart_1.CommitmentChart, { data: this.props.data, commitment: this.props.monthlyCommitment })),
                React.createElement("div", { className: 'col-5' },
                    React.createElement("div", { className: 'circular-progress-bar-wrapper' },
                        React.createElement(react_circular_progressbar_1.CircularProgressbar, { value: this.props.percentage, text: "".concat(this.props.percentage, "%"), strokeWidth: 12, styles: {
                                path: { stroke: '#D6493D' },
                                trail: { stroke: '#F0F0F0' },
                                text: { fill: '#121212' }
                            } })))));
    };
    return SalesCommitmentCharts;
}(abstract_component_1.AbstractComponent));
exports.SalesCommitmentCharts = SalesCommitmentCharts;
