"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PlanData = exports.periodRange = void 0;
var field_1 = require("@codebuild/glaze/libs/form/field");
var form_builder_1 = require("@codebuild/glaze/libs/form/form-builder");
var form_control_1 = require("@codebuild/glaze/libs/form/form-control/form-control");
var repository_1 = require("@codebuild/glaze/libs/repository/repository");
var trans_1 = require("@codebuild/glaze/libs/translation/trans");
var select_input_1 = require("@codebuild/glaze/modules/codebuild/input-fields/react/select-input");
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var React = require("react");
var currency_parser_1 = require("../../../common/libs/currency-parser");
var form_1 = require("../../../components/form/form");
var box_loader_1 = require("../../../common/components/repository-list/loader/box-loader");
var round_1 = require("../../../libs/round");
var select_input_form_control_type_1 = require("@codebuild/glaze/modules/codebuild/input-fields/form-control-types/select-input.form-control-type");
var get_date_range_by_year_1 = require("./libs/get-date-range-by-year");
var Months;
(function (Months) {
    Months["January"] = "January";
    Months["February"] = "February";
    Months["March"] = "March";
    Months["April"] = "April";
    Months["May"] = "May";
    Months["June"] = "June";
    Months["July"] = "July";
    Months["August"] = "August";
    Months["September"] = "September";
    Months["October"] = "October";
    Months["November"] = "November";
    Months["December"] = "December";
})(Months || (Months = {}));
exports.periodRange = [
    { value: Months.January, name: 'Január', key: Months.January },
    { value: Months.February, name: 'Február', key: Months.February },
    { value: Months.March, name: 'Március', key: Months.March },
    { value: Months.April, name: 'Április', key: Months.April },
    { value: Months.May, name: 'Május', key: Months.May },
    { value: Months.June, name: 'Június', key: Months.June },
    { value: Months.July, name: 'Július', key: Months.July },
    { value: Months.August, name: 'Augusztus', key: Months.August },
    { value: Months.September, name: 'Szeptember', key: Months.September },
    { value: Months.October, name: 'Október', key: Months.October },
    { value: Months.November, name: 'November', key: Months.November },
    { value: Months.December, name: 'December', key: Months.December },
];
var PlanData = (function (_super) {
    __extends(PlanData, _super);
    function PlanData() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.repository = repository_1.Repository.use('default');
        _this.baseTransText = 'sales.dashboard';
        _this.form = new form_builder_1.FormBuilder({
            fields: {
                period: new field_1.Field({
                    placeholder: '',
                    value: exports.periodRange[0].value,
                    name: 'periodRange',
                    options: exports.periodRange,
                    label: (0, trans_1.trans)("".concat(_this.baseTransText, ".periodRange.label")),
                    validators: []
                }),
            }
        });
        _this.state = {
            loading: false,
            monthlyPlan: null,
            monthlyActual: null,
            numOfPartnersThatOrdered: null,
            numOfPartnersHaveNotOrdered: 0,
            totalOrdersInThisPeriod: null,
            newPartnersInThisPeriod: null,
        };
        return _this;
    }
    PlanData.prototype.componentDidMount = function () {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var year;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!((_a = this.props.user) === null || _a === void 0 ? void 0 : _a._id)) return [3, 2];
                        year = this.props.year || new Date().getFullYear();
                        return [4, this.fetch(this.form.fields.period.getValue(), year)];
                    case 1:
                        _b.sent();
                        _b.label = 2;
                    case 2:
                        this.$subscriptions.push(this.form.fields.period.$value.subscribe(function (period) { return _this.fetch(period, _this.props.year); }));
                        return [2];
                }
            });
        });
    };
    PlanData.prototype.componentDidUpdate = function (prevProps, prevState, snapshot) {
        return __awaiter(this, void 0, void 0, function () {
            var year;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(prevProps.user !== this.props.user)) return [3, 2];
                        year = this.props.year || new Date().getFullYear();
                        return [4, this.fetch(this.form.fields.period.getValue(), year)];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2:
                        if (!(prevProps.year !== this.props.year)) return [3, 4];
                        return [4, this.fetch(this.form.fields.period.getValue(), this.props.year)];
                    case 3:
                        _a.sent();
                        _a.label = 4;
                    case 4: return [2];
                }
            });
        });
    };
    PlanData.prototype.render = function () {
        var _this = this;
        if (this.state.loading) {
            return React.createElement("div", { style: { minHeight: '350px' }, className: 'row py-9 ' },
                React.createElement(box_loader_1.BoxLoader, null));
        }
        var renderBoxContent = function (content, renderCallback) {
            if (!content) {
                return null;
            }
            try {
                return renderCallback(content);
            }
            catch (e) {
                console.log({ renderBoxContent: e });
                return null;
            }
        };
        var _a = this.state, monthlyPlan = _a.monthlyPlan, monthlyActual = _a.monthlyActual, newPartnersInThisPeriod = _a.newPartnersInThisPeriod, totalOrdersInThisPeriod = _a.totalOrdersInThisPeriod, numOfPartnersThatOrdered = _a.numOfPartnersThatOrdered, numOfPartnersHaveNotOrdered = _a.numOfPartnersHaveNotOrdered;
        return React.createElement("div", { style: { minHeight: '340px' }, className: "justify-content-between row pt-5 display-flex align-items-start w-100" },
            React.createElement("div", { className: 'col-24 display-flex align-items-start w-100' }, this.renderRangeFilter()),
            React.createElement("div", { className: "col-lg-8 col-md-12 col-24 mb-3 pr-4" }, renderBoxContent({ plan: monthlyPlan, actual: monthlyActual }, function (content) { return _this.renderMonthly(content); })),
            React.createElement("div", { className: "col-lg-8 col-md-12 col-24 mb-3 px-2 row" },
                React.createElement("div", { className: 'col-24 mb-3' }, renderBoxContent(numOfPartnersThatOrdered, function (content) { return _this.renderPartnersWhoOrdered(content); })),
                React.createElement("div", { className: 'col-24' }, renderBoxContent(numOfPartnersHaveNotOrdered, function (content) { return _this.renderPartnersWhoHavNotOrdered(content); }))),
            React.createElement("div", { className: "col-lg-8 col-md-12 col-24 mb-3 pl-4" },
                React.createElement("div", { className: 'col-24 mb-3' }, renderBoxContent(totalOrdersInThisPeriod, function (content) { return _this.renderTotalOrders(content); })),
                React.createElement("div", { className: 'col-24' }, renderBoxContent(newPartnersInThisPeriod, function (content) { return _this.renderNewPartners(content); }))));
    };
    PlanData.prototype.renderMonthly = function (_a) {
        var plan = _a.plan, actual = _a.actual;
        var actualCurrentPeriod = (actual === null || actual === void 0 ? void 0 : actual.currentPeriod) || 0;
        var planCurrentPeriod = (plan === null || plan === void 0 ? void 0 : plan.currentPeriod) || 0;
        var actualPreviousPeriod = (actual === null || actual === void 0 ? void 0 : actual.previousPeriod) || 0;
        var planPreviousPeriod = (plan === null || plan === void 0 ? void 0 : plan.previousPeriod) || 0;
        var actualPercentage = (0, round_1.round)((actualCurrentPeriod / planCurrentPeriod) * 100);
        var previousPercentage = planPreviousPeriod ? (0, round_1.round)((actualPreviousPeriod / planPreviousPeriod) * 100) : 0;
        return React.createElement("div", { className: 'sales-dashboard-box w-100' },
            React.createElement("div", { className: 'row mb-1' },
                React.createElement("div", { className: 'col-10 display-flex justify-content-start' },
                    React.createElement("span", { className: 'fs-14' },
                        (0, trans_1.trans)("".concat(this.baseTransText, ".monthly.plan")),
                        ":")),
                React.createElement("div", { className: 'col-10 display-flex justify-content-start' },
                    React.createElement("span", { className: 'fs-14' }, (0, currency_parser_1.hufFormat)(planCurrentPeriod))),
                React.createElement("div", { className: 'col-4' })),
            React.createElement("div", { className: 'row mb-1' },
                React.createElement("div", { className: 'col-10 display-flex justify-content-start' },
                    React.createElement("span", { className: 'fs-14 fw-700' },
                        (0, trans_1.trans)("".concat(this.baseTransText, ".monthly.actual")),
                        ":")),
                React.createElement("div", { className: 'col-10 display-flex justify-content-start' },
                    React.createElement("span", { className: 'fs-14 fw-700' }, (0, currency_parser_1.hufFormat)(actualCurrentPeriod))),
                React.createElement("div", { className: 'col-4 display-flex justify-content-start' },
                    React.createElement("span", { className: 'fs-14 fw-700' },
                        actualPercentage === Infinity ? 0 : actualPercentage,
                        "%"))),
            React.createElement("div", { className: 'row mb-1' },
                React.createElement("div", { className: 'col-10 display-flex justify-content-start' },
                    React.createElement("span", { className: 'fs-14' },
                        (0, trans_1.trans)("".concat(this.baseTransText, ".base.target.value")),
                        ":")),
                React.createElement("div", { className: 'col-10 display-flex justify-content-start' },
                    React.createElement("span", { className: 'fs-14' }, (0, currency_parser_1.hufFormat)(planPreviousPeriod))),
                React.createElement("div", { className: 'col-4' })),
            React.createElement("div", { className: 'row mb-1' },
                React.createElement("div", { className: 'col-10 display-flex justify-content-start' },
                    React.createElement("span", { className: 'fs-14' },
                        (0, trans_1.trans)("".concat(this.baseTransText, ".base.target")),
                        ":")),
                React.createElement("div", { className: 'col-10 display-flex justify-content-start' },
                    React.createElement("span", { className: 'fs-14' }, (0, currency_parser_1.hufFormat)(actualPreviousPeriod))),
                React.createElement("div", { className: 'col-4 display-flex justify-content-start' },
                    React.createElement("span", { className: 'fs-14' },
                        previousPercentage,
                        "%"))));
    };
    PlanData.prototype.renderNewPartners = function (data) {
        return React.createElement("div", { className: 'sales-dashboard-box w-100' },
            React.createElement("div", { className: 'row mb-1' },
                React.createElement("div", { className: 'col-16 display-flex justify-content-start' },
                    React.createElement("span", { className: 'fs-14' },
                        (0, trans_1.trans)("".concat(this.baseTransText, ".period.new.partners")),
                        ":")),
                React.createElement("div", { className: 'col-8 display-flex justify-content-start' },
                    React.createElement("span", { className: 'fs-14 fw-700' },
                        data.numberOfNewPartners,
                        " (",
                        (0, currency_parser_1.hufFormat)(data.theirOrdersTotal),
                        ")"))));
    };
    PlanData.prototype.renderTotalOrders = function (data) {
        return React.createElement("div", { className: 'sales-dashboard-box w-100' },
            React.createElement("div", { className: 'row mb-1' },
                React.createElement("div", { className: 'col-16 display-flex justify-content-start' },
                    React.createElement("span", { className: 'fs-14' },
                        (0, trans_1.trans)("".concat(this.baseTransText, ".period.orders")),
                        ":")),
                React.createElement("div", { className: 'col-8 display-flex justify-content-start' },
                    React.createElement("span", { className: 'fs-14 fw-700' }, data.currentPeriod))),
            React.createElement("div", { className: 'row mb-1' },
                React.createElement("div", { className: 'col-16 display-flex justify-content-start' },
                    React.createElement("span", { className: 'fs-14' },
                        (0, trans_1.trans)("".concat(this.baseTransText, ".base.target")),
                        ":")),
                React.createElement("div", { className: 'col-8 display-flex justify-content-start' },
                    React.createElement("span", { className: 'fs-14' }, data.previousPeriod))));
    };
    PlanData.prototype.renderPartnersWhoOrdered = function (data) {
        return React.createElement("div", { className: 'sales-dashboard-box w-100' },
            React.createElement("div", { className: 'row mb-1' },
                React.createElement("div", { className: 'col-16 display-flex justify-content-start' },
                    React.createElement("span", { className: 'fs-14' },
                        (0, trans_1.trans)("".concat(this.baseTransText, ".this.period.customer.orders")),
                        ":")),
                React.createElement("div", { className: 'col-8 display-flex justify-content-start' },
                    React.createElement("span", { className: 'fs-14 fw-700' },
                        data.currentPeriod.actual,
                        " / ",
                        data.currentPeriod.all))),
            React.createElement("div", { className: 'row mb-1' },
                React.createElement("div", { className: 'col-16 display-flex justify-content-start' },
                    React.createElement("span", { className: 'fs-14' },
                        (0, trans_1.trans)("".concat(this.baseTransText, ".base.target")),
                        ":")),
                React.createElement("div", { className: 'col-8 display-flex justify-content-start' },
                    React.createElement("span", { className: 'fs-14' },
                        data.previousPeriod.actual,
                        " / ",
                        data.previousPeriod.all))));
    };
    PlanData.prototype.renderPartnersWhoHavNotOrdered = function (data) {
        return React.createElement("div", { className: 'sales-dashboard-box w-100' },
            React.createElement("div", { className: 'row mb-1' },
                React.createElement("div", { className: 'col-20 display-flex justify-content-start' },
                    React.createElement("span", { className: 'fs-14' },
                        (0, trans_1.trans)("".concat(this.baseTransText, ".this.period.customer.have.not.ordered")),
                        ":")),
                React.createElement("div", { className: 'col-4 display-flex justify-content-start' },
                    React.createElement("span", { className: 'fs-14 fw-700' }, data))));
    };
    PlanData.prototype.renderRangeFilter = function () {
        return React.createElement(form_1.Form, { className: 'w-100 row' },
            React.createElement("div", { className: "col-lg-8 col-md-12 col-24 w-100" },
                React.createElement(form_control_1.FormControl, { field: this.form.fields.period, type: select_input_form_control_type_1.SelectInputFormControlType },
                    React.createElement(select_input_1.SelectInput, { unclearable: true, className: 'select-input-basic' }))));
    };
    PlanData.prototype.fetch = function (period, year) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, from, to, response, e_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, 3, 4]);
                        this.setState({ loading: true });
                        _a = (0, get_date_range_by_year_1.getDateRangeByYear)(year), from = _a.from, to = _a.to;
                        return [4, this.repository.get("/b2b/sales-dashboard/comparison-analytics/".concat(this.props.user._id, "?period=").concat(period, "&from=").concat(from, "&to=").concat(to))];
                    case 1:
                        response = _b.sent();
                        this.setState({
                            monthlyPlan: response.monthlyPlan,
                            monthlyActual: response.monthlyActual,
                            numOfPartnersThatOrdered: response.numOfPartnersThatOrdered,
                            numOfPartnersHaveNotOrdered: response.numOfPartnersHaveNotOrdered,
                            totalOrdersInThisPeriod: response.totalOrdersInThisPeriod,
                            newPartnersInThisPeriod: response.newPartnersInThisPeriod,
                        });
                        return [3, 4];
                    case 2:
                        e_1 = _b.sent();
                        console.log({ PlanData: e_1 });
                        return [3, 4];
                    case 3:
                        this.setState({ loading: false });
                        return [7];
                    case 4: return [2];
                }
            });
        });
    };
    return PlanData;
}(abstract_component_1.AbstractComponent));
exports.PlanData = PlanData;
