"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TreeSelect = void 0;
var field_1 = require("@codebuild/sprinkles/libs/form/field");
var form_builder_1 = require("@codebuild/sprinkles/libs/form/form-builder");
var form_control_1 = require("@codebuild/sprinkles/libs/form/form-control/form-control");
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var random_id_1 = require("@codebuild/sprinkles/libs/libs/random-id");
var repository_1 = require("@codebuild/sprinkles/libs/repository/repository");
var button_1 = require("@codebuild/sprinkles/modules/codebuild/button/react/button");
var text_input_form_control_type_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/form-control-types/text-input.form-control-type");
var text_input_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/text-input");
var jquery = require("jquery");
var lodash_1 = require("lodash");
var React = require("react");
var operators_1 = require("rxjs/operators");
var modal_1 = require("../../../components/modal/modal");
require("./tree-select.form.scss");
var optional_component_1 = require("../../../components/optional-component");
var TreeSelect = (function (_super) {
    __extends(TreeSelect, _super);
    function TreeSelect() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.repository = repository_1.Repository.use('default');
        _this.inputId = (0, random_id_1.randomId)();
        _this.cancelToken1 = repository_1.Repository.createCancelToken();
        _this.state = {
            value: [],
            subItems: [],
            open: false,
            options: [],
            loading: _this.props.loading,
            searchable: _this.props.searchable,
        };
        _this.searchForm = new form_builder_1.FormBuilder({
            fields: {
                search: new field_1.Field({
                    placeholder: 'Kereses',
                    value: '',
                    name: 'search',
                    label: '',
                    validators: []
                })
            }
        });
        return _this;
    }
    TreeSelect.prototype.componentDidUpdate = function (prevProps, prevState, snapshot) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!!(0, lodash_1.isEqual)(this.props.value, prevProps.value)) return [3, 2];
                        return [4, this.loadValue()];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2:
                        if (!(this.props.loading !== prevProps.loading)) return [3, 4];
                        return [4, this.setState({ loading: this.props.loading })];
                    case 3:
                        _a.sent();
                        _a.label = 4;
                    case 4:
                        if (!(this.props.searchable !== prevProps.searchable)) return [3, 6];
                        return [4, this.setState({ searchable: this.props.searchable })];
                    case 5:
                        _a.sent();
                        _a.label = 6;
                    case 6: return [2];
                }
            });
        });
    };
    TreeSelect.prototype.componentDidMount = function () {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0: return [4, this.loadOptions(((_a = this.props.query) === null || _a === void 0 ? void 0 : _a.query) || '', this.props.products || [], ((_b = this.props.query) === null || _b === void 0 ? void 0 : _b.fromStorage) || '')];
                    case 1:
                        _c.sent();
                        this.$subscriptions.push(this.searchForm.fields.search.$value
                            .pipe((0, operators_1.debounceTime)(450))
                            .subscribe(function (value) { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
                            return [2, this.handleOnSearch(value)];
                        }); }); }));
                        return [4, this.loadValue()];
                    case 2:
                        _c.sent();
                        return [2];
                }
            });
        });
    };
    TreeSelect.prototype.loadValue = function () {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var _b, _c, v, value, e_1_1;
            var e_1, _d;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0: return [4, this.setState({ value: [] })];
                    case 1:
                        _e.sent();
                        _e.label = 2;
                    case 2:
                        _e.trys.push([2, 8, 9, 10]);
                        _b = __values(this.props.value), _c = _b.next();
                        _e.label = 3;
                    case 3:
                        if (!!_c.done) return [3, 7];
                        v = _c.value;
                        return [4, this.findValueById((_a = v === null || v === void 0 ? void 0 : v.document) === null || _a === void 0 ? void 0 : _a._id, this.state.options)];
                    case 4:
                        value = _e.sent();
                        if (!!!value) return [3, 6];
                        return [4, this.handleSelect(value, true)];
                    case 5:
                        _e.sent();
                        _e.label = 6;
                    case 6:
                        _c = _b.next();
                        return [3, 3];
                    case 7: return [3, 10];
                    case 8:
                        e_1_1 = _e.sent();
                        e_1 = { error: e_1_1 };
                        return [3, 10];
                    case 9:
                        try {
                            if (_c && !_c.done && (_d = _b.return)) _d.call(_b);
                        }
                        finally { if (e_1) throw e_1.error; }
                        return [7];
                    case 10: return [2];
                }
            });
        });
    };
    TreeSelect.prototype.loadOptions = function (query, products, fromStorage) {
        return __awaiter(this, void 0, void 0, function () {
            var response, e_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 4, , 6]);
                        if (this.cancelToken1) {
                            this.cancelToken1.cancel('CanceledByUser');
                        }
                        this.cancelToken1 = repository_1.Repository.createCancelToken();
                        return [4, this.setState({ loading: true })];
                    case 1:
                        _a.sent();
                        return [4, this.repository.get('/b2b/stock-miscellaneous/root-tree', {
                                params: { query: query, products: products, fromStorage: fromStorage, onlyAllowedStorages: true },
                                cancelToken: this.cancelToken1.token
                            })];
                    case 2:
                        response = _a.sent();
                        if (!!this.props.optionsNarrowing) {
                            response = this.narrowValue(response);
                        }
                        return [4, this.setState({ options: response, loading: false })];
                    case 3:
                        _a.sent();
                        return [3, 6];
                    case 4:
                        e_2 = _a.sent();
                        return [4, this.setState({ loading: false })];
                    case 5:
                        _a.sent();
                        return [3, 6];
                    case 6: return [2];
                }
            });
        });
    };
    TreeSelect.prototype.narrowValue = function (data) {
        return this.getNarrowValue(data);
    };
    TreeSelect.prototype.getNarrowValue = function (data) {
        var e_3, _a;
        var _b;
        try {
            for (var data_1 = __values(data), data_1_1 = data_1.next(); !data_1_1.done; data_1_1 = data_1.next()) {
                var item = data_1_1.value;
                if (((_b = item.document) === null || _b === void 0 ? void 0 : _b._id) === this.props.optionsNarrowing) {
                    return item.children;
                }
                if (!!item.children.length) {
                    return this.narrowValue(item.children);
                }
            }
        }
        catch (e_3_1) { e_3 = { error: e_3_1 }; }
        finally {
            try {
                if (data_1_1 && !data_1_1.done && (_a = data_1.return)) _a.call(data_1);
            }
            finally { if (e_3) throw e_3.error; }
        }
    };
    TreeSelect.prototype.render = function () {
        var _this = this;
        return React.createElement("div", null,
            React.createElement("label", { htmlFor: this.inputId }, this.props.label),
            React.createElement(modal_1.Modal, { disabled: this.props.readOnly, onClose: function () { return _this.clear(); }, ref: function (ref) { return _this.modalRef = ref; }, renderModalHeader: function () {
                    return React.createElement("h3", null, _this.props.modalTitle);
                }, renderModalContent: function (modalContext) {
                    var _a;
                    var results = _this.filterByWarehouseIfPresent(_this.state.options, (_a = _this.props.warehouseToFilterBy) === null || _a === void 0 ? void 0 : _a.type);
                    return React.createElement("div", { className: 'tree-select' },
                        React.createElement("div", { className: 'border-1 p-2 mb-4' },
                            React.createElement("div", null,
                                React.createElement(optional_component_1.OptionalComponent, { condition: !!_this.state.searchable, ifTrue: React.createElement("div", null,
                                        React.createElement(form_control_1.FormControl, { field: _this.searchForm.fields.search, type: text_input_form_control_type_1.TextInputFormControlType },
                                            React.createElement(text_input_1.TextInput, { autoComplete: 'off', autoFocus: true, tabIndex: _this.props.tabIndex, iconLeft: 'fal fa-search', className: 'text-input-basic' }))) })),
                            React.createElement("div", null, _this.renderTree(results))),
                        React.createElement("div", { className: 'border-1 p-2 mb-4' },
                            React.createElement("h4", { className: 'mb-4' }, "Kiv\u00E1lasztott ter\u00FCletek"),
                            _this.renderSelectedPaths()),
                        React.createElement("div", { className: 'display-flex justify-content-end' },
                            React.createElement(button_1.Button, { className: 'button-primary button-size-normal mr-4', title: 'Reload', onClick: function () { var _a, _b; return _this.loadOptions(((_a = _this.props.query) === null || _a === void 0 ? void 0 : _a.query) || '', _this.props.products || [], ((_b = _this.props.query) === null || _b === void 0 ? void 0 : _b.fromStorage) || ''); } }),
                            React.createElement(button_1.Button, { className: 'button-neutral-outline button-size-normal mr-4', title: 'Mégse', onClick: function () { return _this.modalRef.close(); } }),
                            React.createElement(button_1.Button, { disabled: !_this.isSubmitable(), className: 'button-primary-normal button-size-normal', title: 'Választás', onClick: function () { return _this.submit(); } })));
                }, renderTrigger: function () {
                    return React.createElement("div", null,
                        _this.renderTrigger(),
                        _this.renderError());
                } }));
    };
    TreeSelect.prototype.isSubmitable = function () {
        var _a;
        if (this.props.clearable) {
            return true;
        }
        return !!((_a = this.state.value) === null || _a === void 0 ? void 0 : _a.length);
    };
    TreeSelect.prototype.renderError = function () {
        var _a;
        if (!((_a = this.props.errors) === null || _a === void 0 ? void 0 : _a.length)) {
            return null;
        }
        if (!this.props.renderError) {
            return null;
        }
        return this.props.renderError();
    };
    TreeSelect.prototype.renderTrigger = function () {
        if (this.props.renderTrigger) {
            return this.props.renderTrigger(this, this.props.readOnly);
        }
        return React.createElement(button_1.Button, { className: "button-size-normal ".concat(this.props.readOnly ? 'button-neutral-outline' : 'button-primary-outline'), title: this.props.triggerTitle });
    };
    TreeSelect.prototype.filterByWarehouseIfPresent = function (options, allowedWarehouseType) {
        var _this = this;
        if (!(options === null || options === void 0 ? void 0 : options.length)) {
            return options;
        }
        return options.map(function (node) {
            if (node.type === 'warehouse' && node.document.type === allowedWarehouseType) {
                return node;
            }
            var filteredChildren = _this.filterByWarehouseIfPresent(node.children, allowedWarehouseType);
            return filteredChildren.length > 0 ? __assign(__assign({}, node), { children: filteredChildren }) : null;
        })
            .filter(function (node) { return node !== null; });
    };
    TreeSelect.prototype.renderTree = function (data, depth, disableAll) {
        var _this = this;
        if (depth === void 0) { depth = 0; }
        if (!(data === null || data === void 0 ? void 0 : data.length)) {
            return;
        }
        return React.createElement("div", { className: 'tree-select-list-wrapper' }, data.map(function (d) {
            var _a, _b;
            return (React.createElement("div", { style: { paddingLeft: "".concat(8 * depth, "px") }, key: (_a = d === null || d === void 0 ? void 0 : d.document) === null || _a === void 0 ? void 0 : _a._id },
                _this.renderTreeItem(d, disableAll),
                React.createElement("div", { id: "sublist-".concat((_b = d === null || d === void 0 ? void 0 : d.document) === null || _b === void 0 ? void 0 : _b._id), style: { display: 'none' } }, _this.renderTree(((d === null || d === void 0 ? void 0 : d.children) || []), depth + 1, (_this.isDisabledOption(d) || disableAll)))));
        }));
    };
    TreeSelect.prototype.renderTreeItem = function (item, disabled) {
        var _this = this;
        var _a, _b, _c;
        var isSelected = this.isSelected(item);
        return React.createElement("div", { id: "parent-".concat((_a = item === null || item === void 0 ? void 0 : item.document) === null || _a === void 0 ? void 0 : _a._id), className: "display-flex mb-4 closed tree-select-item p-2 ".concat(isSelected ? 'selected' : '', " ").concat(this.isSubSelected(item) ? 'subselected' : '') },
            React.createElement(optional_component_1.OptionalComponent, { condition: (_b = item === null || item === void 0 ? void 0 : item.children) === null || _b === void 0 ? void 0 : _b.length, ifTrue: React.createElement("div", { className: 'chevron cursor-pointer mr-2', onClick: function () { var _a; return _this.toggleSublist((_a = item === null || item === void 0 ? void 0 : item.document) === null || _a === void 0 ? void 0 : _a._id); } }) }),
            React.createElement("div", { className: "display-flex w-100 ".concat((disabled || this.isDisabledOption(item)) ? 'disabled' : ''), onClick: function () { return !disabled ? _this.optionOnClick(item) : null; } },
                React.createElement("div", { className: 'display-flex flex-fill cursor-pointer ' },
                    React.createElement("strong", null, (_c = item === null || item === void 0 ? void 0 : item.document) === null || _c === void 0 ? void 0 : _c.title)),
                React.createElement(optional_component_1.OptionalComponent, { condition: isSelected, ifTrue: React.createElement(button_1.Button, { className: 'added-badge button-size-small button-primary-outline', title: 'Hozzáadva' }) }),
                React.createElement(optional_component_1.OptionalComponent, { condition: !this.isDisabledOption(item) && !disabled && this.enableAction(item), ifTrue: React.createElement("div", { className: 'action-button mr-2' },
                        React.createElement(optional_component_1.OptionalComponent, { condition: !isSelected, ifTrue: React.createElement(button_1.Button, { iconLeft: 'far fa-plus mr-0', className: 'button-size-small button-neutral-inverze' }) }),
                        React.createElement(optional_component_1.OptionalComponent, { condition: isSelected, ifTrue: React.createElement(button_1.Button, { iconLeft: 'far fa-minus mr-0', className: 'button-size-small button-primary-normal' }) })) })));
    };
    TreeSelect.prototype.optionOnClick = function (item) {
        if (this.isDisabledOption(item) || !this.enableAction(item)) {
            return;
        }
        return !this.isSelected(item) ? this.handleSelect(item, true) : this.handleRemove(item, true);
    };
    TreeSelect.prototype.toggleSublist = function (id) {
        var parent = jquery("#parent-".concat(id));
        parent.hasClass('closed') ? parent.removeClass('closed') : parent.addClass('closed');
        jquery("#sublist-".concat(id)).toggle();
    };
    TreeSelect.prototype.handleOnSearch = function (value) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_b) {
                return [2, this.loadOptions(value, this.props.products, ((_a = this.props.query) === null || _a === void 0 ? void 0 : _a.fromStorage) || '')];
            });
        });
    };
    TreeSelect.prototype.handleSelect = function (item, isExact) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, _b, i, e_4_1;
            var e_4, _c;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        if (!(!this.props.multi && isExact)) return [3, 2];
                        return [4, this.setState({
                                value: [],
                                subItems: []
                            })];
                    case 1:
                        _d.sent();
                        _d.label = 2;
                    case 2:
                        if (!isExact) return [3, 4];
                        return [4, this.setState({ value: __spreadArray(__spreadArray([], __read(this.state.value), false), [item], false) })];
                    case 3:
                        _d.sent();
                        _d.label = 4;
                    case 4:
                        _d.trys.push([4, 9, 10, 11]);
                        _a = __values(item === null || item === void 0 ? void 0 : item.children), _b = _a.next();
                        _d.label = 5;
                    case 5:
                        if (!!_b.done) return [3, 8];
                        i = _b.value;
                        return [4, this.addToSubItems(i)];
                    case 6:
                        _d.sent();
                        _d.label = 7;
                    case 7:
                        _b = _a.next();
                        return [3, 5];
                    case 8: return [3, 11];
                    case 9:
                        e_4_1 = _d.sent();
                        e_4 = { error: e_4_1 };
                        return [3, 11];
                    case 10:
                        try {
                            if (_b && !_b.done && (_c = _a.return)) _c.call(_a);
                        }
                        finally { if (e_4) throw e_4.error; }
                        return [7];
                    case 11: return [2];
                }
            });
        });
    };
    TreeSelect.prototype.addToSubItems = function (item) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var subItems, _b, _c, i, e_5_1;
            var e_5, _d;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        subItems = this.state.subItems || [];
                        return [4, this.setState({ subItems: __spreadArray(__spreadArray([], __read(subItems), false), [(_a = item === null || item === void 0 ? void 0 : item.document) === null || _a === void 0 ? void 0 : _a._id], false) })];
                    case 1:
                        _e.sent();
                        _e.label = 2;
                    case 2:
                        _e.trys.push([2, 7, 8, 9]);
                        _b = __values(item === null || item === void 0 ? void 0 : item.children), _c = _b.next();
                        _e.label = 3;
                    case 3:
                        if (!!_c.done) return [3, 6];
                        i = _c.value;
                        return [4, this.addToSubItems(i)];
                    case 4:
                        _e.sent();
                        _e.label = 5;
                    case 5:
                        _c = _b.next();
                        return [3, 3];
                    case 6: return [3, 9];
                    case 7:
                        e_5_1 = _e.sent();
                        e_5 = { error: e_5_1 };
                        return [3, 9];
                    case 8:
                        try {
                            if (_c && !_c.done && (_d = _b.return)) _d.call(_b);
                        }
                        finally { if (e_5) throw e_5.error; }
                        return [7];
                    case 9: return [2];
                }
            });
        });
    };
    TreeSelect.prototype.removeSubItem = function (item) {
        return __awaiter(this, void 0, void 0, function () {
            var subItems, _a, _b, i, e_6_1;
            var e_6, _c;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        subItems = this.state.subItems.filter(function (i) { var _a; return i !== ((_a = item.document) === null || _a === void 0 ? void 0 : _a._id); });
                        return [4, this.setState({ subItems: subItems })];
                    case 1:
                        _d.sent();
                        _d.label = 2;
                    case 2:
                        _d.trys.push([2, 7, 8, 9]);
                        _a = __values(item === null || item === void 0 ? void 0 : item.children), _b = _a.next();
                        _d.label = 3;
                    case 3:
                        if (!!_b.done) return [3, 6];
                        i = _b.value;
                        return [4, this.removeSubItem(i)];
                    case 4:
                        _d.sent();
                        _d.label = 5;
                    case 5:
                        _b = _a.next();
                        return [3, 3];
                    case 6: return [3, 9];
                    case 7:
                        e_6_1 = _d.sent();
                        e_6 = { error: e_6_1 };
                        return [3, 9];
                    case 8:
                        try {
                            if (_b && !_b.done && (_c = _a.return)) _c.call(_a);
                        }
                        finally { if (e_6) throw e_6.error; }
                        return [7];
                    case 9: return [2];
                }
            });
        });
    };
    TreeSelect.prototype.handleRemove = function (item, isExact) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, _b, children, e_7_1;
            var e_7, _c;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        _d.trys.push([0, 5, 6, 7]);
                        _a = __values(item === null || item === void 0 ? void 0 : item.children), _b = _a.next();
                        _d.label = 1;
                    case 1:
                        if (!!_b.done) return [3, 4];
                        children = _b.value;
                        return [4, this.removeSubItem(children)];
                    case 2:
                        _d.sent();
                        _d.label = 3;
                    case 3:
                        _b = _a.next();
                        return [3, 1];
                    case 4: return [3, 7];
                    case 5:
                        e_7_1 = _d.sent();
                        e_7 = { error: e_7_1 };
                        return [3, 7];
                    case 6:
                        try {
                            if (_b && !_b.done && (_c = _a.return)) _c.call(_a);
                        }
                        finally { if (e_7) throw e_7.error; }
                        return [7];
                    case 7:
                        if (!isExact && this.state.value.some(function (i) { var _a, _b; return ((_a = i === null || i === void 0 ? void 0 : i.document) === null || _a === void 0 ? void 0 : _a._id) === ((_b = item === null || item === void 0 ? void 0 : item.document) === null || _b === void 0 ? void 0 : _b._id); })) {
                            return [2];
                        }
                        if (!isExact) return [3, 9];
                        return [4, this.setState({ value: this.state.value.filter(function (i) { var _a, _b; return ((_a = i.document) === null || _a === void 0 ? void 0 : _a._id) !== ((_b = item.document) === null || _b === void 0 ? void 0 : _b._id); }) })];
                    case 8:
                        _d.sent();
                        _d.label = 9;
                    case 9: return [2];
                }
            });
        });
    };
    TreeSelect.prototype.isSelected = function (item) {
        var _a;
        return !!((_a = this.state.value) === null || _a === void 0 ? void 0 : _a.some(function (v) { var _a, _b; return ((_a = item === null || item === void 0 ? void 0 : item.document) === null || _a === void 0 ? void 0 : _a._id) === ((_b = v === null || v === void 0 ? void 0 : v.document) === null || _b === void 0 ? void 0 : _b._id); }));
    };
    TreeSelect.prototype.isSubSelected = function (item) {
        var _a;
        return !this.isSelected(item) && ((_a = this.state.subItems) === null || _a === void 0 ? void 0 : _a.some(function (v) { var _a; return ((_a = item === null || item === void 0 ? void 0 : item.document) === null || _a === void 0 ? void 0 : _a._id) === v; }));
    };
    TreeSelect.prototype.isDisabledOption = function (item) {
        var _a;
        return (_a = (this.props.disabledOptions || [])) === null || _a === void 0 ? void 0 : _a.some(function (o) { var _a, _b; return ((_a = item === null || item === void 0 ? void 0 : item.document) === null || _a === void 0 ? void 0 : _a._id) === (((_b = o === null || o === void 0 ? void 0 : o.document) === null || _b === void 0 ? void 0 : _b._id) || o); });
    };
    TreeSelect.prototype.enableAction = function (item) {
        var _a, _b;
        if (!!((_a = this.props.enabledTypes) === null || _a === void 0 ? void 0 : _a.length)) {
            return (_b = this.props.enabledTypes) === null || _b === void 0 ? void 0 : _b.includes(item === null || item === void 0 ? void 0 : item.type);
        }
        return !this.isSubSelected(item) || this.isSelected(item);
    };
    TreeSelect.prototype.renderSelectedPaths = function () {
        var _this = this;
        var data = (0, lodash_1.uniqBy)(this.state.value, 'document._id');
        if (!(data === null || data === void 0 ? void 0 : data.length)) {
            return null;
        }
        return React.createElement("div", { className: 'selected-paths' }, data.map(function (d, di) {
            var _a, _b;
            return (React.createElement("div", { className: 'path mb-2 display-flex w-100 p-2', key: di },
                React.createElement("div", { className: 'display-flex flex-fill' }, (_b = (_a = d.document) === null || _a === void 0 ? void 0 : _a.path) === null || _b === void 0 ? void 0 : _b.map(function (p, pi) {
                    var _a, _b;
                    return (React.createElement("div", { key: pi, className: 'display-flex justify-content-start  ' },
                        React.createElement("span", { className: 'fw-600 whitespace-nowrap' }, p === null || p === void 0 ? void 0 :
                            p.title,
                            pi + 1 < ((_b = (_a = d.document) === null || _a === void 0 ? void 0 : _a.path) === null || _b === void 0 ? void 0 : _b.length) ? ' / ' : '')));
                })),
                React.createElement(button_1.Button, { iconLeft: 'far fa-minus mr-0', className: 'button-size-small button-neutral-inverze', onClick: function () { return _this.handleRemove(d, true); } })));
        }));
    };
    TreeSelect.prototype.getValue = function () {
        if (this.isEmpty()) {
            return null;
        }
        return this.state.value;
    };
    TreeSelect.prototype.isEmpty = function () {
        var _a;
        return !((_a = this.state.value) === null || _a === void 0 ? void 0 : _a.length);
    };
    TreeSelect.prototype.getPaths = function (value) {
        var _this = this;
        var _a;
        var inputValues = (_a = (value || [])) === null || _a === void 0 ? void 0 : _a.map(function (v) { var _a; return _this.findValueById((_a = v === null || v === void 0 ? void 0 : v.document) === null || _a === void 0 ? void 0 : _a._id, _this.state.options); });
        var data = !!value ? inputValues : this.state.value;
        return data === null || data === void 0 ? void 0 : data.map(function (d, di) { var _a, _b; return ((_b = (_a = d === null || d === void 0 ? void 0 : d.document) === null || _a === void 0 ? void 0 : _a.path) === null || _b === void 0 ? void 0 : _b.map(function (p, pi) { return "".concat(p === null || p === void 0 ? void 0 : p.title); }).join(' / ')); });
    };
    TreeSelect.prototype.submit = function (ignoreClose) {
        if (ignoreClose === void 0) { ignoreClose = false; }
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.props.onChange(this.state.value)];
                    case 1:
                        _a.sent();
                        if (!ignoreClose) {
                            this.modalRef.close();
                        }
                        return [2];
                }
            });
        });
    };
    TreeSelect.prototype.findValueById = function (id, options) {
        var e_8, _a;
        var _b;
        try {
            for (var _c = __values(options || []), _d = _c.next(); !_d.done; _d = _c.next()) {
                var o = _d.value;
                if (((_b = o === null || o === void 0 ? void 0 : o.document) === null || _b === void 0 ? void 0 : _b._id) === id) {
                    return o;
                }
                var result = this.findValueById(id, (o === null || o === void 0 ? void 0 : o.children) || []);
                if (!!result) {
                    return result;
                }
            }
        }
        catch (e_8_1) { e_8 = { error: e_8_1 }; }
        finally {
            try {
                if (_d && !_d.done && (_a = _c.return)) _a.call(_c);
            }
            finally { if (e_8) throw e_8.error; }
        }
        return null;
    };
    TreeSelect.prototype.clear = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.loadValue()];
                    case 1:
                        _a.sent();
                        return [2];
                }
            });
        });
    };
    TreeSelect.prototype.clearSelection = function () {
        this.props.onChange([]);
    };
    return TreeSelect;
}(abstract_component_1.AbstractComponent));
exports.TreeSelect = TreeSelect;
