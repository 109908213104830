"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.YearlyTargetsForm = void 0;
var repository_1 = require("@codebuild/glaze/libs/repository/repository");
var router_provider_1 = require("@codebuild/glaze/libs/router/router.provider");
var field_1 = require("@codebuild/sprinkles/libs/form/field");
var form_builder_1 = require("@codebuild/sprinkles/libs/form/form-builder");
var form_control_1 = require("@codebuild/sprinkles/libs/form/form-control/form-control");
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var trans_1 = require("@codebuild/sprinkles/libs/translation/trans");
var button_1 = require("@codebuild/sprinkles/modules/codebuild/button/react/button");
var text_input_form_control_type_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/form-control-types/text-input.form-control-type");
var text_input_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/text-input");
var React = require("react");
var moment = require("moment");
var form_1 = require("../../../../components/form/form");
var only_positive_number_validator_1 = require("../../../../components/form/validators/only-positive-number.validator");
var handle_toasts_1 = require("../../../../components/libs/handle.toasts");
var message_box_1 = require("../../../../components/libs/message-box/message-box");
var YearlyTargetsForm = (function (_super) {
    __extends(YearlyTargetsForm, _super);
    function YearlyTargetsForm() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.repository = repository_1.Repository.use('default');
        _this.currentYear = moment().year();
        _this.form = new form_builder_1.FormBuilder({
            fields: {
                january: new field_1.Field({
                    placeholder: '',
                    value: 0,
                    name: 'january',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('sales-targets.form.january'),
                        ' ',
                        React.createElement("i", { className: 'color--status-error-1' }, "*")),
                    validators: [new only_positive_number_validator_1.OnlyPositiveNumberValidator('Invalid number value provided')],
                }),
                february: new field_1.Field({
                    placeholder: '',
                    value: 0,
                    name: 'february',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('sales-targets.form.february'),
                        ' ',
                        React.createElement("i", { className: 'color--status-error-1' }, "*")),
                    validators: [new only_positive_number_validator_1.OnlyPositiveNumberValidator('Invalid number value provided')],
                }),
                march: new field_1.Field({
                    placeholder: '',
                    value: 0,
                    name: 'march',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('sales-targets.form.march'),
                        ' ',
                        React.createElement("i", { className: 'color--status-error-1' }, "*")),
                    validators: [new only_positive_number_validator_1.OnlyPositiveNumberValidator('Invalid number value provided')],
                }),
                april: new field_1.Field({
                    placeholder: '',
                    value: 0,
                    name: 'april',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('sales-targets.form.april'),
                        ' ',
                        React.createElement("i", { className: 'color--status-error-1' }, "*")),
                    validators: [new only_positive_number_validator_1.OnlyPositiveNumberValidator('Invalid number value provided')],
                }),
                may: new field_1.Field({
                    placeholder: '',
                    value: 0,
                    name: 'may',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('sales-targets.form.may'),
                        ' ',
                        React.createElement("i", { className: 'color--status-error-1' }, "*")),
                    validators: [new only_positive_number_validator_1.OnlyPositiveNumberValidator('Invalid number value provided')],
                }),
                june: new field_1.Field({
                    placeholder: '',
                    value: 0,
                    name: 'june',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('sales-targets.form.june'),
                        ' ',
                        React.createElement("i", { className: 'color--status-error-1' }, "*")),
                    validators: [new only_positive_number_validator_1.OnlyPositiveNumberValidator('Invalid number value provided')],
                }),
                july: new field_1.Field({
                    placeholder: '',
                    value: 0,
                    name: 'july',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('sales-targets.form.july'),
                        ' ',
                        React.createElement("i", { className: 'color--status-error-1' }, "*")),
                    validators: [new only_positive_number_validator_1.OnlyPositiveNumberValidator('Invalid number value provided')],
                }),
                august: new field_1.Field({
                    placeholder: '',
                    value: 0,
                    name: 'august',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('sales-targets.form.august'),
                        ' ',
                        React.createElement("i", { className: 'color--status-error-1' }, "*")),
                    validators: [new only_positive_number_validator_1.OnlyPositiveNumberValidator('Invalid number value provided')],
                }),
                september: new field_1.Field({
                    placeholder: '',
                    value: 0,
                    name: 'september',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('sales-targets.form.september'),
                        ' ',
                        React.createElement("i", { className: 'color--status-error-1' }, "*")),
                    validators: [new only_positive_number_validator_1.OnlyPositiveNumberValidator('Invalid number value provided')],
                }),
                october: new field_1.Field({
                    placeholder: '',
                    value: 0,
                    name: 'october',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('sales-targets.form.october'),
                        ' ',
                        React.createElement("i", { className: 'color--status-error-1' }, "*")),
                    validators: [new only_positive_number_validator_1.OnlyPositiveNumberValidator('Invalid number value provided')],
                }),
                november: new field_1.Field({
                    placeholder: '',
                    value: 0,
                    name: 'november',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('sales-targets.form.november'),
                        ' ',
                        React.createElement("i", { className: 'color--status-error-1' }, "*")),
                    validators: [new only_positive_number_validator_1.OnlyPositiveNumberValidator('Invalid number value provided')],
                }),
                december: new field_1.Field({
                    placeholder: '',
                    value: 0,
                    name: 'december',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('sales-targets.form.december'),
                        ' ',
                        React.createElement("i", { className: 'color--status-error-1' }, "*")),
                    validators: [new only_positive_number_validator_1.OnlyPositiveNumberValidator('Invalid number value provided')],
                }),
            }
        });
        _this.state = {
            allTargets: [],
            loading: false,
            error: null
        };
        return _this;
    }
    YearlyTargetsForm.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.props.user) {
                            return [2];
                        }
                        return [4, this.fetchCurrentYearTargets()];
                    case 1:
                        _a.sent();
                        return [4, this.fetchTargets()];
                    case 2:
                        _a.sent();
                        return [2];
                }
            });
        });
    };
    YearlyTargetsForm.prototype.componentDidUpdate = function (prevProps, prevState, snapshot) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(prevProps.year !== this.props.year)) return [3, 2];
                        return [4, this.resetForm(this.props.year)];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2:
                        if (!(prevProps.user !== this.props.user)) return [3, 5];
                        return [4, this.fetchCurrentYearTargets()];
                    case 3:
                        _a.sent();
                        return [4, this.fetchTargets()];
                    case 4:
                        _a.sent();
                        _a.label = 5;
                    case 5: return [2];
                }
            });
        });
    };
    YearlyTargetsForm.prototype.resetForm = function (year) {
        var targetByYear = this.state.allTargets.find(function (target) { return target.year === year; });
        this.updateFields(targetByYear === null || targetByYear === void 0 ? void 0 : targetByYear.targets);
    };
    YearlyTargetsForm.prototype.render = function () {
        return React.createElement("div", { className: 'display-flex flex-column align-items-center w-100' }, this.renderUserForm());
    };
    YearlyTargetsForm.prototype.renderUserForm = function () {
        var _this = this;
        var _a;
        var disabled = this.props.year < this.currentYear;
        var now = moment();
        return React.createElement("div", { className: 'sales-target-wrapper' },
            React.createElement(form_1.Form, null,
                React.createElement(form_control_1.FormControl, { field: this.form.fields.january, type: text_input_form_control_type_1.TextInputFormControlType },
                    React.createElement(text_input_1.TextInput, { disabled: this.getIsDisabled(moment("".concat(this.props.year, "-01-12"))), className: 'text-input-basic' })),
                React.createElement(form_control_1.FormControl, { field: this.form.fields.february, type: text_input_form_control_type_1.TextInputFormControlType },
                    React.createElement(text_input_1.TextInput, { disabled: this.getIsDisabled(moment("".concat(this.props.year, "-02-12"))), className: 'text-input-basic' })),
                React.createElement(form_control_1.FormControl, { field: this.form.fields.march, type: text_input_form_control_type_1.TextInputFormControlType },
                    React.createElement(text_input_1.TextInput, { disabled: this.getIsDisabled(moment("".concat(this.props.year, "-03-12"))), className: 'text-input-basic' })),
                React.createElement(form_control_1.FormControl, { field: this.form.fields.april, type: text_input_form_control_type_1.TextInputFormControlType },
                    React.createElement(text_input_1.TextInput, { disabled: this.getIsDisabled(moment("".concat(this.props.year, "-04-12"))), className: 'text-input-basic' })),
                React.createElement(form_control_1.FormControl, { field: this.form.fields.may, type: text_input_form_control_type_1.TextInputFormControlType },
                    React.createElement(text_input_1.TextInput, { disabled: this.getIsDisabled(moment("".concat(this.props.year, "-05-12"))), className: 'text-input-basic' })),
                React.createElement(form_control_1.FormControl, { field: this.form.fields.june, type: text_input_form_control_type_1.TextInputFormControlType },
                    React.createElement(text_input_1.TextInput, { disabled: this.getIsDisabled(moment("".concat(this.props.year, "-06-12"))), className: 'text-input-basic' })),
                React.createElement(form_control_1.FormControl, { field: this.form.fields.july, type: text_input_form_control_type_1.TextInputFormControlType },
                    React.createElement(text_input_1.TextInput, { disabled: this.getIsDisabled(moment("".concat(this.props.year, "-07-12"))), className: 'text-input-basic' })),
                React.createElement(form_control_1.FormControl, { field: this.form.fields.august, type: text_input_form_control_type_1.TextInputFormControlType },
                    React.createElement(text_input_1.TextInput, { disabled: this.getIsDisabled(moment("".concat(this.props.year, "-08-12"))), className: 'text-input-basic' })),
                React.createElement(form_control_1.FormControl, { field: this.form.fields.september, type: text_input_form_control_type_1.TextInputFormControlType },
                    React.createElement(text_input_1.TextInput, { disabled: this.getIsDisabled(moment("".concat(this.props.year, "-09-12"))), className: 'text-input-basic' })),
                React.createElement(form_control_1.FormControl, { field: this.form.fields.october, type: text_input_form_control_type_1.TextInputFormControlType },
                    React.createElement(text_input_1.TextInput, { disabled: this.getIsDisabled(moment("".concat(this.props.year, "-10-12"))), className: 'text-input-basic' })),
                React.createElement(form_control_1.FormControl, { field: this.form.fields.november, type: text_input_form_control_type_1.TextInputFormControlType },
                    React.createElement(text_input_1.TextInput, { disabled: this.getIsDisabled(moment("".concat(this.props.year, "-11-12"))), className: 'text-input-basic' })),
                React.createElement(form_control_1.FormControl, { field: this.form.fields.december, type: text_input_form_control_type_1.TextInputFormControlType },
                    React.createElement(text_input_1.TextInput, { disabled: this.getIsDisabled(moment("".concat(this.props.year, "-12-12"))), className: 'text-input-basic' }))),
            React.createElement("div", { className: 'display-flex flex-row justify-content-end w-100 mt-7' },
                React.createElement(button_1.Button, { className: 'button-primary-normal button-size-normal w-100', title: (0, trans_1.trans)('sales-targets.form.submit.button'), onClick: function () { return _this.updateTargets(); } })),
            React.createElement("div", { className: 'py-6' }, !!this.state.error && React.createElement(message_box_1.MessageBox, { type: 'error', message: (_a = this.state.error) === null || _a === void 0 ? void 0 : _a.message })));
    };
    YearlyTargetsForm.prototype.getIsDisabled = function (date) {
        if (this.props.year < this.currentYear) {
            return true;
        }
        var now = moment();
        return date.isSameOrBefore(now);
    };
    YearlyTargetsForm.prototype.updateTargets = function () {
        return __awaiter(this, void 0, void 0, function () {
            var isValid, targets, e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 5]);
                        return [4, this.form.validate()];
                    case 1:
                        isValid = _a.sent();
                        if (!isValid) {
                            return [2];
                        }
                        targets = this.form.toJSON();
                        return [4, this.repository.put("/b2b/sales-targets/".concat(this.props.user._id), {
                                data: {
                                    targets: targets,
                                    year: this.props.year, user: this.props.user._id
                                }
                            })];
                    case 2:
                        _a.sent();
                        handle_toasts_1.HandleToasts.success((0, trans_1.trans)('sales-targets.form.submission.success'));
                        router_provider_1.RouterProvider.goTo('/sales-targets');
                        return [3, 5];
                    case 3:
                        e_1 = _a.sent();
                        return [4, this.setState({ error: e_1 })];
                    case 4:
                        _a.sent();
                        return [3, 5];
                    case 5: return [2];
                }
            });
        });
    };
    YearlyTargetsForm.prototype.fetchCurrentYearTargets = function () {
        return __awaiter(this, void 0, void 0, function () {
            var response, e_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, 3, 4]);
                        this.setState({ loading: true });
                        return [4, this.repository.get("/b2b/sales-targets/current-year/".concat(this.props.user._id))];
                    case 1:
                        response = _a.sent();
                        if (!(response === null || response === void 0 ? void 0 : response.targets)) {
                            return [2];
                        }
                        this.updateFields(response.targets);
                        return [3, 4];
                    case 2:
                        e_2 = _a.sent();
                        console.log({ user: this.props.user, fetchCurrentYearTargets: e_2 });
                        return [3, 4];
                    case 3:
                        this.setState({ loading: false });
                        return [7];
                    case 4: return [2];
                }
            });
        });
    };
    YearlyTargetsForm.prototype.updateFields = function (options) {
        var _a = __read(Object.values(options !== null && options !== void 0 ? options : {}), 12), january = _a[0], february = _a[1], march = _a[2], april = _a[3], may = _a[4], june = _a[5], july = _a[6], august = _a[7], september = _a[8], october = _a[9], november = _a[10], december = _a[11];
        this.form.fields.january.setValue(january !== null && january !== void 0 ? january : 0);
        this.form.fields.february.setValue(february !== null && february !== void 0 ? february : 0);
        this.form.fields.march.setValue(march !== null && march !== void 0 ? march : 0);
        this.form.fields.april.setValue(april !== null && april !== void 0 ? april : 0);
        this.form.fields.may.setValue(may !== null && may !== void 0 ? may : 0);
        this.form.fields.june.setValue(june !== null && june !== void 0 ? june : 0);
        this.form.fields.july.setValue(july !== null && july !== void 0 ? july : 0);
        this.form.fields.august.setValue(august !== null && august !== void 0 ? august : 0);
        this.form.fields.september.setValue(september !== null && september !== void 0 ? september : 0);
        this.form.fields.october.setValue(october !== null && october !== void 0 ? october : 0);
        this.form.fields.november.setValue(november !== null && november !== void 0 ? november : 0);
        this.form.fields.december.setValue(december !== null && december !== void 0 ? december : 0);
    };
    YearlyTargetsForm.prototype.fetchTargets = function () {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var response, e_3;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 2, 3, 4]);
                        if (!((_a = this.props.user) === null || _a === void 0 ? void 0 : _a._id)) {
                            return [2];
                        }
                        this.setState({ loading: true });
                        return [4, this.repository.get("/b2b/sales-targets/".concat((_b = this.props.user) === null || _b === void 0 ? void 0 : _b._id))];
                    case 1:
                        response = _c.sent();
                        if (!response) {
                            return [2];
                        }
                        this.setState({ allTargets: response });
                        return [3, 4];
                    case 2:
                        e_3 = _c.sent();
                        console.log({ fetchTargets: e_3 });
                        return [3, 4];
                    case 3:
                        this.setState({ loading: false });
                        return [7];
                    case 4: return [2];
                }
            });
        });
    };
    return YearlyTargetsForm;
}(abstract_component_1.AbstractComponent));
exports.YearlyTargetsForm = YearlyTargetsForm;
