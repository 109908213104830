"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AutomaticSaveManager = void 0;
var abstract_component_1 = require("@codebuild/glaze/libs/libs/abstract.component");
var sleep_1 = require("@codebuild/glaze/libs/libs/sleep");
var repository_1 = require("@codebuild/glaze/libs/repository/repository");
var router_provider_1 = require("@codebuild/glaze/libs/router/router.provider");
var button_1 = require("@codebuild/sprinkles/modules/codebuild/button/react/button");
var lodash_1 = require("lodash");
var React = require("react");
var sticky_container_1 = require("../../../common/components/sticky-container");
var handle_toasts_1 = require("../../../components/libs/handle.toasts");
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
var optional_component_1 = require("../../../components/optional-component");
var periodToAutoSave = 10 * 1000;
var AutomaticSaveManager = (function (_super) {
    __extends(AutomaticSaveManager, _super);
    function AutomaticSaveManager(props) {
        var _this = _super.call(this, props) || this;
        _this.repository = repository_1.Repository.use('default');
        _this.saveSubscription = null;
        _this.state = {
            loading: false,
            updateId: '',
            lastPayload: ''
        };
        return _this;
    }
    AutomaticSaveManager.prototype.componentDidMount = function () {
        if (!this.props.disabled) {
            this.startAutomaticSave();
        }
        if (this.props.updateId) {
            this.setState({ updateId: this.props.updateId });
        }
    };
    AutomaticSaveManager.prototype.componentWillUnmount = function () {
        this.stopAutomaticSave();
    };
    AutomaticSaveManager.prototype.componentDidUpdate = function (prevProps, prevState, snapshot) {
        if (!(0, lodash_1.isEqual)(prevProps.updateId, this.props.updateId)) {
            this.setState({ updateId: this.props.updateId });
        }
        if (!(0, lodash_1.isEqual)(prevProps.disabled, this.props.disabled)) {
            if (!this.props.disabled && !this.saveSubscription) {
                this.startAutomaticSave();
            }
        }
    };
    AutomaticSaveManager.prototype.startAutomaticSave = function () {
        var _this = this;
        this.saveSubscription = (0, rxjs_1.interval)(periodToAutoSave)
            .pipe((0, operators_1.switchMap)(function () { return _this.handleSave(); }))
            .subscribe({
            next: function () { return console.log('Save request sent successfully'); },
            error: function (err) { return console.error('Error during save request:', err); },
        });
    };
    AutomaticSaveManager.prototype.stopAutomaticSave = function () {
        if (this.saveSubscription) {
            this.saveSubscription.unsubscribe();
            this.saveSubscription = null;
        }
    };
    AutomaticSaveManager.prototype.handleStockTakeUpdateOpen = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (!this.state.updateId) {
                    return [2, handle_toasts_1.HandleToasts.error('Valami nem sikerült')];
                }
                return [2, router_provider_1.RouterProvider.goTo("/stock-product-inventory/".concat(this.state.updateId))];
            });
        });
    };
    AutomaticSaveManager.prototype.render = function () {
        var _this = this;
        if (this.props.invisible || !this.state.updateId) {
            return null;
        }
        return (React.createElement(sticky_container_1.StickyContainer, { topSpacing: 32, className: 'w-100' },
            React.createElement("div", { className: 'save-box-automatic mb-6' },
                React.createElement("h5", { className: 'mb-2' }, "AUTOMATIKUS MENT\u00C9S"),
                React.createElement("div", { className: 'mt-5 mb-5' },
                    React.createElement(optional_component_1.OptionalComponent, { condition: this.state.loading, ifTrue: this.renderLoading(), ifFalse: React.createElement(button_1.Button, { title: 'Leltár megnyitása', iconLeft: 'fas fa-external-link-alt', className: 'button-info-normal button-size-normal mb-3', disabled: this.state.loading, onClick: function () { return _this.handleStockTakeUpdateOpen(); } }) })))));
    };
    AutomaticSaveManager.prototype.renderLoading = function () {
        return React.createElement("div", { className: 'row' },
            React.createElement("div", { className: 'col-24 position-center mb-2' },
                React.createElement("span", { className: 'fs-10' }, "Automatikus ment\u00E9s folyamatban")),
            React.createElement("div", { className: 'col-24 position-center' },
                React.createElement("i", { className: "fas fa-spinner-third fa-spin" })));
    };
    AutomaticSaveManager.prototype.handleSave = function () {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var formData, plan, stockProducts, stockProducts_1, stockProducts_1_1, stock, item, e_1_1, data, stringifiedData, response, e_2;
            var e_1, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 14, 15, 16]);
                        formData = this.props.form.toJSON();
                        if (!(formData === null || formData === void 0 ? void 0 : formData.warehouse)) {
                            throw new Error('warehouse is required');
                        }
                        if (!(formData === null || formData === void 0 ? void 0 : formData.legalCodeIssue)) {
                            throw new Error('jógcím kód is required');
                        }
                        if (!(formData === null || formData === void 0 ? void 0 : formData.legalCodeIncome)) {
                            throw new Error('warehouse is required');
                        }
                        plan = [];
                        stockProducts = (_a = this.props.storages) === null || _a === void 0 ? void 0 : _a.reduce(function (acc, storage) { return __spreadArray(__spreadArray([], __read(acc), false), __read(storage.stockProducts), false); }, []);
                        _c.label = 1;
                    case 1:
                        _c.trys.push([1, 6, 7, 8]);
                        stockProducts_1 = __values(stockProducts), stockProducts_1_1 = stockProducts_1.next();
                        _c.label = 2;
                    case 2:
                        if (!!stockProducts_1_1.done) return [3, 5];
                        stock = stockProducts_1_1.value;
                        return [4, this.props.stockRefs[stock._id].getData(false)];
                    case 3:
                        item = _c.sent();
                        if (!(item === null || item === void 0 ? void 0 : item.amount) && (item === null || item === void 0 ? void 0 : item.amount) !== 0) {
                            return [3, 4];
                        }
                        plan.push(item);
                        _c.label = 4;
                    case 4:
                        stockProducts_1_1 = stockProducts_1.next();
                        return [3, 2];
                    case 5: return [3, 8];
                    case 6:
                        e_1_1 = _c.sent();
                        e_1 = { error: e_1_1 };
                        return [3, 8];
                    case 7:
                        try {
                            if (stockProducts_1_1 && !stockProducts_1_1.done && (_b = stockProducts_1.return)) _b.call(stockProducts_1);
                        }
                        finally { if (e_1) throw e_1.error; }
                        return [7];
                    case 8:
                        data = {
                            warehouse: formData === null || formData === void 0 ? void 0 : formData.warehouse,
                            legalCodeIssue: formData === null || formData === void 0 ? void 0 : formData.legalCodeIssue,
                            legalCodeIncome: formData === null || formData === void 0 ? void 0 : formData.legalCodeIncome,
                            plan: plan === null || plan === void 0 ? void 0 : plan.map(function (p) { return ({
                                stockProduct: p.product,
                                amount: parseInt(p.amount, 10),
                            }); }),
                        };
                        stringifiedData = JSON.stringify(data);
                        if (this.state.lastPayload && this.state.lastPayload === stringifiedData) {
                            throw new Error('Already up to date');
                        }
                        this.setState({ loading: true, lastPayload: stringifiedData });
                        if (!this.state.updateId) return [3, 10];
                        return [4, this.repository.put("/b2b/stock-inventory/".concat(this.state.updateId), { data: data })];
                    case 9:
                        _c.sent();
                        return [3, 12];
                    case 10: return [4, this.repository.post('/b2b/stock-inventory', { data: data })];
                    case 11:
                        response = _c.sent();
                        this.setState({ updateId: response._id });
                        _c.label = 12;
                    case 12: return [4, (0, sleep_1.sleep)(1300)];
                    case 13:
                        _c.sent();
                        return [3, 16];
                    case 14:
                        e_2 = _c.sent();
                        console.warn({ handleSave: e_2 });
                        this.setState({ loading: false });
                        return [3, 16];
                    case 15:
                        this.setState({ loading: false });
                        return [7];
                    case 16: return [2];
                }
            });
        });
    };
    return AutomaticSaveManager;
}(abstract_component_1.AbstractComponent));
exports.AutomaticSaveManager = AutomaticSaveManager;
