"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustomerPartnerUpdateScreen = void 0;
var repository_1 = require("@codebuild/glaze/libs/repository/repository");
var router_provider_1 = require("@codebuild/glaze/libs/router/router.provider");
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var trans_1 = require("@codebuild/sprinkles/libs/translation/trans");
var button_1 = require("@codebuild/sprinkles/modules/codebuild/button/react/button");
var moment = require("moment");
var React = require("react");
var sticky_container_1 = require("../../../common/components/sticky-container");
var permissions_1 = require("../../../common/libs/permissions/permissions");
var badge_1 = require("../../../components/libs/badge/badge");
var handle_toasts_1 = require("../../../components/libs/handle.toasts");
require("./customer-partner-create-update.screen.scss");
var permission_wrapper_1 = require("../../../components/permission-wrapper");
var addresses_box_1 = require("./libs/addresses-box/addresses-box");
var basic_data_form_1 = require("./libs/basic-data-form");
var commitment_box_1 = require("./libs/commitment-box");
var connected_partners_box_1 = require("./libs/connected-partners-box/connected-partners-box");
var contacts_box_1 = require("./libs/contact-box/contacts-box");
var contracts_box_1 = require("./libs/contract-box/contracts-box");
var credit_box_1 = require("./libs/credit-box");
var discount_box_1 = require("./libs/discount-box");
var owners_box_1 = require("./libs/owners-box/owners-box");
var CustomerPartnerUpdateScreen = (function (_super) {
    __extends(CustomerPartnerUpdateScreen, _super);
    function CustomerPartnerUpdateScreen() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.repository = repository_1.Repository.use('default');
        _this.state = {
            customerPartner: null,
            editorLanguage: 'hu',
            isBundle: null,
            loading: false,
            readOnly: true,
        };
        return _this;
    }
    CustomerPartnerUpdateScreen.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.fetch()];
                    case 1:
                        _a.sent();
                        return [2];
                }
            });
        });
    };
    CustomerPartnerUpdateScreen.prototype.fetch = function () {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var response, e_1;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 2, , 3]);
                        return [4, this.repository.get("/b2b/customer-partner/".concat((_b = (_a = this.props.route) === null || _a === void 0 ? void 0 : _a.params) === null || _b === void 0 ? void 0 : _b.partnerId))];
                    case 1:
                        response = _c.sent();
                        this.setState({ customerPartner: response });
                        return [3, 3];
                    case 2:
                        e_1 = _c.sent();
                        return [3, 3];
                    case 3: return [2];
                }
            });
        });
    };
    CustomerPartnerUpdateScreen.prototype.render = function () {
        var _this = this;
        var _a, _b, _c, _d, _e, _f, _g;
        return React.createElement("div", { className: 'CustomerPartnerUpdateScreen row w-100' },
            React.createElement("div", { className: 'col-20' },
                React.createElement("div", { className: 'mb-2' },
                    React.createElement(button_1.Button, { className: 'button-neutral-link button-size-small', title: 'Vissza', iconLeft: 'fas fa-arrow-left', onClick: function () { return router_provider_1.RouterProvider.goTo('/customer-partner'); } })),
                React.createElement("div", { className: 'mb-4' },
                    React.createElement("h3", { className: 'fw-600' }, (0, trans_1.trans)('customer-partner.title'))),
                React.createElement("div", { className: 'row mb-4' },
                    React.createElement("div", { className: "col-24" },
                        React.createElement(basic_data_form_1.BasicDataForm, { ref: function (ref) { return _this.customerPartnerBasicDataFormRef$ = ref; }, editorLanguage: this.state.editorLanguage, isCreate: false, readOnly: this.state.readOnly, values: this.state.customerPartner }))),
                React.createElement("div", { className: 'row mb-4' },
                    React.createElement("div", { className: "col-24" },
                        React.createElement(discount_box_1.DiscountBox, { ref: function (ref) { return _this.discountBoxRef$ = ref; }, isCreate: false, readOnly: this.state.readOnly, values: (_a = this.state.customerPartner) === null || _a === void 0 ? void 0 : _a.discount, customerPartner: this.state.customerPartner }))),
                React.createElement("div", { className: 'row mb-4' },
                    React.createElement("div", { className: "col-24" },
                        React.createElement(commitment_box_1.CommitmentBox, { ref: function (ref) { return _this.customerPartnerCommitmentBoxRef$ = ref; }, editorLanguage: this.state.editorLanguage, isCreate: false, readOnly: this.state.readOnly, values: this.state.customerPartner }))),
                React.createElement("div", { className: 'row mb-4' },
                    React.createElement("div", { className: "col-24" },
                        React.createElement(credit_box_1.CreditBox, { ref: function (ref) { return _this.customerPartnerCreditBoxRef$ = ref; }, editorLanguage: this.state.editorLanguage, isCreate: false, readOnly: this.state.readOnly, values: this.state.customerPartner }))),
                React.createElement("div", { className: 'row mb-4' },
                    React.createElement("div", { className: "col-24" },
                        React.createElement(addresses_box_1.AddressesBox, { ref: function (ref) { return _this.customerPartnerShippingAddressesBoxRef$ = ref; }, editorLanguage: this.state.editorLanguage, isCreate: false, readOnly: this.state.readOnly, title: 'Telephelyek', values: (_b = this.state.customerPartner) === null || _b === void 0 ? void 0 : _b.shippingDatas }))),
                React.createElement("div", { className: 'row mb-4' },
                    React.createElement("div", { className: "col-24" },
                        React.createElement(contacts_box_1.ContactsBox, { ref: function (ref) { return _this.customerPartnerContactBoxRef$ = ref; }, editorLanguage: this.state.editorLanguage, isCreate: false, readOnly: this.state.readOnly, title: 'Kapcsolattartók', values: (_c = this.state.customerPartner) === null || _c === void 0 ? void 0 : _c.contactDatas }))),
                React.createElement("div", { className: 'row mb-4' },
                    React.createElement("div", { className: "col-24" },
                        React.createElement(contracts_box_1.ContractsBox, { ref: function (ref) { return _this.customerPartnerContractBoxRef$ = ref; }, editorLanguage: this.state.editorLanguage, isCreate: false, readOnly: this.state.readOnly, title: 'Szerződések', values: (_d = this.state.customerPartner) === null || _d === void 0 ? void 0 : _d.contracts }))),
                React.createElement("div", { className: 'row mb-4' },
                    React.createElement("div", { className: "col-24" },
                        React.createElement(connected_partners_box_1.ConnectedPartnersBox, { ref: function (ref) { return _this.customerPartnerConnectedPartnersBoxRef$ = ref; }, editorLanguage: this.state.editorLanguage, isCreate: false, readOnly: this.state.readOnly, title: 'Kapcsolódó cégek', ownId: (_e = this.state.customerPartner) === null || _e === void 0 ? void 0 : _e._id, values: (_f = this.state.customerPartner) === null || _f === void 0 ? void 0 : _f.connectedPartners }))),
                React.createElement("div", { className: 'row mb-4' },
                    React.createElement("div", { className: "col-24" },
                        React.createElement(owners_box_1.OwnersBox, { ref: function (ref) { return _this.customerPartnerOwnersBoxRef$ = ref; }, editorLanguage: this.state.editorLanguage, isCreate: false, readOnly: this.state.readOnly, title: 'Kezelők', values: (_g = this.state.customerPartner) === null || _g === void 0 ? void 0 : _g.owners })))),
            React.createElement("div", { className: 'col-4' }, this.renderSaveBox()));
    };
    CustomerPartnerUpdateScreen.prototype.renderSaveBox = function () {
        var _this = this;
        var _a, _b, _c, _d, _e;
        return React.createElement(permission_wrapper_1.PermissionWrapper, { permissions: permissions_1.PERMISSIONS.CRM.customerPartners.update },
            React.createElement(sticky_container_1.StickyContainer, { topSpacing: 32, className: 'w-100' },
                this.state.readOnly && React.createElement("div", { className: 'SaveBox' },
                    React.createElement("div", { className: 'w-100 display-flex justify-content-center mb-4' },
                        React.createElement(button_1.Button, { disabled: this.state.loading, onClick: function () { return _this.setState({ readOnly: false }); }, iconLeft: 'far fa-pencil', title: this.state.loading ?
                                React.createElement("i", { className: 'fas fa-circle-notch fa-spin' }) : (0, trans_1.trans)('customer-partner.update.save-box.button.enable-edit'), className: 'button-info-normal button-size-normal' })),
                    React.createElement("hr", null),
                    React.createElement("div", null,
                        React.createElement("h5", { className: 'mb-4 text-center' }, "ST\u00C1TUSZ"),
                        React.createElement("div", { className: 'w-100 display-flex justify-content-center mb-4' },
                            React.createElement(badge_1.Badge, { title: ((_a = this.state.customerPartner) === null || _a === void 0 ? void 0 : _a.isActive) ? (0, trans_1.trans)('general.status.active') : (0, trans_1.trans)('general.status.inactive'), type: ((_b = this.state.customerPartner) === null || _b === void 0 ? void 0 : _b.isActive) ? 'success' : 'error' })),
                        React.createElement("div", { className: 'w-100 display-flex justify-content-center mb-4' },
                            React.createElement(button_1.Button, { disabled: this.state.loading, onClick: function () { return _this.setStatus(); }, iconLeft: 'far fa-store', title: (this.state.loading ?
                                    React.createElement("i", { className: 'fas fa-circle-notch fa-spin' }) : (((_c = this.state.customerPartner) === null || _c === void 0 ? void 0 : _c.isActive) ? 'Cég inaktiválása' : 'Cég aktiválása')), className: 'button-neutral-inverze button-size-normal' })))),
                !this.state.readOnly && React.createElement("div", { className: 'SaveBox' },
                    React.createElement("h5", { className: 'mb-4 text-center' }, "SZERKESZT\u00C9S"),
                    React.createElement("div", { className: 'w-100 display-flex justify-content-start mb-4' },
                        React.createElement("div", { className: 'display-flex flex-column' },
                            React.createElement("small", { className: 'mb-2' }, "L\u00E9trehozva"),
                            React.createElement("span", { className: 'fw-700 ml-4' }, moment((_d = this.state.customerPartner) === null || _d === void 0 ? void 0 : _d.createdAt).format('YYYY.MM.DD.')))),
                    React.createElement("div", { className: 'w-100 display-flex justify-content-start mb-4' },
                        React.createElement("div", { className: 'display-flex flex-column' },
                            React.createElement("small", { className: 'mb-2' }, "Utolj\u00E1ra m\u00F3dos\u00EDtva"),
                            React.createElement("span", { className: 'fw-700 ml-4' }, moment((_e = this.state.customerPartner) === null || _e === void 0 ? void 0 : _e.updatedAt).format('YYYY.MM.DD.')))),
                    React.createElement("div", { className: 'w-100 display-flex justify-content-center' },
                        React.createElement(button_1.Button, { disabled: this.state.loading, onClick: function () { return _this.submitForms(); }, iconLeft: 'far fa-save', title: this.state.loading ?
                                React.createElement("i", { className: 'fas fa-circle-notch fa-spin' }) : (0, trans_1.trans)('customer-partner.update.save-box.button.save'), className: 'button-info-normal button-size-normal' })))));
    };
    CustomerPartnerUpdateScreen.prototype.setStatus = function () {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var statusEndpoint, response, e_2;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 2, , 3]);
                        this.setState({ loading: true });
                        statusEndpoint = ((_a = this.state.customerPartner) === null || _a === void 0 ? void 0 : _a.isActive) ? 'deactivate' : 'activate';
                        return [4, this.repository.put("/b2b/customer-partner/".concat((_b = this.state.customerPartner) === null || _b === void 0 ? void 0 : _b._id, "/").concat(statusEndpoint))];
                    case 1:
                        response = _c.sent();
                        this.setState({ customerPartner: response, loading: false });
                        handle_toasts_1.HandleToasts.success((0, trans_1.trans)('customer-partner.status-update.success.toast'));
                        return [3, 3];
                    case 2:
                        e_2 = _c.sent();
                        this.setState({ loading: false });
                        handle_toasts_1.HandleToasts.error(e_2, (0, trans_1.trans)(e_2.message.replace(/\s/g, '')));
                        return [3, 3];
                    case 3: return [2];
                }
            });
        });
    };
    CustomerPartnerUpdateScreen.prototype.submitForms = function () {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
        return __awaiter(this, void 0, void 0, function () {
            var discountData, basicData, commitment, credit, billingData, miscData, shippingDatas, contactDatas, contracts, connectedPartners, owners, data, response, e_3;
            return __generator(this, function (_l) {
                switch (_l.label) {
                    case 0:
                        _l.trys.push([0, 11, , 12]);
                        this.setState({ loading: true });
                        return [4, ((_a = this.discountBoxRef$) === null || _a === void 0 ? void 0 : _a.getData())];
                    case 1:
                        discountData = _l.sent();
                        return [4, ((_b = this.customerPartnerBasicDataFormRef$) === null || _b === void 0 ? void 0 : _b.getData())];
                    case 2:
                        basicData = _l.sent();
                        return [4, ((_c = this.customerPartnerCommitmentBoxRef$) === null || _c === void 0 ? void 0 : _c.getData())];
                    case 3:
                        commitment = _l.sent();
                        return [4, ((_d = this.customerPartnerCreditBoxRef$) === null || _d === void 0 ? void 0 : _d.getData())];
                    case 4:
                        credit = _l.sent();
                        if (!!basicData.error || !!commitment.error || !!credit.error || !!discountData.error) {
                            this.setState({ loading: false });
                            return [2];
                        }
                        billingData = __assign(__assign({}, basicData.address), { name: basicData.name, paymentDueDate: basicData.paymentDueDate, taxNumber: basicData.taxNumber, billingType: basicData.billingType, invoiceNotificationEmail: basicData.invoiceNotificationEmail });
                        miscData = {
                            winehubGLN: basicData.winehubGLN,
                            partnerGLN: basicData.partnerGLN,
                            deliveryIdentifier: basicData.deliveryIdentifier,
                        };
                        return [4, ((_e = this.customerPartnerShippingAddressesBoxRef$) === null || _e === void 0 ? void 0 : _e.getData())];
                    case 5:
                        shippingDatas = _l.sent();
                        return [4, ((_f = this.customerPartnerContactBoxRef$) === null || _f === void 0 ? void 0 : _f.getData())];
                    case 6:
                        contactDatas = _l.sent();
                        return [4, ((_g = this.customerPartnerContractBoxRef$) === null || _g === void 0 ? void 0 : _g.getData())];
                    case 7:
                        contracts = _l.sent();
                        return [4, ((_h = this.customerPartnerConnectedPartnersBoxRef$) === null || _h === void 0 ? void 0 : _h.getData())];
                    case 8:
                        connectedPartners = _l.sent();
                        return [4, ((_j = this.customerPartnerOwnersBoxRef$) === null || _j === void 0 ? void 0 : _j.getData())];
                    case 9:
                        owners = _l.sent();
                        if (!!basicData.error) {
                            this.setState({ loading: false });
                            return [2];
                        }
                        data = __assign(__assign(__assign(__assign(__assign({}, discountData), basicData), commitment), credit), { miscData: miscData, billingData: billingData, connectedPartners: connectedPartners, shippingDatas: shippingDatas, contactDatas: contactDatas, contracts: contracts, owners: owners, country: 'Magyarorszag' });
                        return [4, this.repository.put("/b2b/customer-partner/".concat((_k = this.state.customerPartner) === null || _k === void 0 ? void 0 : _k._id), { data: data })];
                    case 10:
                        response = _l.sent();
                        this.setState({ loading: false, readOnly: true });
                        router_provider_1.RouterProvider.goTo("/customer-partner/update/".concat(response._id));
                        handle_toasts_1.HandleToasts.success((0, trans_1.trans)('customer-partner.update.success.toast'));
                        return [3, 12];
                    case 11:
                        e_3 = _l.sent();
                        this.setState({ loading: false });
                        handle_toasts_1.HandleToasts.error(e_3, (0, trans_1.trans)(e_3.message.replace(/\s/g, '')));
                        return [3, 12];
                    case 12: return [2];
                }
            });
        });
    };
    return CustomerPartnerUpdateScreen;
}(abstract_component_1.AbstractComponent));
exports.CustomerPartnerUpdateScreen = CustomerPartnerUpdateScreen;
